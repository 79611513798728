import { Option, Fetch } from "./fetchHelpers"
import { serviceUrl } from './ServiceURL';

/* eslint-disable array-callback-return */

/**
 * This function creates the rows for the category table on the admin categories
 * page. It is automatically run when the page is loaded.
 * 
 * @param {object} categories all categories retrieved from the database
 */
function createCategoryTable(categories) {
  let categoryTable = [];
  var categoryRow = {};
  var preId = 0;
  categories.map(function (category) {
    var curId = category.idCategory;
    /*
     * These is/else statements ensure that the different translations of the
     * category names ends up in the same row.
     */
    if (curId === preId) {
      categoryRow[category.isoCode] = category.category;
    } else if (preId > 0) {
      categoryTable.push(categoryRow);
      categoryRow = {};
      categoryRow['id'] = category.idCategory;
      categoryRow[category.isoCode] = category.category;
    } else {
      categoryRow['id'] = category.idCategory;
      categoryRow[category.isoCode] = category.category;
    }
    preId = curId;
  })
  categoryTable.push(categoryRow);
  return categoryTable;
}

/**
 * This function recieves the added entry from the category table on the admin
 * category page and add the new entry to the database.
 * 
 * @param {object} added the new category to be added to the database (can contain
 * multiple translations of the category)
 */
function addNewCategory(added) {
  var categoryTranslation = [];
  /*
   * Reformats the added category from:
   * \[
   *   0: {nob: "...", eng: "..."}
   * ]
   * to:
   * {nob: "...", eng: "..."}
   */
  added.map(function (row) {
    categoryTranslation = row
  })
  /*
   * This async function ensures that the first fetch returns before the second 
   * one starts. This is necessary as the second fetch is dependant on the
   * output of the former.
   */
  const request = async () => {
    /*
     * This fetch creates a new category entry in the database. This is the
     * parent entry for the translations of the category, and the id of the 
     * newly added entry is needed as a foreign key for the adding a new 
     * translation.
     */
    const category = await Fetch(
      serviceUrl+'/api/Categories', Option('POST', {}));
    const idCategory = await category.idCategory;
    /*
     * If multiple translations have been added, this loop ensures they all get
     * stored in the database.
     */
    for (var isoCode in categoryTranslation) {
      const body = {
        "IdCategory": parseInt(idCategory),
        "IsoCode": isoCode,
        "Category": categoryTranslation[isoCode],
        "EvaluationDescription": 'Please enter an evaluation description',
      };
      // This fetch add the translation of the category to the database
      await Fetch(serviceUrl+'/api/CategoryTranslations',
        Option('POST', body))
    }
    return idCategory;
  }
  return request();
}

/**
 * This function revieves the entry from the category table on the admin category
 * apge and updates the database accordingly.
 * 
 * @param {object} changed the category translation that is being changed (can 
 * contain multiple translations of the category)
 */
function editCategory(changed) {
  /*
   * This async function ensures that all the fetch functions are run and completed
   * sequentially. 
   */
  const request = async () => {
    for (var categoryId in changed) {
      for (var isoCode in changed[categoryId]) {
        var translation = changed[categoryId][isoCode]
        /*
         * The first fetch checks wether or not the entry being changed exists 
         * or not. If not then a new entry is added to the database (the second 
         * fetch). If the second is activated then the third and fourth ones are
         * skipped, as it is unnecessary to update the newly created entry with 
         * the same values as used during its creation.
         */
        const category = await Fetch(
          serviceUrl+'/api/CategoryTranslations/'
          + categoryId + '/' + isoCode, Option('GET'));
        if (category === undefined) {
          const body = {
            "IdCategory": categoryId,
            "IsoCode": isoCode,
            "Category": translation,
            "EvaluationDescription": 'Please enter an evaluation description',
          };
          /*
           * Creates a new database entry if a translation in the current language
           * did not previously exist.
           */
          Fetch(serviceUrl+'/api/CategoryTranslations',
            Option('POST', body));
        } else {
          /*
           * This fetch retrieves the translatad evaluation description of the
           * edited category as the evaluation description is not part of the 
           * input for this edit funtion.
           */
          const category = await Fetch(
            serviceUrl+'/api/CategoryTranslations/' + categoryId
            + '/' + isoCode, Option('GET')
          );
          const evaluationDescription = category.evaluationDescription;
          const body = {
            "IdCategory": categoryId,
            "IsoCode": isoCode,
            "Category": translation,
            "EvaluationDescription": evaluationDescription,
          };
          Fetch(
            serviceUrl+'/api/CategoryTranslations/' + categoryId
            + '/' + isoCode, Option('PUT', body)
          )
        }
      }
    }
  }
  return request();
}

/**
 * This function recieves the entry that was deleted from the category table
 * on the admin category page and deletes the entry from the database. 
 * 
 * Note: Deletes all translations of the category.
 * 
 * @param {object} category the entry being deleted
 */
function deleteCategory(category) {
    return Fetch(serviceUrl+'/api/Categories/' + category, Option('DELETE'));
}

export {
  createCategoryTable,
  addNewCategory,
  editCategory,
  deleteCategory
};