/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "react-bootstrap/Container";
import { EditingState } from "@devexpress/dx-react-grid";
import {
  Grid as Gridd,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";
import Select from "react-select";
import Button from "@atlaskit/button";
import { Option, Fetch } from "../helpers/fetchHelpers";
import { createCategoryDropdown } from "../helpers/commonFunctions";
import {
  createStatementTable,
  addNewStatement,
  editStatement,
  deleteStatement
} from "../helpers/statementsFunction";
import {
  Dropdown,
  DropdownIndicator,
  ChevronDown,
  selectStyles,
  TextFix
} from "../helpers/styling";
import { navbar } from "../helpers/adminNavbar";
import CreateConfirmationDialog from "./AdminDialog";
import { serviceUrl } from "../helpers/ServiceURL";

const getRowId = row => row.id;

class AdminStatements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      categories: [],
      statements: [],
      statementsTranslation: [],
      languages: [],
      availableLanguages: [],
      rows: [],
      defaultColumnWidths: [
        { columnName: "id", width: 50 },
        { columnName: "nob", width: 950 },
      ],
      /*
       * Disabling editing for the column ID as this column is automatically
       * generated by the database, and cannot be manually set.
       */
      editingStateColumnExtensions: [
        { columnName: "id", editingEnabled: false }
      ],
      /*
       * This allows for wordwrap for the statements in the 'Norwegian Bokmål'
       * column, this is very useful as these statements can become somewhat
       * long.
       */
      tableColumnExtensions: [{ columnName: "nob", wordWrapEnabled: true }],
      dropdownCategories: [],
      isOpen: false,
      value: undefined
    };
    this.commitChanges = this.commitChanges.bind(this);
  }

  /**
   * This function makes sure that the functions called within are run when the
   * component was mounted. This is done so that the functions called are ready
   * when the user sees the page.
   */
  componentDidMount() {
    this.fetchCategories();
    this.fetchLanguages();
    this.fetchStatements();
  }

  /**
   * This function retrieves all categories from the database and stores it in the
   * state. It also calls the function createCategoryDropdown() so that the dropdown
   * is up to date with the latest fetch from the database.
   */
  fetchCategories() {
    Fetch(serviceUrl + "/api/CategoryTranslations", Option("GET")).then(
      categories => {
        this.setState({ categories: categories });
        this.setState({
          dropdownCategories: createCategoryDropdown(categories)
        });
      }
    );
  }

  /**
   * This function retrieves all statements and statements translations from the
   * database and stores it in the state.
   *
   * The reason both are needed is that the reference to the parent category is
   * stored in the database table Statements while the translations of the
   * statement text is stored in the table StatementsTranslations.
   */
  fetchStatements() {
    Fetch(serviceUrl + "/api/Statements", Option("GET")).then(statements => {
      this.setState({ statements: statements });
    });
    Fetch(serviceUrl + "/api/StatementsTranslations", Option("GET")).then(
      statementsTranslation => {
        this.setState({ statementsTranslation: statementsTranslation });
      }
    );
  }

  /**
   * This fucntion retrives all existing languages and all languages available
   * for translation and stores them in the state for later use.
   */
  fetchLanguages() {
    Fetch(serviceUrl + "/api/AvailableLanguages", Option("GET")).then(
      availableLanguages => {
        this.setState({ availableLanguages: availableLanguages });
      }
    );
    Fetch(serviceUrl + "/api/Languages", Option("GET")).then(languages => {
      this.setState({ languages: languages });
    });
  }

  /**
   * This function creates the columns used for the statements table on the admin
   * statements page. The first column is manually set to be the ID of the
   * statement, the second column is manually set to 'Norwegian Bokmål' as this
   * is the default language. The remaining columns are the other available
   * languages sorted alphabetically.
   *
   * This function also ensures that all columns containing statements have
   * wordwrap enabled.
   *
   * @returns {object} the columns to be used for the statements table
   */
  createStatementColumns() {
    let languages = [];
    /*
     * Norwegian Bokmål is manually defined as it is the default language and
     * should therefore be the first column among the translations.
     */
    let columns = [
      { name: "id", title: "ID" },
      { name: "nob", title: "Norwegian Bokmål" }
    ];
    /*
    this.state.availableLanguages.map(function (language) {
      languages.push(language.isoCode);
    })
    */
    /*
     * This next section of the function is the part that ensures that all
     * statement columns have wordwrap enabled.
     */
    let columnExtensions = [];
    let extensions = this.state.editingStateColumnExtensions;
    this.state.languages.map(function(language) {
      if (languages.includes(language.isoCode) && language.isoCode !== "nob") {
        columns.push({ name: language.isoCode, title: language.nameEnglish });
        var found = false;
        for (var index in extensions) {
          var extension = extensions[index];
          if (
            extension ===
            {
              columnName: language.isoCode,
              wordWrapEnabled: true
            }
          ) {
            found = true;
            break;
          }
        }
        if (!found) {
          columnExtensions.push({
            columnName: language.isoCode,
            wordWrapEnabled: true
          });
        }
      }
    });
    for (var index in columnExtensions) {
      var found = false;
      var newExtension = columnExtensions[index];
      for (var idx in this.state.tableColumnExtensions) {
        var extension = this.state.tableColumnExtensions[idx];
        if (extension === newExtension) {
          found = true;
          break;
        }
      }
      if (!found) {
        this.state.tableColumnExtensions.push(newExtension);
      }
    }
    return columns;
  }

  /**
   * This function toggles open the dropdown menu. Meaning if it's open it
   * closes and if it's closed it opens.
   */
  toggleOpen = () => {
    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  /**
   * This function deccides what happens when something has been selected from
   * the dropdown menu.
   *
   * It toggles the dropdown, updates the state with the selected value and
   * creates the statement table by calling createStatementTable().
   */
  onSelectChange = value => {
    this.toggleOpen();
    this.setState({ value: value });
    this.setState({
      rows: createStatementTable(
        value,
        this.state.statements,
        this.state.statementsTranslation
      )
    });
  };

  /**
   * This function commits the changes done in the table to the datase. And
   * performs the different task depending on whether it was an added, changed
   * or deleted task called.
   *
   * @param {object} param0 an object indicating whether the table function new,
   * edit or delete was used
   */
  commitChanges({ added, changed, deleted }) {
    let { rows } = this.state;
    /*
     * If a new statement has been added, call the addNewStatement() function to
     * add it to the database.
     */
    if (added) {
      addNewStatement(added, this.state.value.value)
        .then(statId => {
          rows = rows.slice();
          var newRow = {
            id: statId,
            nob: added[0].nob,
            eng: added[0].eng
          };
          rows.push(newRow);
          this.setState({ rows });
        })
        .catch(error => console.log("error in update: " + error));
      return;
    }
    /*
     * If a statement has been changed then call the function editStatement() to
     * commit the changes to the database, and then update the table.
     */
    if (changed) {
      editStatement(changed);
      /*
       * Updates the table in place, this part will work even if something went
       * wrong with in the editStatement() function.
       */
      rows = rows.map(row =>
        changed[row.id] ? { ...row, ...changed[row.id] } : row
      );
    }
    /*
     * If a statement has been deleted then call the deleteStatement() function to
     * delete the statement from the database. And then remove the statement from
     * the table.
     */
    if (deleted) {
      this.setState({
        statId: deleted[0],
        statement: this.state.rows.find(x => x.id == deleted[0]).nob,
        showDialog: true
      });
    }
    this.setState({ rows });
  }

  deleteStatement = value => {
    let { rows } = this.state;
    if (value) {
      deleteStatement(this.state.statId);
      /*
       * Updates the table in place, this part will work even if something went
       * wrong with in the deleteStatement() function.
       */
      const deletedSet = new Set([this.state.statId]);
      rows = rows.filter(row => !deletedSet.has(row.id));
      this.setState({ rows });
    }
    this.setState({
      showDialog: false
    });
  };

  render() {
    const {
      rows,
      editingStateColumnExtensions,
      tableColumnExtensions,
      dropdownCategories,
      isOpen,
      value
    } = this.state;
    return (
      <Container style={{ width: "100%" }}>
                <CreateConfirmationDialog
          title={
            "Delete statement " +
            this.state.statement +
            "(" +
            this.state.statId +
            ")?"
          }
          contentText={
            "All setup that has been done for this statement will be lost!"
          }
          warningText={"This action cannot be undone!"}
          showDialog={this.state.showDialog}
          onClick={event => this.deleteStatement(event)}
        />
        {/* Navigation bar for the admin pages */}
        {navbar}
        <Typography variant="h4">Statements</Typography>
        {/* <Grid item xs={12}> */}
        <Grid item style={{width: "105%", marginBottom: 10}} container>
          <TextFix>
            First select a category in the dropdown menu. Then use the
            "NEW"/"EDIT" and "DELETE" to add, edit and delete statements for a
            category.
          </TextFix>
          <TextFix>
            When adding a new statement, please be sure to have added a
            "Norwegian Bokmål" translation as this is the default language.
          </TextFix>
        </Grid>
        <div style={{ width: '30%', height: '10%', marginBottom: '20px' }} >
            <Fragment>
              <Dropdown
                isOpen={isOpen}
                onClose={this.toggleOpen}
                target={
                  <Button
                    iconAfter={<ChevronDown />}
                    onClick={this.toggleOpen}
                    isSelected={isOpen}
                  >
                    {value ? `Category: ${value.label}` : "Select a category"}
                  </Button>
                }
              >
                <Select
                  autoFocus
                  backspaceRemovesValue={false}
                  components={{ DropdownIndicator, IndicatorSeparator: null }}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  menuIsOpen
                  onChange={this.onSelectChange}
                  options={dropdownCategories}
                  placeholder="Search..."
                  styles={selectStyles}
                  tabSelectsValue={false}
                  value={value}
                />
              </Dropdown>
          </Fragment>
          </div>
          <Grid style={{width: "110%", marginBottom: 10}} container>
          <Grid item xs={12}>
            <Gridd
              rows={rows}
              columns={this.createStatementColumns()}
              getRowId={getRowId}
            >
              <EditingState
                onCommitChanges={this.commitChanges}
                defaultEditingRowIds={[0]}
                columnExtensions={editingStateColumnExtensions}
              />
              <Table columnExtensions={tableColumnExtensions} />
              <TableColumnResizing
                defaultColumnWidths={this.createStatementColumns() ? this.state.defaultColumnWidths : null}
              />
              <TableHeaderRow />
              <TableEditRow rowHeight={100}/>
              <TableEditColumn
                showAddCommand
                showEditCommand
                showDeleteCommand
              />
            </Gridd>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default AdminStatements;
