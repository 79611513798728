import { Option, Fetch } from "./fetchHelpers"
import { serviceUrl } from './ServiceURL';

/* eslint-disable array-callback-return */

/**
 * This function creates the content for a table containing the statements of a
 * given category.
 * @param {object} category the parent category of the statements in the table
 * @param {object} statements all existing statements irregardless of category
 * @param {object} statementsTranslation all existing statement translations
 * @returns {object} all statements with all translations belonging to a given
 * category in a table format
 */
function createStatementTable(category, statements, statementsTranslation) {
  let statementsTable = [];
  let statementIdx = [];
  /**
   * This loop finds the id's of all statement belonging to the given category
   */
  for (var id in statements) {
    var statement = statements[id];
    if (statement.idCategory === category.value) {
      statementIdx.push(statement.idStatement);
    }
  }
  var statementRow = {};
  var preId = 0;
  statementsTranslation.map(function (statement) {
    if (statementIdx.includes(statement.idStatement)) {
      /**
       * These if/else statements ensures that the different translations with the 
       * same id ends up in the same row in the table.
       */
      var curId = statement.idStatement;
      if (curId === preId) {
        statementRow[statement.isoCode] = statement.statementText;
      } else if (preId > 0) {
        statementsTable.push(statementRow);
        statementRow = {};
        statementRow['id'] = statement.idStatement;
        statementRow[statement.isoCode] = statement.statementText;
      } else {
        statementRow['id'] = statement.idStatement;
        statementRow[statement.isoCode] = statement.statementText;
      }
      preId = curId;
    }
  })
  /**
   * This if statement prevent an empty row from being added if there is no data
   */
  if (preId > 0) {
    statementsTable.push(statementRow);
  }
  return statementsTable;
}

/**
 * Recieves the added entry from statements table on the admin statements page
 * and adds the entry to the database
 * @param {object} added the statement that is being added (can contain multiple 
 * translations)
 * @param {int} idCategory the id of the category the statement belongs to 
 */
function addNewStatement(added, idCategory) {
  var statement;
  /*
   * Reformats the added statement from:
   * \[
   *   0: {nob: "...", eng: "..."}
   * ]
   * to:
   * {nob: "...", eng: "..."}
   */
  added.map(function (row) {
    statement = row
  })
  /*
   * This async function ensures that the first fetch returns before the second 
   * one starts. This is necessary as the second fetch is dependant on the
   * output of the former.
   */
  const request = async () => {
    const body = { "IdCategory": parseInt(idCategory) };
    /*
     * This fetch creates a new statement entry in the database. This is the
     * parent entry for the translations of the statement, and the id of the 
     * newly added entry is needed as a foreign key for the adding a new 
     * translation.
     */
    const statementDetails = await Fetch(
      serviceUrl+'/api/Statements',
      Option('POST', body)
    );
    const idStatement = await statementDetails.idStatement;
    /*
     * If multiple translations have been added, this loop ensures they all get
     * stored in the database.
     */
    for (var isoCode in statement) {
      const body = {
        "IdStatement": parseInt(idStatement),
        "IsoCode": isoCode,
        "StatementText": statement[isoCode],
      };
      // This fetch add the translation of the statement to the database
      await Fetch(
        serviceUrl+'/api/StatementsTranslations',
        Option('POST', body)
      );
    }
    return idStatement;
  }
  return request();
}

/**
 * Recieves the changed entry from the company type table on the admin company 
 * page and updates the database entry accordingly.
 * 
 * @param {object} changed the statement that is being changed (can contain
 * multiple translations of the statement)
 */
function editStatement(changed) {
  /*
   * This async function ensures that all the fetch functions are run and completed
   * sequentially. 
   */
  const request = async () => {
    for (var statementId in changed) {
      for (var isoCode in changed[statementId]) {
        var translation = changed[statementId][isoCode]
        /*
         * The first fetch checks wether or not the entry being changed exists 
         * or not. If not then a new entry is added to the database, the second 
         * fetch. If the second is activated then the third one is skipped, as 
         * it is unnecessary to update the newly created entry with the same 
         * values as used during its creation.
         */
        const statement = await Fetch(
          serviceUrl+'/api/StatementsTranslations/'
          + statementId + '/' + isoCode, Option('GET'));
        if (statement === undefined) {
          const body = {
            "IdStatement": parseInt(statementId),
            "IsoCode": isoCode,
            "StatementText": translation,
          };
          /*
           * Creates a new database entry if a translation in the current language
           * did not previously exist.
           */
          Fetch(
            serviceUrl+'/api/StatementsTranslations',
            Option('POST', body)
          );
        } else {
          const body = {
            "IdStatement": parseInt(statementId),
            "IsoCode": isoCode,
            "StatementText": translation,
          };
          Fetch(
            serviceUrl+'/api/StatementsTranslations/'
            + statementId + '/' + isoCode, Option('PUT', body)
          );
        }
      }
    }
  }
  request();
}

/**
 * Recieves the entry that was deleted from the statements table on the admin
 * statements page and deletes the entry from the database. 
 * 
 * Note: Deletes all translations of the statement.
 * 
 * @param {object} deleted the entry being deleted
 * @param {list} availableLanguages languages available for translation 
 */
function deleteStatement(deleted) {
  Fetch(serviceUrl+'/api/Statements/' + deleted, Option('DELETE'))
}

export {
  createStatementTable,
  addNewStatement,
  editStatement,
  deleteStatement
};