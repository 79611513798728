/* eslint-disable array-callback-return */
import React from 'react';
import { Grid, Dialog, Typography, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import MaterialButton from '@material-ui/core/Button';
import Container from 'react-bootstrap/Container';
import { EditingState } from '@devexpress/dx-react-grid';
import {
  Grid as Gridd,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn
} from '@devexpress/dx-react-grid-material-ui';
import Select from 'react-select';
import Button from '@atlaskit/button';
import { Option, Fetch } from "../helpers/fetchHelpers"
import { 
  createLanguageTable,
  createLanguageDropdown,
  addNewLanguage,
  removeLanguage
} from '../helpers/languageFunctions';
import {
  Dropdown,
  DropdownIndicator,
  ChevronDown,
  selectStyles,
  TextFix,
} from '../helpers/styling';
import { navbar } from '../helpers/adminNavbar';
import CreateConfirmationDialog from './AdminDialog'
import { serviceUrl } from '../helpers/ServiceURL';

const getRowId = row => row.iso;

class AdminLanguage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      languages: [],
      isOpen: false,
      value: undefined,
      availableLanguages: [],
      rows: [],
      // Static columns
      columns: [
        { name: 'iso', title: 'ISO code' },
        { name: 'name', title: 'Name in english' },
        { name: 'default', title: 'Dafault language' },
      ],
      /*
       * Disabling edititng in the columns ID and default as the ID is 
       * dynamically assigned by the database, and after that it is never 
       * changed. For the default language the reason is that changing this has
       * not yet been implemented.
       */
      editingStateColumnExtensions: [
        { columnName: 'iso', editingEnabled: false },
        { columnName: 'default', editingEnabled: false },
      ],
      isClearable: true,
      isSearchable: true,
      dropdownLanguages: []
    };
  }

  /**
   * This function makes sure that the functions called within are run when the
   * component was mounted. This is done so that the functions called are ready
   * when the user sees the page.
   */
  componentDidMount() {
    this.fetchLanguages();
  }

  /**
   * This fucntion retrives all existing languages and all languages available
   * for translation and stores them in the state for later use.
   * 
   * It calls the createLanguageDropdown() function to create the menu for
   * the 'all languages' dropdown. It also calls the createLanguageTable() function
   * to create the table for the 'avaialable languages' dropdown. 
   */
  fetchLanguages() {
    const request = async () => {
      const lanugages = await Fetch(serviceUrl+'/api/Languages', Option('GET'));
      this.setState({ languages: lanugages });
      this.setState({ dropdownLanguages: createLanguageDropdown(lanugages) });

      const availableLanguages = await Fetch(serviceUrl+'/api/AvailableLanguages', Option('GET'));
      this.setState({ availableLanguages: availableLanguages });
      this.setState({ rows: createLanguageTable(lanugages, availableLanguages) });
    }
    request();
  }

    /**
   * This function commits the changes done in the table to the datase. And 
   * performs the different task depending on whether it was an added, changed
   * or deleted task called.
   * 
   * @param {object} param0 an object indicating whether the table function new,
   * edit or delete was used
   */
  commitChanges({ deleted }) {
    /*
     * If a category has been deleted then call the deleteCategory() function to
     * delete the category from the database. And then remove the category from 
     * the table.
     */
    if (deleted) {
      this.setState({
        isoCode: deleted[0],
        language: this.state.rows.find(x => x.iso == deleted[0]).name,
        showDialog: true
      });
    }
  }

  /**
   * This function toggles open the dropdown menu. Meaning if it's open it 
   * closes and if it's closed it opens.
   */
  toggleOpen = () => {
    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  /**
   * This function deccides what happens when something has been selected from
   * the dropdown menu.
   * 
   * It toggles the dropdown and updates the state with the selected value.
   */
  onSelectChange = value => {
    this.toggleOpen();
    this.setState({ value });
  };

  deleteLang = value => {
    if(value){
      removeLanguage(this.state.isoCode);
    }
    this.setState({
      showDialog: false
    });
  };

  render() {
    const {
      rows,
      columns,
      dropdownLanguages,
      isOpen,
      value
    } = this.state;
    return (
      <Container style={{ width: '80%' }}>
        <CreateConfirmationDialog 
          title={"Delete language "+this.state.language+"("+this.state.isoCode+")?"}
          contentText={"All setup that has been done for this language will be lost!"}
          warningText={"This action cannot be undone!"}
          showDialog={this.state.showDialog}
          onClick={(event) => this.deleteLang(event)}
        />
        {/* Navigation bar for the admin pages */}
        {navbar}
        <Grid container>
          <Grid item xs={8}>
            <TextFix>
              Here you can make a language available for translation.
              Simply choose a language from the dropdown menu and press the add button.
              Page needs to be reloaded to see the changes.
            </TextFix>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Typography variant="h6">
              Add a new language
              </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6">
              Available languages
              </Typography>
          </Grid>
          <Grid item xs={1}>
            <Button
              onClick={() => value && addNewLanguage(value)}
            >
              Add {value ? console.log(value) : null}
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Dropdown
              isOpen={isOpen}
              onClose={this.toggleOpen}
              target={
                <Button
                  iconAfter={<ChevronDown />}
                  onClick={this.toggleOpen}
                  isSelected={isOpen}
                >
                  {value ? `Language: ${value.label}` : 'Select a language'}
                </Button>
              }
            >
              <Select
                autoFocus
                backspaceRemovesValue={false}
                components={{ DropdownIndicator, IndicatorSeparator: null }}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                menuIsOpen
                onChange={this.onSelectChange}
                options={dropdownLanguages}
                placeholder="Search..."
                styles={selectStyles}
                tabSelectsValue={false}
                value={value}
              />
            </Dropdown>
          </Grid>
          <Grid item xs={8}>
            <Gridd
              rows={rows}
              columns={columns}
              getRowId={getRowId}
            >
              <EditingState 
                onCommitChanges={(event) => this.commitChanges(event)}
              />
              <Table />
              <TableHeaderRow />
              <TableEditRow />
              <TableEditColumn
                showDeleteCommand
              />
            </Gridd>
          </Grid>
        </Grid>
      </Container>
    );
  };

};

export default AdminLanguage;