import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import { Form, Header, Segment } from 'semantic-ui-react';
import Container from 'react-bootstrap/Container';
import Background from '../images/ballbinge.jpg';
import { Card, CardTitle, CardText } from 'reactstrap';
import { Icon } from 'semantic-ui-react';
import light_b from '../images/light_b.png';
import { Fetch, Option } from '../components/frontEndHelper/FetchHelper';
import { userId } from '../components/frontEndHelper/SessionHelper';
import { bouvetblue, bouvetdarkblue, bouvetbackgroundgrey, bouvetorange } from './../styles/colors';
import { addUser, updateUser } from '../components/frontEndHelper/storeUser';
import { serviceUrl } from '../components/frontEndHelper/ServiceURL';
import { withNamespaces } from 'react-i18next';

/* Title style*/
const IconContainer = styled.div`
  /* padding-top: 10px; */
  padding-left: 10px;
  display: grid;
  grid-template-columns: repeat(4, minmax(16.66%, 1fr));
  grid-auto-flow: column;
  grid-gap: 5px 5px;
  width: '100%';
  max-width: 600px;
`;

const H1 = styled.h1`
  font-family: arial;
  font-size: 40px;
  width: 100%;
  ::before {width: 100%;left:0% }
  `;

const H2 = styled.h2`
  font-family:arial;
  padding-top: 20px;
  font-size: 26px; 
  font-weight: bolder;
  color: black;
  ::before {width: 70%;left:14.6% }
  `;
  
const P = styled.p`
font-family: sanchez;
font-size: 16px;
`;
const P1 = styled.p`
font-family: sanchez;
font-size: 24px;
margin-bottom: 0;
`;
const GradientBackground = styled.div`
  background: linear-gradient(to right bottom, ${bouvetbackgroundgrey} 50%, white 50%);
`;
const Bottomtext = styled.div`
  width: 100%;
  margin-top: 40px;
  background-image: url("${Background}");
  background-size: 100%;
`;

const Articlelink = styled.div`
  padding-top: 30px;
  float: right;
  font-size: 20px;
`;

const Mybutton = styled.button`
  background: ${bouvetdarkblue};
  color: white;
  font-size: 1em;
  padding: 0.25em 1em;
  border: 2px solid ${bouvetdarkblue};
  border-radius: 3px;
  float: left;

  &:hover {
    background: ${bouvetblue};
    border: 2px solid ${bouvetdarkblue};
  }
`;

const Button = styled.button`
  background: ${bouvetdarkblue};
  padding: 0.25em 1.5em;
  minWidth: 150px;
  &:hover {
    background: ${bouvetblue} 
  }
`;

class Feedback extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      render: false,
      userFeedback: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.onAuto();
  }
  /**
   * This function will store the feedback text
   * for the current user in the database.
   */
  seeFeedbackmessage = txt => {
    let forMonitoring = JSON.parse(sessionStorage.getItem('forMonitoring'));
    let registredInfo = JSON.parse(sessionStorage.getItem('registredInfo'));
    let mongoId = sessionStorage.getItem('mongoId');
    let idUser = parseInt(registredInfo.idUser);
    let idCompanyRole = parseInt(registredInfo.idCompanyRole);
    let idCompanySize = parseInt(registredInfo.idCompanySize);
    let idCompanyType = parseInt(registredInfo.idCompanyType);
    let isoCode = registredInfo.isoCode;
    let nameUser = forMonitoring.nameUser;
    let emailUser = forMonitoring.emailUser;
    let companyName = forMonitoring.companyName;
    let phoneNumber = forMonitoring.phoneNumber;
    let comment = forMonitoring.comment;
    let completed = parseInt(JSON.parse(sessionStorage.getItem('categoriesCompleted')));
    const body = {
      IdUser: idUser,
      IdCompanyType: idCompanyType,
      IdCompanySize: idCompanySize,
      IdCompanyRole: idCompanyRole,
      CompanyName: companyName,
      NameUser: nameUser,
      Email: emailUser,
      Finished: true,
      CategoriesCompleted: completed,
      IsoCode: isoCode,
      Feedback: comment
    };
    Fetch(serviceUrl + '/api/Users/' + idUser, Option('PUT', body)).catch(error => console.error(error));
    this.setState({ render: !this.state.render });
    // Add the user info to cosmosdb as a new item. This need to chenges to only update exisiting user.
    if (mongoId !== undefined) {
      updateUser(idUser); 
    } 
  };

  /**
   * Remove the textarea if feedback is sent
   */
  seeTextareaAndButton() {
    const { t } = this.props;
    const showTextarea = this.state.render;
    if (!showTextarea) {
      return (
        <div>
          <Header
            style={{
              fontWeight: 550,
              paddingLeft: 5
            }}
          >
            {t('Gi tilbakemelding')}
          </Header>
          <Form onSubmit={this.onSubmit}>
            <Form.Field
              style={{
                border: '5px solid #f2f2f2',
                width: '100%',
                minHeight: '10em'
              }}
              fontWeight="400"
              control={TextareaAutosize}
              name="userFeedback"
              labelsize="20px"
              placeholder="Din tilbakemelding..."
              onChange={this.handleChange}
            />
            <Mybutton primary type="submit">
              {t('Send inn')}
            </Mybutton>
          </Form>
        </div>
      );
    }
  }

  /**
   * Handle any change in the feedback input field
   * @param {*} event
   */
  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  /**
   * When user clicked on "send in" button, save data in database
   * <P>Du vil få tilsendt full rapport innen kort tid.</P>
          <P>Hvis du har noen spørsmål eller forslag, send en mail til info@bouvet.no</P>
   */
  onSubmit = e => {
    const { userFeedback } = this.state;
    e.preventDefault();
    this.seeFeedbackmessage(userFeedback);
  };
   /**
   * When user clicked on "Bestill" button on previous page, save data in database
   * <P>Du vil få tilsendt full rapport innen kort tid.</P>
          <P>Hvis du har noen spørsmål eller forslag, send en mail til info@bouvet.no</P>
   */
  onAuto = () => {
    const { userFeedback } = this.state;
    this.seeFeedbackmessage(userFeedback);
  }

  getTitle() {
    const { t } = this.props;
    
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Container className="pageHeader" style={{ textAlign: 'center' }}>
        <H1 style={{paddingTop: '130px'}}>{t("Digital modenhetsanalyse")}</H1>
        <H2 style={{paddingTop: '50px'}}>{t("Din henvendelse er sendt")}</H2>
        <br></br>     
          <h3 style={{ fontSize: '20px', marginTop: '0px', marginBottom: '50px' }}>
            {t("Takk for gjennomføring")}<br/><br/>
            {t("Takk for gjennomføring1")}<br/><br/>
            {t("Takk for gjennomføring2")}<br/><br/>
            {t("Ha en fin dag")}
          </h3>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Button onClick="">          
              <a href="http://www.bouvet.no" style={{color: 'white', textDecoration: 'none'}}>Avslutt</a>
            </Button>  
          </div>
        </Container>          
        
      </div>      
    );
  }

  

  getSoME() {
    const { t } = this.props;
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{display: 'flex', justifyContent: 'left', justifySelf: 'center', paddingTop: '10px', width: '45%'}}>
          <P1 style={{marginTop: '1px', marginBottom: '1px'}}>Del analysen</P1>
          {this.getIcons()}
        </div>
      </div>
    );
  }

  getIcons() {
    return (
      <IconContainer>
        <Icon
          style={{
            margin: 'auto',
            color: bouvetdarkblue,
            textDecoration: 'none'
          }}
          link
          name="facebook square"
          size="big"
        />
        <Icon
          style={{
            margin: 'auto',
            color: bouvetdarkblue,
            textDecoration: 'none'
          }}
          link
          name="twitter"
          size="big"
        />
        <Icon
          style={{
            margin: 'auto',
            color: bouvetdarkblue,
            textDecoration: 'none'
          }}
          link
          name="linkedin"
          size="big"
        />
        <Icon
          style={{
            margin: 'auto',
            color: bouvetdarkblue,
            textDecoration: 'none'
          }}
          link
          name="mail"
          size="big"
        />
      </IconContainer>
    );
  }

  getShare() {
    const { t } = this.props;
    return (
      <GradientBackground>
        <Container className="textContainer">
          <div>
            <p>{t('Tilsendt rapport')}</p>
          </div>
          <div>
            <p>{t('Spørsmål eller forslag')}</p>
          </div>
          <div style={{ textAlign: 'center', paddingTop: 10 }}>
            <p>{t('Del analysen')}</p>
          </div>
          {this.getIcons()}
        </Container>
      </GradientBackground>
    );
  }
  getCommentField() {
    const { t } = this.props;
    return (
      <Container className="textContainer2">
        <Segment
          style={{
            border: 'none',
            paddingtop: '70px',
            paddingBottom: '70px',
            boxShadow: 'none'
          }}
        >
          <div> {this.seeTextareaAndButton()}</div>
          {this.state.render && (
            <div className="corners">
              <div className="top left" />
              <div className="top right" />
              <div className="bottom right" />
              <div className="bottom left" />
              <p
                style={{
                  fontWeight: 400,
                  textAlign: 'center'
                }}
              >
                {t('Takk for din tilbakemelding')}
              </p>
            </div>
          )}
        </Segment>
      </Container>
    );
  }

  getFooter() {
    const { t } = this.props;
    return (
      <Bottomtext>
        .
        <Container fontFamily="source-sans-pro-light">
          <Card
            style={{
              fontWeight: '300',
              align: 'center',
              borderColor: 'white',
              marginTop: '30px',
              width: '500px',
              padding: '40px'
            }}
          >
            <CardTitle style={{ fontSize: 28, fontWeight: '400' }}>{t('Read more title')}</CardTitle>
            <CardText style={{ fontSize: 20 }}>
              {t('Read more text')}
              <Articlelink>
                <a
                  style={{ textDecoration: 'none', color: '#222' }}
                  href="https://www.bouvet.no/ting-vi-kan/digitaliseringsreisen"
                >
                  <Icon
                    style={{
                      border: 0,
                      color: '#222',
                      textDecoration: 'none'
                    }}
                    link
                    name="arrow right"
                  />
                  {t('Les mer')}
                </a>
              </Articlelink>
            </CardText>
          </Card>
          ..
        </Container>
      </Bottomtext>
    );
  }

  render() {
    return (
      <div>
        {this.getTitle()}        
        {/* {this.getShare()} */}
        {/*this.getSoME() */}
        {/*this.getCommentField() */}
        {/*this.getFooter() */}
      </div>
    );
  }
}

export default withNamespaces('Feedback')(Feedback);
