/* eslint-disable no-multi-str */
/* eslint-disable no-redeclare */
/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
import { Fetch, Option } from "./fetchHelpers"
import { serviceUrl } from './ServiceURL';

/**
 * This function takes the company roles and reformats the data such that it
 * fits the dropdown menu format.
 * 
 * @param {object} companyRoles all company roles
 * @returns {object} the menu that can be placed directly into the dropdown
 */
function createRoleDropdown(companyRoles) {
  let dropdown = [{ value: undefined, label: 'All roles' }];
  companyRoles.map(function (role) {
    // Adds only the norwegian translation of the roles to the dropdown menu
    if (role.isoCode === 'nob') {
      dropdown.push({ value: role.idCompanyRole, label: role.companyRole });
    }
  })
  return dropdown;
}

/**
 * This function takes the company roles and reformats the data such that it
 * fits the dropdown menu format.
 * 
 * @param {objecct} companyTypes all company types
 * @returns {object} the menu that can be placed directly into the dropdown
 */
function createTypeDropdown(companyTypes) {
  let types = [{ value: undefined, label: 'All types' }];
  companyTypes.map(function (type) {
    // Adds only the norwegian translation of the types to the dropdown menu
    if (type.isoCode === 'nob') {
      types.push({ value: type.idCompanyType, label: type.companyType });
    }
  })
  return types;
}

/**
 * This function takes the company sizes and reformats the data such that it
 * fits the dropdown menu format.
 * 
 * @param {object} companySizes all the company sizes
 * @returns {object} the menu that can be placed directly into the dropdown
 */
function createSizeDropdown(companySizes) {
  let sizes = [];
  companySizes.map(function (size) {
    sizes.push({ value: size.idCompanySize, label: size.companySize1 });
  })
  return sizes;
}

/**
 * This function takes the statements and creates a dropdown menu with all the
 * statements that correpsonds to the parent category.
 * 
 * @param {object} category the parent category of the statements
 * @param {object} statements all existing statements
 * @param {object} statementsTranslation all existing statements translations
 * @returns {object} the menu that can be placed directly into the dropdown
 */

function createStatementDropdown(category, statements, statementsTranslation, questionaires, type, role, size) {
  let dropdown = [];
  let statementTempIdx = [];
  let statementIdx = [];

  var selectedType = type && type.value ? type.value : undefined;
  var selectedRole = role && role.value ? role.value : undefined;
  var selectedSize = size && size.value ? size.value : undefined;

  // Filters out all statements that does not belong to the given parent category
  for (var i in statements) {
    var statement = statements[i];
    if (statement.idCategory === category.value) {
      statementTempIdx.push(statement.idStatement);
    }
  }

  // Filters out all statements that has already been added to the questionaire
  statementIdx = statementTempIdx.slice();
  for(var idx = statementTempIdx.length; idx--;){
    for(var i in questionaires){
      var statement = questionaires[i];
      if(statement.idStatement === statementTempIdx[idx] && 
        (!selectedType || statement.idCompanyType === selectedType) && 
        (!selectedRole || statement.idCompanyRole === selectedRole) && 
        (!selectedSize || statement.idCompanySize === selectedSize)){
          statementIdx.splice(idx,1);
          break;
      }
    }
  }

  statementsTranslation.map(function (statement) {
    if (statementIdx.includes(statement.idStatement)) {
      // Adds only the norwegian translation of the roles to the dropdown menu
      if (statement.isoCode === 'nob') {
        dropdown.push({
          value: statement.idStatement,
          label: statement.statementText
        });
      }
    }
  })
  return dropdown;
}

function removeQuestionaireEntriesbyId(idStatement, rows, idCompanyRole, idCompanyType, idCompanySize, companyRoles, companyTypes, companySizes){
  if(idCompanyRole && idCompanyRole.value){
    companyRoles = [idCompanyRole.value];
  }else{
    companyRoles = companyRoles.map(function(val){return val.idCompanyRole});
  }
  if(idCompanyType && idCompanyType.value){
    companyTypes = [idCompanyType.value];
  }else{
    companyTypes = companyTypes.map(function(val){return val.idCompanyType});
  }
  if(idCompanySize && idCompanySize.value){
    companySizes = [idCompanySize.value];
  }else{
    companySizes = companySizes.map(function(val){return val.idCompanySize});
  }

  return rows.filter(x => 
    !(x.idStatement == idStatement && 
    companyRoles.includes(x.idCompanyRole) && 
    companyTypes.includes(x.idCompanyType) && 
    companySizes.includes(x.idCompanySize)));
}

function createQuestionaireEntries(idStatement, idCompanyRole, idCompanyType, idCompanySize, statementWeight, companyRoles, companyTypes, companySizes){
  var rows = [];
  if(idCompanyRole){
    companyRoles = [{idCompanyRole: idCompanyRole.value}];
  }
  if(idCompanyType){
    companyTypes = [{idCompanyType: idCompanyType.value}];
  }
  if(idCompanySize){
    companySizes = [{idCompanySize: idCompanySize.value}];
  }

  for(var role in companyRoles){
    for(var comType in companyTypes){
      for(var size in companySizes){
        rows.push({idStatement: idStatement, idCompanyRole: companyRoles[role].idCompanyRole, idCompanyType: companyTypes[comType].idCompanyType, idCompanySize: companySizes[size].idCompanySize, statementWeight: statementWeight})
      }
    }
  }

  return rows;
}

/**
 * This function recieves information from the dropdowns on the admin questionnaires
 * page and returns the correpsonding questionnaire.
 * 
 * @param {object} category the selected category
 * @param {object} selRole the selected company role
 * @param {object} selType the selected company type
 * @param {object} selSize the selected company size
 * @param {object} types all existing company types
 * @param {oject} sizes all existing company sizes
 * @param {object} questionaires all existing questionnaires
 * @param {object} statements all existing statements
 * @param {object} statementsTranslation all existing statements translations
 */
function createQuestionaireTable(
  category, selRole, selType, selSize,
  types, roles, sizes, questionaires, statements, statementsTranslation) {
  /*
   * If the selected company types that were recieved is undefined (meaning that
   * the statement is applicable to all company types) create a list of all
   * company types.
   */
  let companyTypes = [];
  if (selType === undefined || selType.value === undefined) {
    for (var i in types) {
      if (types[i].isoCode === 'nob') {
        companyTypes.push(types[i].idCompanyType);
      }
    }
  }
  /*
   * If the selected company role that were recieved is undefined (meaning that
   * the statement is applicable to all company roles) create a list of all
   * company roles.
   */
  let companyRoles = [];
  if (selRole === undefined || selRole.value === undefined) {
    for (var i in roles) {
      if (roles[i].isoCode === 'nob') {
        companyRoles.push(roles[i].idCompanyRole);
      }
    }
  }
  /*
   * If the selected company sizes that were recieved is undefined (meaning that
   * the statement is applicable to all company sizes) create a list of all
   * company sizes.
   */
  let companySizes = [];
  if (selSize === undefined) {
    for (var i in sizes) {
      companySizes.push(sizes[i].idCompanySize);
    }
  }
  // The ID's of the statements from the questionnaires
  let statementQuestionaireId = [];
  // Stores the statement ID and the statement weight from the questionnaires
  let weights = [];
  for (var id in questionaires) {
    var statement = questionaires[id];
    if (selRole === undefined || selRole.value === undefined) {
      /*
       * If no company size has been selected then retrieve the statemetns 
       * that exists in at least one. Later there is a check to ensure that
       * the retrived statements exist in all company sizes.
       */
      if (selSize === undefined) {
        /*
         * If no company type has been selected then retrieve the statemetns 
         * that exists in at least one. Later there is a check to ensure that
         * the retrived statements exist in all company types.
         */
        if ((selType === undefined || selType.value === undefined)) {
          if (companySizes.includes(statement.idCompanySize)
            && companyTypes.includes(statement.idCompanyType)
            && companyRoles.includes(statement.idCompanyRole)) {
            statementQuestionaireId.push(statement.idStatement);
            weights.push({
              id: statement.idStatement,
              weight: statement.statementWeight
            })
          }
          /*
           * If a company type has been selected then retrieve only the statements 
           * that has a correpsonding reference.
           */
        } else {
          if (statement.idCompanyType === selType.value
            && companySizes.includes(statement.idCompanySize)
            && companyRoles.includes(statement.idCompanyRole)) {
            statementQuestionaireId.push(statement.idStatement);
            weights.push({
              id: statement.idStatement,
              weight: statement.statementWeight
            })
          }
        }
      }
    } else if (statement.idCompanyRole === selRole.value) {
      /*
       * If no company size has been selected then retrieve the statemetns 
       * that exists in at least one. Later there is a check to ensure that
       * the retrived statements exist in all company sizes.
       */
      if (selSize === undefined) {
        /*
         * If no company type has been selected then retrieve the statemetns 
         * that exists in at least one. Later there is a check to ensure that
         * the retrived statements exist in all company types.
         */
        if ((selType === undefined || selType.value === undefined)) {
          if (companySizes.includes(statement.idCompanySize)
            && companyTypes.includes(statement.idCompanyType)) {
            statementQuestionaireId.push(statement.idStatement);
            weights.push({
              id: statement.idStatement,
              weight: statement.statementWeight
            })
          }
          /*
           * If a company type has been selected then retrieve only the statements 
           * that has a correpsonding reference.
           */
        } else {
          if (statement.idCompanyType === selType.value
            && companySizes.includes(statement.idCompanySize)) {
            statementQuestionaireId.push(statement.idStatement);
            weights.push({
              id: statement.idStatement,
              weight: statement.statementWeight
            })
          }
        }
      }
    }
  }
  // The ID's of the statements weight from the questionnaires
  let weightsId = [];
  // Temporary object to store statement ID's and weights
  let temp = {};
  /*
   * When selecting all types or sizes if all weights in each instance is the
   * same then simply show the weight, but if the weight is the same in the
   * different types or sizes then showing a weight could cause som confusion,
   * so instead we show an explanation for why.
   */
  for (var i in weights) {
    if (!weightsId.includes(weights[i].id)) {
      weightsId.push(weights[i].id);
      temp[weights[i].id] = weights[i].weight;
    } else {
      if (!(temp[weights[i].id] === weights[i].weight)) {
        temp[weights[i].id] = 'Value not equal for all types. If you change \
          this here, it will also update the weight for this statement among \
          all company types.';
      }
    }
  }
  // Putting the info from temp back into weights
  weights = [];
  for (var id in temp) {
    weights.push({ id: parseInt(id), weight: temp[id] });
  }
  // Retrieving all statement ID's belonging to the fiven parent category.
  let statementCategoryId = [];
  for (var id in statements) {
    var statement = statements[id];
    if (statement.idCategory === category.value) {
      statementCategoryId.push(statement.idStatement);
    }
  }
  /*
   * Filtering out irrelevant statements, so that only the statements that 
   * correspond to the right category and the right quetionnaire is shown in
   * the table.
   */
  let statementId = [];
  for (var i in statementCategoryId) {
    for (var j in statementQuestionaireId) {
      if (statementCategoryId[i] === statementQuestionaireId[j])
        statementId.push(statementCategoryId[i]);
    }
  }
  // Getting the number of different compnay sizes, 1 if defined
  var numSizes;
  if (selSize === undefined || selSize.value === undefined) {
    numSizes = companySizes.length;
  } else {
    numSizes = 1;
  }
  // Getting the number of different compnay types, 1 if defined
  var numTypes;
  if (selType === undefined || selType.value === undefined) {
    numTypes = companyTypes.length;
  } else {
    numTypes = 1;
  }
  // Getting the number of different compnay roles, 1 if defined
  var numRoles;
  if (selRole === undefined || selRole.value === undefined) {
    numRoles = companyRoles.length;
  } else {
    numRoles = 1;
  }
  /*
   * The count variable is used to count how many times a statement has been
   * stored in the variable statementId. If either all sizes or all types have
   * been selected, or none at all, then the number of occurences should be the
   * product of number of types and number of sizes.
   */
  var count = 1;
  var pre;
  // Reassigning temp to temporarily store the statementId
  temp = statementId;
  statementId = [];
  for (var i in temp) {
    if (temp[i] === pre) {
      count++;
      /*
       * Checking if the statement exists in all types and sizes that has been 
       * selected, if not then it is not added to statementId and will not be 
       * shown in the table.
       * 
       * For example:
       * This ensures that if a statement has only been added for a specific 
       * company type that it doesn't show if all types are shown, and that only
       * the statements that are common among all the types are shown when all 
       * types are selected.
       */
      if (count === (numSizes * numTypes * numRoles)) {
        statementId.push(temp[i]);
      }
    } else {
      count = 1;
    }
    pre = temp[i];
  }
  // Reassigning the temp variable for temporarily storing statements
  temp = {};
  /*
   * Assembeling the final parts for the questionnaire.
   * 
   * This section goes through the translation of the statements and for each
   * statement that belongs to the the questionnaire the all the existing 
   * translation of that statement is added. And then the corresponding weight
   * is also added.
   */
  var preId = 0;
  let questionaire = [];
  statementsTranslation.map(function (statement) {
    if (statementId.includes(statement.idStatement)) {
      var curId = statement.idStatement;
      /*
       * Ensuring that translations of the same statement is stored in the same
       * row. 
       */
      if (curId === preId) {
        temp[statement.isoCode] = statement.statementText;
        /**
         * Since we initialize preId to be 0 and since the statement ID will never
         * be 0 we have to create an exception for it. Which is done by this if
         * statement.
         */
      } else if (preId === 0) {
        temp['id'] = statement.idStatement;
        temp[statement.isoCode] = statement.statementText;
        for (var i in weights) {
          if (curId === weights[i].id) {
            temp['weight'] = weights[i].weight;
          }
        }
      } else {
        questionaire.push(temp);
        temp = {};
        temp['id'] = statement.idStatement;
        temp[statement.isoCode] = statement.statementText;
        // Adding the corresponding statement weight to the row
        for (var i in weights) {
          if (curId === weights[i].id) {
            temp['weight'] = weights[i].weight;
          }
        }
      }
      preId = curId;
    }
  })
  if (preId > 0) {
    questionaire.push(temp);
  }
  return questionaire;
}

/**
 * This function recieves the statement to be added from the statement dropdown
 * on the admin questionaire page along with information about the company type,
 * size and role. It then adds the statement to the questionaire table in the 
 * database with the corresponding company details.
 * 
 * @param {object} added the statement being added
 * @param {object} selRole the selcted company role
 * @param {object} selType the selected company type
 * @param {object} selSize the selected company size
 * @param {object} types all existing company types
 * @param {object} sizes all existing company sizes
 */
function addNewStatement(added, selRole, selType, selSize, types, roles, sizes) {
  /*
   * If the selected company types that were recieved is undefined (meaning that
   * the statement is applicable to all company types) create a list of all
   * company types.
   */
  var post;
  let companyTypes = [];
  if (selType === undefined || selType.value === undefined) {
    for (var i in types) {
      if (types[i].isoCode === 'nob') {
        companyTypes.push(types[i].idCompanyType);
      }
    }
  }
  /*
   * If the selected company types that were recieved is undefined (meaning that
   * the statement is applicable to all company types) create a list of all
   * company types.
   */
  let companyRoles = [];
  if (selRole === undefined || selRole.value === undefined) {
    for (var i in roles) {
      if (roles[i].isoCode === 'nob') {
        companyRoles.push(roles[i].idCompanyRole);
      }
    }
  }
  /*
   * If the selected company sizes that were recieved is undefined (meaning that
   * the statement is applicable to all company sizes) create a list of all
   * company sizes.
   */
  let companySizes = [];
  if (selSize === undefined) {
    for (var i in sizes) {
      companySizes.push(sizes[i].idCompanySize);
    }
  }
  /*
   * If the selected role is undefined, create an entry in the database for all
   * combinations of roles.
   */
  if (selRole === undefined || selRole.value === undefined) {
    for (var k in companyRoles) {
      /*
      * If the selected size is undefined, create an entry in the database for all
      * combinations of sizes.
      */
      if (selSize === undefined) {
        for (var i in companySizes) {
          /*
          * If the selected type is undefined, create an entry in the database for all
          * combinations of types.
          */
          if (selType === undefined || selType.value === undefined) {
            for (var j in companyTypes) {
              try {
                const body = {
                  "IdCompanyType": parseInt(companyTypes[j]),
                  "IdCompanySize": parseInt(companySizes[i]),
                  "IdCompanyRole": parseInt(companyRoles[k]),
                  "IdStatement": parseInt(added.value),
                };
                post = Fetch(
                  serviceUrl+'/api/Questionaires',
                  Option('POST', body)
                );
                /*
                * This catch is here because if we add a statement to all company
                * types, but it already exists in one or more, then trying to add a
                * statement will cause an error. This catch then just skips to the 
                * next company type and tries again.
                */
              } catch (error) {
                continue
              }
            }
            /*
            * If the selected type is defined then add the statement to only
            * the selected company type.
            */
          } else {
            try {
              const body = {
                "IdCompanyType": parseInt(selType.value),
                "IdCompanySize": parseInt(companySizes[i]),
                "IdCompanyRole": parseInt(companyRoles[k]),
                "IdStatement": parseInt(added.value),
              };
              post = Fetch(
                serviceUrl+'/api/Questionaires',
                Option('POST', body)
              );
            } catch (error) {
              continue
            }
          }
        }
      }
    }
    /*
     * If the selected role is defined then add the statement to only
     * the selected company role.
     */
  } else {
    if (selSize === undefined) {
      for (var i in companySizes) {
        /*
        * If the selected type is undefined, create an entry in the database for all
        * combinations of types.
        */
        if (selType === undefined || selType.value === undefined) {
          for (var j in companyTypes) {
            try {
              const body = {
                "IdCompanyType": parseInt(companyTypes[j]),
                "IdCompanySize": parseInt(companySizes[i]),
                "IdCompanyRole": parseInt(selRole.value),
                "IdStatement": parseInt(added.value),
              };
              post = Fetch(
                serviceUrl+'/api/Questionaires',
                Option('POST', body)
              );
              /*
              * This catch is here because if we add a statement to all company
              * types, but it already exists in one or more, then trying to add a
              * statement will cause an error. This catch then just skips to the 
              * next company type and tries again.
              */
            } catch (error) {
              continue
            }
          }
          /*
          * If the selected type is defined then add the statement to only
          * the selected company type.
          */
        } else {
          try {
            const body = {
              "IdCompanyType": parseInt(selType.value),
              "IdCompanySize": parseInt(companySizes[i]),
              "IdCompanyRole": parseInt(selRole.value),
              "IdStatement": parseInt(added.value),
            };
            post = Fetch(
              serviceUrl+'/api/Questionaires',
              Option('POST', body)
            );
          } catch (error) {
            continue
          }
        }
      }
    }
  }
  return post;
}

/**
 * This function recieves the statement that was changed in the questionaire
 * table on the admin questionair page and updates the database correpsondingly.
 * 
 * @param {object} changed the changed statement, with its new weight
 * @param {object} selRole the selected company role
 * @param {object} selType the selected company type
 * @param {object} selSize the selected company size
 * @param {object} types all existing company types
 * @param {object} sizes all existing company sizes
 */
function editStatement(changed, selRole, selType, selSize, types, roles, sizes) {
  /*
   * If the selected company types that were recieved is undefined (meaning that
   * the statement is applicable to all company types) create a list of all
   * company types.
   */
  let companyTypes = [];
  if (selType === undefined || selType.value === undefined) {
    for (var i in types) {
      if (types[i].isoCode === 'nob') {
        companyTypes.push(types[i].idCompanyType);
      }
    }
  }
  /*
   * If the selected company types that were recieved is undefined (meaning that
   * the statement is applicable to all company types) create a list of all
   * company types.
   */
  let companyRoles = [];
  if (selRole === undefined || selRole.value === undefined) {
    for (var i in roles) {
      if (roles[i].isoCode === 'nob') {
        companyRoles.push(roles[i].idCompanyRole);
      }
    }
  }
  /*
   * If the selected company sizes that were recieved is undefined (meaning that
   * the statement is applicable to all company sizes) create a list of all
   * company sizes.
   */
  let companySizes = [];
  if (selSize === undefined) {
    for (var i in sizes) {
      companySizes.push(sizes[i].idCompanySize);
    }
  }
  /*
   * The outermost for-loop is used to extract the id of the statement, which is
   * stored in the variable statement.
   */
  for (var statement in changed) {
    /*
     * This second for-loop is used to extract the new weight, and to update the
     * database with this new value.
     */
    for (var id in changed[statement]) {
      var weight = changed[statement][id];
      /*
       * If the selected role is undefined, create an entry in the database for 
       * all combinations of roles.
       */
      if (selRole === undefined || selRole.value === undefined) {
        for (var k in companyRoles) {
          /*
           * If the selected size is undefined, create an entry in the database for 
           * all combinations of sizes.
           */
          if (selSize === undefined) {
            for (var i in companySizes) {
              /*
              * If the selected type is undefined, create an entry in the database
              * for all combinations of types.
              * 
              * Important Note:
              * This update will override all existing entries for both roles and 
              * types. There is a warning about this on the front end, so it should
              * not come as a surprise.
              */
              if (selType === undefined || selType.value === undefined) {
                for (var j in companyTypes) {
                  const body = {
                    "IdCompanyType": parseInt(companyTypes[j]),
                    "IdCompanySize": parseInt(companySizes[i]),
                    "IdCompanyRole": parseInt(companyRoles[k]),
                    "IdStatement": parseInt(statement),
                    "StatementWeight": weight,
                  };
                  fetch(
                    serviceUrl+'/api/Questionaires/role/'
                    + companyRoles[k] + '/type/' + companyTypes[j]
                    + '/size/' + companySizes[i] + '/statement/' + statement,
                    Option('PUT', body)
                  );
                }
                /*
                * If the selected type is defined then add the statement to only
                * the selected company type.
                */
              } else {
                const body = {
                  "IdCompanyType": parseInt(selType.value),
                  "IdCompanySize": parseInt(companySizes[i]),
                  "IdCompanyRole": parseInt(companyRoles[k]),
                  "IdStatement": parseInt(statement),
                  "StatementWeight": weight,
                };
                Fetch(
                  serviceUrl+'/api/Questionaires/role/'
                  + companyRoles[k] + '/type/' + selType.value
                  + '/size/' + companySizes[i] + '/statement/' + statement,
                  Option('PUT', body)
                );
              }
            }
          }
        }
      } else {
        /*
         * If the selected size is undefined, create an entry in the database for 
         * all combinations of sizes.
         */
        if (selSize === undefined) {
          for (var i in companySizes) {
            /*
            * If the selected type is undefined, create an entry in the database
            * for all combinations of types.
            * 
            * Important Note:
            * This update will override all existing entries for both roles and 
            * types. There is a warning about this on the front end, so it should
            * not come as a surprise.
            */
            if (selType === undefined || selType.value === undefined) {
              for (var j in companyTypes) {
                const body = {
                  "IdCompanyType": parseInt(companyTypes[j]),
                  "IdCompanySize": parseInt(companySizes[i]),
                  "IdCompanyRole": parseInt(selRole.value),
                  "IdStatement": parseInt(statement),
                  "StatementWeight": weight,
                };
                Fetch(
                  serviceUrl+'/api/Questionaires/role/'
                  + selRole.value + '/type/' + companyTypes[j]
                  + '/size/' + companySizes[i] + '/statement/' + statement,
                  Option('PUT', body)
                );
              }
              /*
              * If the selected type is defined then add the statement to only
              * the selected company type.
              */
            } else {
              const body = {
                "IdCompanyType": parseInt(selType.value),
                "IdCompanySize": parseInt(companySizes[i]),
                "IdCompanyRole": parseInt(selRole.value),
                "IdStatement": parseInt(statement),
                "StatementWeight": weight,
              };
              Fetch(
                serviceUrl+'/api/Questionaires/role/'
                + selRole.value + '/type/' + selType.value
                + '/size/' + companySizes[i] + '/statement/' + statement,
                Option('PUT', body)
              );
            }
          }
        }
      }
    }
  }
}

/**
 * This function recieves the state that has been deleted from the questionnarie 
 * table on the admin questionnaire page along with information on wich company
 * role, type and size it belonged to. It then removes the corresponding statement
 * from the database.
 * 
 * @param {object} deleted the statement to be deleted
 * @param {object} selRole the selected company role it belongs to
 * @param {object} selType the selected company type it belongs to
 * @param {object} selSize the selected company size it belongs to
 * @param {object} types all existing company types
 * @param {object} sizes all existing company sizes
 */
function deleteStatement(deleted, selRole, selType, selSize, types, roles, sizes) {
  /*
   * If the selected company types that were recieved is undefined (meaning that
   * the statement is applicable to all company types) create a list of all
   * company types.
   */
  let companyTypes = [];
  if (selType === undefined || selType.value === undefined) {
    for (var i in types) {
      if (types[i].isoCode === 'nob') {
        companyTypes.push(types[i].idCompanyType);
      }
    }
  }
  /*
   * If the selected company roles that were recieved is undefined (meaning that
   * the statement is applicable to all company roles) create a list of all
   * company roles.
   */
  let companyRoles = [];
  if (selRole === undefined || selRole.value === undefined) {
    for (var i in roles) {
      if (roles[i].isoCode === 'nob') {
        companyRoles.push(roles[i].idCompanyRole);
      }
    }
  }
  /*
   * If the selected company sizes that were recieved is undefined (meaning that
   * the statement is applicable to all company sizes) create a list of all
   * company sizes.
   */
  let companySizes = [];
  if (selSize === undefined) {
    for (var i in sizes) {
      companySizes.push(sizes[i].idCompanySize);
    }
  }
  /*
   * If the selected role is undefined, create an entry in the database for all
   * combinations of roles.
   */
  if (selRole === undefined || selRole.value === undefined) {
    for (var k in companyRoles) {
      /*
       * If the selected size is undefined, create an entry in the database for all
       * combinations of sizes.
       */
      if (selSize === undefined) {
        for (var i in companySizes) {
          /*
          * If the selected type is undefined, create an entry in the database for all
          * combinations of types.
          */
          if (selType === undefined || selType.value === undefined) {
            for (var j in companyTypes) {
              try {
                Fetch(
                  serviceUrl+'/api/Questionaires/role/'
                  + companyRoles[k] + '/type/' + companyTypes[j]
                  + '/size/' + companySizes[i] + '/statement/' + deleted[0],
                  Option('DELETE')
                );
                /*
                 * This catch is here because if we add a statement to all company
                 * types, but it already exists in one or more, then trying to add a
                 * statement will cause an error. This catch then just skips to the 
                 * next company type and tries again.
                 */
              } catch (error) {
                continue
              }
            }
            /*
             * If the selected type is defined then add the statement to only
             * the selected company type.
             */
          } else {
            Fetch(
              serviceUrl+'/api/Questionaires/role/'
              + companyRoles[k] + '/type/' + selType.value
              + '/size/' + companySizes[i] + '/statement/' + deleted[0],
              Option('DELETE')
            );
          }
        }
      }
    }
  } else {
    /*
     * If the selected size is undefined, create an entry in the database for all
     * combinations of sizes.
     */
    if (selSize === undefined) {
      for (var i in companySizes) {
        /*
        * If the selected type is undefined, create an entry in the database for all
        * combinations of types.
        */
        if (selType === undefined || selType.value === undefined) {
          for (var j in companyTypes) {
            try {
              Fetch(
                serviceUrl+'/api/Questionaires/role/'
                + selRole.value + '/type/' + companyTypes[j]
                + '/size/' + companySizes[i] + '/statement/' + deleted[0],
                Option('DELETE')
              );
              /*
               * This catch is here because if we add a statement to all company
               * types, but it already exists in one or more, then trying to add a
               * statement will cause an error. This catch then just skips to the 
               * next company type and tries again.
               */
            } catch (error) {
              continue
            }
          }
          /*
           * If the selected type is defined then add the statement to only
           * the selected company type.
           */
        } else {
          Fetch(
            serviceUrl+'/api/Questionaires/role/'
            + selRole.value + '/type/' + selType.value
            + '/size/' + companySizes[i] + '/statement/' + deleted[0],
            Option('DELETE')
          );
        }
      }
    }
  }
}

export {
  createRoleDropdown,
  createTypeDropdown,
  createSizeDropdown,
  createStatementDropdown,
  createQuestionaireTable,
  addNewStatement,
  editStatement,
  deleteStatement,
  removeQuestionaireEntriesbyId,
  createQuestionaireEntries
};