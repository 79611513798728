/* eslint-disable array-callback-return */
import { Option, Fetch } from "./fetchHelpers"
import { serviceUrl } from './ServiceURL';

/**
 * This function creates the table entries for the company role table on the 
 * admin company page. 
 * @param {object} companyRoles all company roles retrieved from the database
 * @return {object} all company roles in the desired table format
 */
function createRolesTable(companyRoles) {
  let roles = [];
  var temp = {};
  var preId = 0;
  companyRoles.map(function (role) {
    var curId = role.idCompanyRole;
    /**
     * These if/else statements ensures that the different translations with the 
     * same id ends up in the same row in the table.
     */
    if (curId === preId) {
      temp[role.isoCode] = role.companyRole;
    } else if (preId > 0) {
      roles.push(temp);
      temp = {};
      temp['id'] = role.idCompanyRole;
      temp[role.isoCode] = role.companyRole;
    } else {
      temp['id'] = role.idCompanyRole;
      temp[role.isoCode] = role.companyRole;
    }
    preId = curId;
  })
  roles.push(temp);
  return roles;
}

/**
 * This function creates the table entries for the company role table on the 
 * admin company page. 
 * @param {object} companyTypes all company types retrieved from the database
 * @return {object} all company types in the desired table format
 */
function createTypesTable(companyTypes) {
  let types = [];
  var temp = {};
  var preId = 0;
  companyTypes.map(function (type) {
    var curId = type.idCompanyType;
    /**
     * These if/else statements ensures that the different translations with the 
     * same id ends up in the same row in the table.
     */
    if (curId === preId) {
      temp[type.isoCode] = type.companyType;
    } else if (preId > 0) {
      types.push(temp);
      temp = {};
      temp['id'] = type.idCompanyType;
      temp[type.isoCode] = type.companyType;
    } else {
      temp['id'] = type.idCompanyType;
      temp[type.isoCode] = type.companyType;
    }
    preId = curId;
  })
  types.push(temp);
  return types;
}

/**
 * This function creates the table entries for the company role table on the 
 * admin company page. 
 * @param {object} companySizes all company sizes retrieved from the database
 * @return {object} all company sizes in the desired table format
 */
function createSizesTable(companySizes) {
  let sizes = [];
  companySizes.map(function (size) {
    sizes.push({ id: size.idCompanySize, size: size.companySize1 });
  })
  return sizes;
}

/**
 * Recieves the added entry from the company role table on the admin company 
 * page and adds the entry to the database.
 * @param {object} added the company role that is being added (can contain 
 * multiple translations of the category)
 */
function addNewCompanyRole(added) {
  var role = {};
  /** Reformats the added role */
  added.map(function (row) {
    role = row;
  })
  /**
   * This async function ensures that the first fetch returns before the second 
   * one starts. This is necessary as the second fetch is dependant on the
   * output of the former.
   */
  const request = async () => {
    const companyDetails = await Fetch(
      serviceUrl+'/api/CompanyRoles', Option('POST', {})
    );
    const idCompanyRole = await companyDetails.idCompanyRole;
    /**
     * If multiple translations have been added, this loop ensures they all get
     * stored in the database.
     */
    for (var isoCode in role) {
      const body = {
        "IdCompanyRole": parseInt(idCompanyRole),
        "IsoCode": isoCode,
        "CompanyRole": role[isoCode],
      };
      Fetch(
        serviceUrl+'/api/CompanyRoleTranslations',
        Option('POST', body)
      );
    }
  }
  request();
}

/**
 * Recieves the changed entry from the company role table on the admin company 
 * page and updates the database entry accordingly.
 * @param {object} changed the company role that is being changed (can contain
 * multiple translations of the category)
 */
function editCompanyRole(changed) {
  /**
   * This async function ensures that all the fetch functions are run and completed
   * sequentially. 
   */
  const request = async () => {
    for (var role in changed) {
      /**
       * If multiple translations have been changed, this loop ensures that they
       * all are updated in the database.
       */
      for (var isoCode in changed[role]) {
        var translation = changed[role][isoCode]
        /**
         * The first fetch checks wether or not the entry being changed exists 
         * or not. If not then a new entry is added to the database, the second 
         * fetch. If the second is activated then the third one is skipped, as 
         * it is unnecessary to update the newly created entry with the same 
         * values as used during its creation.
         */
        const companyRole = await Fetch(
          serviceUrl+'/api/CompanyRoleTranslations/' + role
          + '/' + isoCode, Option('GET')
        );
        if (companyRole === undefined) {
          const body = {
            "IdCompanyRole": role,
            "IsoCode": isoCode,
            "CompanyRole": translation,
          };
          await Fetch(
            serviceUrl+'/api/CompanyRoleTranslations',
            Option('POST', body)
          );
          // Skip changing the newly added entry
          continue;
        }
        const body = {
          "IdCompanyRole": role,
          "IsoCode": isoCode,
          "CompanyRole": translation,
        };
        Fetch(
          serviceUrl+'/api/CompanyRoleTranslations/' + role
          + '/' + isoCode, Option('PUT', body)
        )
      }
    }
  }
  request();
}

/**
 * Recieves the entry that was deleted from the company role table on the admin
 * company page and deletes the entry from the database. 
 * 
 * Note: Deletes all translations.
 * @param {object} deleted the entry being deleted
 * @param {list} availableLanguages languages available for translation 
 */
function deleteCompanyRole(deleted) {
    Fetch(serviceUrl+'/api/CompanyRoles/' + deleted, Option('DELETE'))
}

/**
 * Recieves the added entry from the company type table on the admin company
 * page and adds the new entry to the database.
 * @param {object} added the company type that is being added (can contain
 * multiple translations)
 */
function addNewCompanyType(added) {
  var type;
  /** 
   * Reformats the added type from:
   * \[
   *   0: {nob: "...", eng: "..."}
   * ]
   * to:
   * {nob: "...", eng: "..."}
   */
  added.map(function (row) {
    type = row
  })
  /**
   * This async function ensures that the first fetch returns before the second 
   * one starts. This is necessary as the second fetch is dependant on the
   * output of the former.
   */
  const request = async () => {
    const companyDetails = await Fetch(
      serviceUrl+'/api/CompanyTypes', Option('POST', {})
    );
    const idType = await companyDetails.idCompanyType;
    /**
     * If multiple translations have been added, this loop ensures they all get
     * stored in the database.
     */
    for (var isoCode in type) {
      const body = {
        "IdCompanyType": parseInt(idType),
        "IsoCode": isoCode,
        "CompanyType": type[isoCode],
      };
      await Fetch(
        serviceUrl+'/api/CompanyTypeTranslations',
        Option('POST', body)
      );
    }
  }
  request();
}

/**
 * Recieves the changed entry from the company type table on the admin company 
 * page and updates the database entry accordingly.
 * @param {object} changed the company type that is being changed (can contain
 * multiple translations of the category)
 */
function editCompanyType(changed) {
  /**
   * This async function ensures that all the fetch functions are run and completed
   * sequentially. 
   */
  const request = async () => {
    for (var type in changed) {
      /**
       * If multiple translations have been changed, this loop ensures that they
       * all are updated in the database.
       */
      for (var isoCode in changed[type]) {
        var translation = changed[type][isoCode]
        /**
         * The first fetch checks wether or not the entry being changed exists 
         * or not. If not then a new entry is added to the database, the second 
         * fetch. If the second is activated then the third one is skipped, as 
         * it is unnecessary to update the newly created entry with the same 
         * values as used during its creation.
         */
        const companyTypeTranslation = await Fetch(
          serviceUrl+'/api/CompanyTypeTranslations/' + type
          + '/' + isoCode, Option('GET')
        );
        if (companyTypeTranslation === undefined) {
          const body = {
            "IdCompanyType": type,
            "IsoCode": isoCode,
            "CompanyType": translation,
          };
          await Fetch(
            serviceUrl+'/api/CompanyTypeTranslations',
            Option('POST', body)
          );
          // Skip changing the newly added entry
          continue;
        }
        const body = {
          "IdCompanyType": type,
          "IsoCode": isoCode,
          "CompanyType": translation,
        };
        Fetch(
          serviceUrl+'/api/CompanyTypeTranslations/' + type + '/' + isoCode,
          Option('PUT', body)
        );
      }
    }
  }
  request();
}

/**
 * Recieves the entry that was deleted from the company type table on the admin
 * company page and deletes the entry from the database. 
 * 
 * Note: Deletes all translations.
 * @param {object} deleted the entry being deleted
 * @param {list} availableLanguages - languages available for translation 
 */
function deleteCompanyType(deleted) {
    Fetch(serviceUrl+'/api/CompanyTypes/' + deleted, Option('DELETE'))
}

/**
 * Recieves the added entry from the company size table on the admin company
 * page and adds the new entry to the database.
 * @param {object} added the company size that is being added (can contain
 * multiple translations)
 */
function addNewCompanySize(added) {
  var size;
  /** 
   * Reformats the added type from:
   * \[
   *   0: {nob: "...", eng: "..."}
   * ]
   * to:
   * {nob: "...", eng: "..."}
   */
  added.map(function (row) {
    size = row
  })
  const body = { "CompanySize1": size['size'] };
  Fetch(serviceUrl+'/api/CompanySizes', Option('POST', body))
}

/**
 * Recieves the changed entry from the company size table on the admin company 
 * page and updates the database entry accordingly.
 * @param {object} changed the company size that is being changed
 */
function editCompanySize(changed) {
  for (var i in changed) {
    if (changed[i]) {
      var size = changed[i];
      const body = {
        "IdCompanySize": i,
        "CompanySize1": size.size,
      };
      Fetch(serviceUrl+'/api/CompanySizes/' + i, Option('PUT', body))
    }
  }
}

/**
 * Recieves the entry that was deleted from the company type table on the admin
 * company page and deletes the entry from the database. 
 * 
 * Note: Deletes all translations.
 * @param {object} deleted the entry being deleted
 */
function deleteCompanySize(deleted) {
    Fetch(serviceUrl+'/api/CompanySizes/' + deleted, Option('DELETE'))
}

export {
  createRolesTable,
  createTypesTable,
  createSizesTable,
  addNewCompanyRole,
  editCompanyRole,
  deleteCompanyRole,
  addNewCompanyType,
  editCompanyType,
  deleteCompanyType,
  addNewCompanySize,
  editCompanySize,
  deleteCompanySize,
};