/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@atlaskit/button';
import Container from 'react-bootstrap/Container';
import { EditingState } from '@devexpress/dx-react-grid';
import {
  Grid as Gridd,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import Select from 'react-select';
import { Option, Fetch } from "../helpers/fetchHelpers"
import { 
  createLanguageDropdown,
  createRoleDropdown,
  createTypeDropdown,
  createSizeDropdown,
  createStatementTable, 
  editDescription,
  deleteDescription
} from '../helpers/statementdescriptionsFunctions';
import {
  Dropdown,
  DropdownIndicator,
  ChevronDown,
  selectStyles,
  TextFix,
} from '../helpers/styling';
import { navbar } from '../helpers/adminNavbar';
import { serviceUrl } from '../helpers/ServiceURL';

const getRowId = row => row.id;

class AdminStatementDescriptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: 0,
      type: 0,
      size: 0,
      categories: [],
      languages: [],
      availableLanguages: [],
      // Static columns
      columns: [
        { name: 'id', title: 'ID' },
        { name: 'statement', title: 'Statement' },
        { name: 'weight', title: 'Statement weight' },
        { name: 'description', title: 'Statement Description' },
      ],
      /*
       * Disabling edititng in the columns ID and Category as the ID is 
       * dynamically assigned by the database, and after that it is never 
       * changed. For the category the reason is that the editing og the 
       * category name happens on the admin category page.
       */
      editingStateColumnExtensions: [
        { columnName: 'id', editingEnabled: false },
        { columnName: 'statement', editingEnabled: false },
        { columnName: 'weight', editingEnabled: false },
      ],
      defaultColumnWidths: [
        { columnName: 'id', width: 40 },
        { columnName: 'statement', width: 300 },
        { columnName: 'weight', width: 40 },
        { columnName: 'description', width: 650 },
      ],
      /*
       * This allows for wordwrap in the evaluation column, this is very useful
       * as these descriptions can become somewhat long.
       */
      tableColumnExtensions: [
        { columnName: 'description', wordWrapEnabled: true },
        { columnName: 'statement', wordWrapEnabled: true  },
      ],
      rows: [],
      dropdownLanguages: [],
    };
    this.commitChanges = this.commitChanges.bind(this);
  }

  /**
   * This function makes sure that the functions called within are run when the
   * component was mounted. This is done so that the functions called are ready
   * when the user sees the page.
   */
  componentDidMount() {
    this.fetchQuestionaires();
    this.fetchCategories();
    this.fetchLanguages();
    this.fetchStatements();
    this.fetchCompanyRole();
    this.fetchCompanyType();
    this.fetchCompanySize();
  }

  /**
   * This function retrieves all questionnairies from the database and stores
   * it in the state.
   */
  fetchQuestionaires() {
    Fetch(serviceUrl+'/api/Questionaires', Option('GET'))
      .then((questionaires) => {
        this.setState({ questionaires: questionaires });
      })
  }

  /**
   * This function retrieves all categories from the database and stores it in the
   * state.
   */
  fetchCategories() {
    Fetch(serviceUrl+'/api/CategoryTranslations', Option('GET')).then(categories => {
      this.setState({ categories: categories });
    });
  }

    /**
   * This function retrieves all categories from the database and stores it in the
   * state.
   */
  fetchStatements() {
    Fetch(serviceUrl+'/api/Statements', Option('GET')).then(statement => {
      this.setState({ statement: statement });
    });
    Fetch(serviceUrl+'/api/StatementsTranslations', Option('GET')).then(statementTranslation => {
      this.setState({ statementTranslation: statementTranslation });
    });
    Fetch(serviceUrl+'/api/StatementDescription', Option('GET')).then(statementDescription => {
      this.setState({ statementDescription: statementDescription });
    });
  }

  /**
   * This fucntion retrives all existing languages and all languages available
   * for translation and stores them in the state for later use.
   * 
   * It also calls the createLanguageDropdown() function to create the menu for
   * the available languages dropdown.
   */
  fetchLanguages() {
    const request = async () => {
      const languages = await Fetch(
        serviceUrl+'/api/Languages', Option('GET'));
      this.setState({ languages: languages });

      const availableLanguages = await Fetch(
        serviceUrl+'/api/AvailableLanguages', Option('GET'));
      this.setState({ availableLanguages: availableLanguages });
      this.setState({ 
        dropdownLanguages: createLanguageDropdown(languages, availableLanguages)
      });
    }
    request();
  }

  /**
   * This function retrieves all company roles from the database and stores it
   * in the state. It also calls the function createRoleDropdown() so that the
   * dropdown is up to date with the latest fetch from the database.
   */
  fetchCompanyRole() {
    Fetch(serviceUrl+'/api/CompanyRoleTranslations', Option('GET'))
      .then((companyRoles) => {
        this.setState({ companyRoles: companyRoles });
        this.setState({ dropdownRoles: createRoleDropdown(companyRoles) });
      })
  }

  /**
   * This function retrieves all company types from the database and stores it
   * in the state. It also calls the function createTypeDropdown() so that the
   * dropdown is up to date with the latest fetch from the database.
   */
  fetchCompanyType() {
    Fetch(serviceUrl+'/api/CompanyTypeTranslations', Option('GET'))
      .then((companyTypes) => {
        this.setState({ companyTypes: companyTypes });
        this.setState({ dropdownTypes: createTypeDropdown(companyTypes) });
      })
  }

  /**
   * This function retrieves all company types from the database and stores it
   * in the state. It also calls the function createTypeDropdown() so that the
   * dropdown is up to date with the latest fetch from the database.
   * 
   * NOTE:
   * The 'company sizes' dropdown has not yet been fully implemented.
   */
  fetchCompanySize() {
    Fetch(serviceUrl+'/api/CompanySizes', Option('GET'))
      .then((companySizes) => {
        this.setState({ companySizes: companySizes });
        this.setState({ dropdownSizes: createSizeDropdown(companySizes) });
      })
  }

  /**
   * This function toggles open the dropdown menu. Meaning if it's open it 
   * closes and if it's closed it opens.
   */
  toggleOpenLang = () => {
    this.setState(state => ({ isOpenLang: !state.isOpenLang }));
  };
  /**
   * This function deccides what happens when something has been selected from
   * the dropdown menu.
   * 
   * It toggles the dropdown, updates the state with the selected value and 
   * creates the evaluation table by calling createCategoryTable().
   */
  onSelectChangeLang = value => {
    this.toggleOpenLang();
    this.setState({ language: value });
  };

    /**
   * This function toggles open the dropdown menu. Meaning if it's open it 
   * closes and if it's closed it opens.
   */
  toggleOpenRole = () => {
    this.setState(state => ({ isOpenRole: !state.isOpenRole }));
  };
  /**
   * This function deccides what happens when something has been selected from
   * the dropdown menu.
   * 
   * It toggles the dropdown, updates the state with the selected value and 
   * creates the evaluation table by calling createCategoryTable().
   */
  onSelectChangeRole = value => {
    this.toggleOpenRole();
    this.setState({ role: value });
  };

  /**
   * This function toggles open the dropdown menu. Meaning if it's open it 
   * closes and if it's closed it opens.
   */
  toggleOpenType = () => {
    this.setState(state => ({ isOpenType: !state.isOpenType }));
  };
  /**
   * This function deccides what happens when something has been selected from
   * the dropdown menu.
   * 
   * It toggles the dropdown, updates the state with the selected value and 
   * creates the evaluation table by calling createCategoryTable().
   */
  onSelectChangeType = value => {
    this.toggleOpenType();
    this.setState({ type: value });
  };

  /**
   * This function toggles open the dropdown menu. Meaning if it's open it 
   * closes and if it's closed it opens.
   */
  toggleOpenSize = () => {
    this.setState(state => ({ isOpenSize: !state.isOpenSize }));
  };
  /**
   * This function deccides what happens when something has been selected from
   * the dropdown menu.
   * 
   * It toggles the dropdown, updates the state with the selected value and 
   * creates the evaluation table by calling createCategoryTable().
   */
  onSelectChangeSize = value => {
    this.toggleOpenSize();
    this.setState({ size: value });
  };

  /**
   * This function commits the changes done in the table to the datase. 
   * 
   * @param {object} param0 an object indicating if the table function edit was
   * used
   */
  commitChanges({ changed }) {
    let { statementDescription, language, role, type, size } = this.state;
    let roleVal = role ? role.value : 0;
    let typeVal = type ? type.value : 0;
    let sizeVal = size ? size.value : 0;
    if (changed) {
      for(var idx in changed){
        var change = changed[idx];
        if(change.description != ""){
          const body = {
              "idStatement": parseInt(idx),
              "isoCode": language.value,
              "statementDescription": change.description,
              "role": roleVal,
              "type": typeVal,
              "size": sizeVal
          };
          var newstatementDescriptions = statementDescription.slice().filter(item => !(item.idStatement == parseInt(idx) && item.isoCode == language.value && item.role === roleVal && item.type === typeVal && item.size === sizeVal));
          newstatementDescriptions.push(body);
          this.setState({
            statementDescription: newstatementDescriptions
          })
          editDescription(change, idx, roleVal, typeVal, sizeVal, language.value).then(() => {
            //Verify save OK
          })
        }else{
          var newstatementDescriptions =  statementDescription.slice().filter(item => !(item.idStatement == parseInt(idx) && item.isoCode == language.value && item.role === roleVal && item.type === typeVal && item.size === sizeVal));
          this.setState({
            statementDescription: newstatementDescriptions
          })
          deleteDescription(idx, roleVal, typeVal, sizeVal, language.value);
        }
      };
    }
  }

  render() {
    const {
      columns,
      editingStateColumnExtensions,
      tableColumnExtensions,
      dropdownLanguages,
      isOpenLang,
      language,
      isOpenRole,
      role,
      dropdownRoles,
      isOpenType,
      type,
      dropdownTypes,
      isOpenSize,
      size,
      dropdownSizes,
      categories,
      questionaires,
      statement,
      statementTranslation,
      statementDescription
    } = this.state;
    return (
      <Container style={{ width: '100%' }}>
        {/* Navigation bar for the admin pages */}
        {navbar}
        <Grid style={{width: "110%", marginBottom: 10}} container>
        
            <Typography variant="h4">
              Statement Descriptions
            </Typography>
            <TextFix>
              To edit the description of a statement, first select the language
              and then press the corresponding edit button.
            </TextFix>

            <div style={{ width: '30%', height: '10%', marginBottom: '20px' }} >
            <Fragment>
              <Dropdown
                isOpen={isOpenLang}
                onClose={this.toggleOpenLang}
                target={
                  <Button
                    iconAfter={<ChevronDown />}
                    onClick={this.toggleOpenLang}
                    isSelected={isOpenLang}
                  >
                    {language ? `Language: ${language.label}` : 'Select a language'}
                  </Button>
                }
              >
                <Select
                  autoFocus
                  backspaceRemovesValue={false}
                  components={{ DropdownIndicator, IndicatorSeparator: null }}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  menuIsOpen
                  onChange={this.onSelectChangeLang}
                  options={dropdownLanguages}
                  placeholder="Search..."
                  styles={selectStyles}
                  tabSelectsValue={false}
                  value={language}
                />
              </Dropdown>
              <Dropdown
                isOpen={isOpenRole}
                onClose={this.toggleOpenRole}
                target={
                  <Button
                    iconAfter={<ChevronDown />}
                    onClick={this.toggleOpenRole}
                    isSelected={isOpenRole}
                  >
                    {role ? `Company role: ${role.label}` : 'Select a company role'}
                  </Button>
                }
              >
                <Select
                  autoFocus
                  backspaceRemovesValue={false}
                  components={{ DropdownIndicator, IndicatorSeparator: null }}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  menuIsOpen
                  onChange={this.onSelectChangeRole}
                  options={dropdownRoles}
                  placeholder="Search..."
                  styles={selectStyles}
                  tabSelectsValue={false}
                  value={role}
                />
              </Dropdown>

              <Dropdown
                isOpen={isOpenType}
                onClose={this.toggleOpenType}
                target={
                  <Button
                    iconAfter={<ChevronDown />}
                    onClick={this.toggleOpenType}
                    isSelected={isOpenType}
                  >
                    {type ? `Company type: ${type.label}` : 'Select a company type'}
                  </Button>
                }
              >
                <Select
                  autoFocus
                  backspaceRemovesValue={false}
                  components={{ DropdownIndicator, IndicatorSeparator: null }}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  menuIsOpen
                  onChange={this.onSelectChangeType}
                  options={dropdownTypes}
                  placeholder="Search..."
                  styles={selectStyles}
                  tabSelectsValue={false}
                  value={type}
                />
              </Dropdown>

              <Dropdown
                isOpen={isOpenSize}
                onClose={this.toggleOpenSize}
                target={
                  <Button
                    iconAfter={<ChevronDown />}
                    onClick={this.toggleOpenSize}
                    isSelected={isOpenSize}
                  >
                    {size ? `Company size: ${size.label}` : 'Select a company size'}
                  </Button>
                }
              >
                <Select
                  autoFocus
                  backspaceRemovesValue={false}
                  components={{ DropdownIndicator, IndicatorSeparator: null }}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  menuIsOpen
                  onChange={this.onSelectChangeSize}
                  options={dropdownSizes}
                  placeholder="Search..."
                  styles={selectStyles}
                  tabSelectsValue={false}
                  value={size}
                />
              </Dropdown>

            </Fragment>
            </div>
          {/* <Grid item xs={12}> */}
          <Grid item style={{width: "120%", marginBottom: 10}}>
            {!(categories && language) ? 
              <Gridd
                rows={[]}
                columns={columns}
                getRowId={getRowId}
              >
                <EditingState
                  onCommitChanges={this.commitChanges}
                  defaultEditingRowIds={[0]}
                  columnExtensions={editingStateColumnExtensions}
                />
                <Table columnExtensions={tableColumnExtensions} />
                <TableColumnResizing
                defaultColumnWidths={this.state.defaultColumnWidths}
              />
                <TableHeaderRow />
                <TableEditRow />
                <TableEditColumn
                  showEditCommand
                />
              </Gridd> : 
              (categories.filter(x => x.isoCode === language.value).map(cat => (
                <div key={cat.category}>
                  <Typography variant="h6" >{cat.category}</Typography>
                  <Gridd
                    rows={createStatementTable(language, cat, role, type, size, statement, statementTranslation, statementDescription, questionaires)}
                    columns={columns}
                    getRowId={getRowId}
                  >
                    <EditingState
                      onCommitChanges={this.commitChanges}
                      defaultEditingRowIds={[0]}
                      columnExtensions={editingStateColumnExtensions}
                    />
                    <Table columnExtensions={tableColumnExtensions} />
                    <TableColumnResizing
                      defaultColumnWidths={this.state.defaultColumnWidths}
                    />
                    <TableHeaderRow />
                    <TableEditRow />
                    <TableEditColumn
                      showEditCommand
                    />
                  </Gridd>
                </div>
              )))}
          </Grid>
        </Grid>
      </Container>
    );
  };

};

export default AdminStatementDescriptions;