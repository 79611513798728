/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from 'react-bootstrap/Container';
import { EditingState } from '@devexpress/dx-react-grid';
import {
  Grid as Gridd,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import { Option, Fetch } from "../helpers/fetchHelpers"
import { createTranslationColumns } from '../helpers/commonFunctions';
import {
  createCategoryTable,
  addNewCategory,
  editCategory,
  deleteCategory
} from '../helpers/categoriesFunctions';
import { navbar } from '../helpers/adminNavbar';
import { TextFix } from '../helpers/styling';
import CreateConfirmationDialog from './AdminDialog'
import { serviceUrl } from '../helpers/ServiceURL';

const getRowId = row => row.id;

class AdminCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      languages: [],
      availableLanguages: [],
      /*
       * Disabling editing for the column ID as this column is automatically
       * generated by the database, and cannot be manually set.
       */
      editingStateColumnExtensions: [
        { columnName: 'id', editingEnabled: false },
      ],
      rows: [],
    };
    this.commitChanges = this.commitChanges.bind(this);
  }

  /**
   * This function makes sure that the functions called within are run when the
   * component was mounted. This is done so that the functions called are ready
   * when the user sees the page.
   */
  componentDidMount() {
    this.fetchCategories();
    this.fetchLanguages();
  }

  /**
   * This function retrieves all categories from the database and stores it in the
   * state. It also calls the function createCategoryTable() so that the table is up
   * to date with the latest fetch from the database.
   */
  fetchCategories() {
    Fetch(serviceUrl+'/api/CategoryTranslations', Option('GET'))
      .then((categories) => {
        this.setState({ rows: createCategoryTable(categories) });
      })
  }

  /**
   * This fucntion retrives all existing languages and all languages available
   * for translation and stores them in the state for later use.
   */
  fetchLanguages() {
    Fetch(serviceUrl+'/api/AvailableLanguages', Option('GET'))
      .then((availableLanguages) => {
        this.setState({ availableLanguages: availableLanguages });
      })
    Fetch(serviceUrl+'/api/Languages', Option('GET'))
      .then((languages) => {
        this.setState({ languages: languages });
      })
  }

  /**
   * This function commits the changes done in the table to the datase. And 
   * performs the different task depending on whether it was an added, changed
   * or deleted task called.
   * 
   * @param {object} param0 an object indicating whether the table function new,
   * edit or delete was used
   */
  commitChanges({ added, changed, deleted }) {
    let { rows } = this.state;
    /*
     * If a new category has been added, call the addNewCategory() function to 
     * add it to the database.
     */
    if (added) {
      addNewCategory(added).then(CatId => {
        rows = rows.slice();
        var newRow = {
          id: CatId,
          nob: added[0].nob,
          eng: added[0].eng
        };
        rows.push(newRow);
        this.setState({
          rows: rows
        })
      });
    }
    /*
     * If a category has been changed then call the function editCategory() to
     * commit the changes to the database, and then update the table.
     */
    if (changed) {
      editCategory(changed);
    }
    /*
     * If a category has been deleted then call the deleteCategory() function to
     * delete the category from the database. And then remove the category from 
     * the table.
     */
    if (deleted) {
      this.setState({
        catId: deleted[0],
        category: this.state.rows.find(x => x.id == deleted[0]).nob,
        showDialog: true
      });
    }
  }

  deleteCat = value => {
    if(value){
      deleteCategory(this.state.catId).then(res => {
        this.setState({
          rows: this.state.rows.slice().filter(x => x.id != res.idCategory)
        });
      });
    }
    this.setState({
      showDialog: false
    });
  }

  render() {
    const {
      rows,
      editingStateColumnExtensions
    } = this.state;
    return (
      <Container  style={{ width: '90%' }}>
        <CreateConfirmationDialog 
          title={"Delete category "+this.state.category+"("+this.state.catId+")?"}
          contentText={"All setup that has been done for this category will be lost!"}
          warningText={"This action cannot be undone!"}
          showDialog={this.state.showDialog}
          onClick={(event) => this.deleteCat(event)}
        />
        {/* Navigation bar for the admin pages */}
        {navbar}
        <Typography variant="h4">
          Categories
        </Typography>
        {/* <Grid item xs={12}> */}
          <TextFix>
            When adding a new category please always add a Norwegian Bokmål translation
            as this is the default language.
          </TextFix>
          <TextFix>
            After creating a new category go to the "Evaluation Description" page to
            edit the default description.
            Then go to the "Statements" page to add statements to the category.
          </TextFix>
        {/* </Grid> */}
        <Gridd
          rows={rows}
          columns={
            createTranslationColumns(
              this.state.languages, this.state.availableLanguages)
          }
          getRowId={getRowId}
        >
          <EditingState
            onCommitChanges={this.commitChanges}
            defaultEditingRowIds={[0]}
            columnExtensions={editingStateColumnExtensions}
          />
          <Table />
          <TableHeaderRow />
          <TableEditRow />
          <TableEditColumn
            showAddCommand
            showEditCommand
            showDeleteCommand
          />
        </Gridd>
      </Container>
    );
  };

};


export default AdminCategories;