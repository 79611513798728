import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';
 
export const adalConfig = {
  //tenant: 'c317fa72-b393-44ea-a87c-ea272e8d963d',
  tenant: "bouvetasa.onmicrosoft.com",
  clientId: '71166c34-f3d1-41c3-8646-a7631c034f11',
  endpoints: {
    api: '71166c34-f3d1-41c3-8646-a7631c034f11',
  },
  postLogoutRedirectUri: window.location.origin,
  cacheLocation: 'localStorage',
};
 
export const authContext = new AuthenticationContext(adalConfig);
 

export const getToken = () => {
  return authContext.getCachedToken(authContext.config.clientId);
 };  

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
 
export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);


    
