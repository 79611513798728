import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import registraionENG from './locales/eng/Registration.json';
import analyseENG from './locales/eng/Analyse.json';
import reportENG from './locales/eng/Report.json';
import feedbackENG from './locales/eng/Feedback.json';

import registraionNOB from './locales/nob/Registration.json';
import analyseNOB from './locales/nob/Analyse.json';
import reportNOB from './locales/nob/Report.json';
import feedbackNOB from './locales/nob/Feedback.json';

// the translations

const resources = {
  eng: {
    Registration: registraionENG,
    Analyse: analyseENG,
    Report: reportENG,
    Feedback: feedbackENG,
  },
  nob: {
    Registration: registraionNOB,
    Analyse: analyseNOB,
    Report: reportNOB,
    Feedback: feedbackNOB,
  }
};

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "nob",
    "fallbackLng": "nob",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      wait: true
    }
  });

export default i18n;