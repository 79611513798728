import React from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import htmlToImage from 'html-to-image';
import { Card } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../App.css';
import lightning from '../images/lightning.png';
import { userId, userlanguage } from '../components/frontEndHelper/SessionHelper';
import { Option, Fetch } from '../components/frontEndHelper/FetchHelper';
// import bouvetLogo from "../images/BouvetTagg_Oransje_RGB.png";
import bouvetLogo from "../images/BouvetTagg_Oransje_RGBHor.png";
// import bouvetLogo from "../images/bouvet_logo.png";
import {
  bouvetorange,
  bouvetblue,
  bouvetdarkblue,
  bouvetneongreen,
  bouvetgrey70,
  bouvetbackgroundgrey
} from './../styles/colors';
import { ResponsiveContainer, Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis } from 'recharts';
import { serviceUrl } from '../components/frontEndHelper/ServiceURL';
import { withNamespaces } from 'react-i18next';
import { TextFix } from './frontEndHelper/styling';
import i18n from 'i18next';
import { StatementDescriptions, StatementTranslations } from '../locales/FetchStatement';

const Title = styled.h1`
  font-family: sanchez;
  text-align: center;
  /* display: inline-block;  */
  padding-bottom: 5px;
  padding-top: 20px;
  margin: 0px;
  font-size: 30px;
  width:100%;
  ::before{width:100%;left:0%}
  `;

const H2 = styled.h2`
  font-family: arial;
  padding-bottom: 15px;
  padding-top: 20px;
  font-size: 18px;
  font-weight: bolder;
  color: black
  `;

const Button = styled.button`
  background: ${props => (props.primary ? '': bouvetdarkblue)};
  color: ${props => (props.primary ? bouvetdarkblue : 'white')};

  font-size: .8em;
  padding: 0.15em 1em;
  min-width: 150px;
  border: 2px solid ${bouvetblue};
  border-radius: 3px;
  float: left;

  &:hover {
    background: ${bouvetblue};
    border: 2px solid ${bouvetdarkblue};
    color:'white';
  }

  &:disabled {
    color:'red';
    border: 2px solid ${bouvetdarkblue};
  }
`;

const SmallText=styled.div`
  font-size:16px;
`;

const TableCellTitle=styled.p`
padding-bottom:10px;
font-weight:bold;
font-size:16px;
`;

const BlueTableHeader=styled.p`
  background: ${bouvetdarkblue};
  color: white;
  text-align: center;
  min-height: 100px;
  padding-top: 20px;
  font-size: 18px;
`;

const txtFieldState = {
  value: '',
  valid: true,
  typeMismatch: false
};

const ErrorValidationLabel = ({ txtLbl }) => (
  <label htmlFor="" style={{ color: 'red', paddingTop: 5 }}>
    {txtLbl}
  </label>
);

class Report extends React.Component {
  state = {
    weakImpStatements: [],
    weakImprovmentsStatements: [],
    evenStronger: [],
    strongestImpStatements: [],
    improvments: [],
    submit: false,
    allStatementsUsed: [],
    categories: [], //will be updated to categories fetched from db
    allStatements: [], //will be updated to satatments fetched from db
    //for inputs field
    strongestScore: 0.0,
    weakestScore: 0.0,
    name: {
      ...txtFieldState,
      fieldName: 'Fornavn + Etternavn',
      required: true
    },
    email: {
      ...txtFieldState,
      fieldName: 'Email',
      required: true
    },
    companyname: {
      ...txtFieldState,
      fieldName: 'companyname',
      required: true
    },
    phonenumber: {
      ...txtFieldState,
      fieldName: 'phonenumber',
      required: true
    },
    comment: {
      ...txtFieldState,
      fieldName: 'Comment',
      required: true
    },

    allFieldsValid: false //will be updated if all input are valid
  };

  myFormRef = React.createRef();

  executeScroll = () => {
    this.myFormRef.current.scrollIntoView()
  }

  reduceFormValues = formElements => {
    const arrElements = Array.prototype.slice.call(formElements); //we convert elements/inputs into an array found inside form element

    //we need to extract specific properties in Constraint Validation API using this code snippet
    const formValues = arrElements
      .filter(elem => elem.type === 'text' || elem.type === 'email' || elem.type === 'textarea')
      .map(x => {
        const { typeMismatch } = x.validity;
        const { name, type, value } = x;

        return {
          name,
          type,
          typeMismatch, //we use typeMismatch when format is incorrect(e.g. incorrect email)
          value,
          valid: x.checkValidity()
        };
      })
      .reduce((acc, currVal) => {
        //then we finally use reduce, ready to put it in our state
        const { value, valid, typeMismatch } = currVal;
        const { fieldName, requiredTxt, formatErrorTxt } = this.state[currVal.name]; //get the rest of properties inside the state object

        //we'll need to map these properties back to state so we use reducer...
        acc[currVal.name] = {
          value,
          valid,
          typeMismatch,
          fieldName,
          requiredTxt,
          formatErrorTxt
        };

        return acc;
      }, {});

    return formValues;
  };

  checkAllFieldsValid = formValues => {
    return !Object.keys(formValues)
      .map(x => formValues[x])
      .some(field => !field.valid);
  };

  onSubmit = async e => {
    e.preventDefault();
    const form = e.target;
    //we need to extract specific properties in Constraint Validation API using this code snippet
    const formValues = this.reduceFormValues(form.elements);
    const allFieldsValid = this.checkAllFieldsValid(formValues);
    const freeConsultantHour = Array.prototype.slice.call(form.elements).find(item => item.type === 'checkbox').value;
    let registredInfo,
      idUser,
      idCompanyRole,
      idCompanySize,
      idCompanyType,
      isoCode,
      pngString,
      radarchartBase64String,
      consultantOrdered;

    this.setState({ ...formValues, allFieldsValid }); //we set the state based on the extracted values from Constraint Validation API
    // exract values from input and session storage for the current user and then post it to the database
    let completed = parseInt(JSON.parse(sessionStorage.getItem('categoriesCompleted')));
    try {
      registredInfo = JSON.parse(sessionStorage.getItem('registredInfo')); //retrive values from session to update some other fields
      idUser = parseInt(registredInfo.idUser);
      idCompanyRole = parseInt(registredInfo.idCompanyRole);
      idCompanySize = parseInt(registredInfo.idCompanySize);
      idCompanyType = parseInt(registredInfo.idCompanyType);
      isoCode = registredInfo.isoCode;
      pngString = JSON.parse(sessionStorage.getItem('pngBase64'));
      radarchartBase64String = pngString.pngString;
      consultantOrdered = sessionStorage.getItem('checkbox');
    } catch (e) {
      //Should never occurr under normal use that one of these values cannot be converted to Integer....Only possible if users directly access "/report" page.
      console.log(e);
      return;
    }
    if (allFieldsValid) {
      const body = {
        IdUser: idUser,
        IdCompanyType: idCompanyType,
        IdCompanySize: idCompanySize,
        IdCompanyRole: idCompanyRole,
        CompanyName: formValues.companyname.value,
        FullName: formValues.name.value,
        Email: formValues.email.value,
        Finished: true,
        CategoriesCompleted: completed,
        IsoCode: isoCode,
        Comment: formValues.comment.value,
        PhoneNumber: formValues.phonenumber.value,
        Score: this.getAvergageScore().toString(),
        PngString: radarchartBase64String,
        ConsultantOrdered: consultantOrdered,
        weakImpStatements: this.state.weakImpStatements,
        evenStronger: this.state.evenStronger,
        strongestImpStatements: this.state.strongestImpStatements,
        improvments: this.state.improvments,
        weakImprovmentsStatements: this.state.weakImprovmentsStatements,
        allStatementsUsed: this.state.allStatementsUsed,
        strongestScore: this.state.strongestScore,
        weakestScore: this.state.weakestScore,
        scores: this.getScoresAndSelfEvalScores()
      };
      sessionStorage.setItem(
        'forMonitoring',
        JSON.stringify({
          nameUser: formValues.name.value,
          emailUser: formValues.email.value,
          companyName: formValues.companyname.value,
          phoneNumber: formValues.phonenumber.value,
          comment: formValues.comment.value
        })
      );
      Fetch(serviceUrl + '/api/Users/' + idUser, Option('PUT', body))
        .then(resp => {
          resp.json();
        })
        .catch(error => console.error(error));

      await 
        this.setState({ redirect: true })
        //data will be stored in session to handle further in feeedback page.      
      // this.setState({ redirect: true });
    } else {
      this.setState({
        submit: true
      });
    }
  };

  /**
   * This Function fetchs all categories from the table "CATEGORY_TRANSLATION" in the database.
   */
  fetchCategories() {
    Fetch(serviceUrl + '/api/CategoryTranslations', Option('GET'))
      .then(res => {
        this.setState({ categories: res });
      })
      .catch(error => console.error(error));
  }

  /**
   * This Function fetchs all data from table "QUESTIONAIRE" in the database.
   */
  fetchQuestionaires() {
    Fetch(serviceUrl + '/api/Questionaires', Option('GET'))
      .then(res => {
        this.setState({ allStatements: res });
      })
      .catch(error => console.error(error));
  }

  /**
   * A function that fetch statements that are stored in the db table named: "STATEMENTS".
   */
  fetchStatements = async () => {
    await Fetch(serviceUrl + '/api/Statements', Option('GET'))
      .then(res => {
        this.setState({
          statements: res
        });
      })
      .catch(error => console.error(error));
  };

  componentDidMount() {
    this.fetchCategories();
    this.fetchQuestionaires();
    this.getDescriptions();
    this.getStatementTranslations();
    this.fetchStatements();
  }

  getStatementTranslations() {
    StatementTranslations('eng').then(res => {
      i18n.addResourceBundle('eng', 'Report', res, true, true);
    });

    StatementTranslations('nob').then(res => {
      i18n.addResourceBundle('nob', 'Report', res, true, true);
    });
  }

  getDescriptions() {
    StatementDescriptions('eng').then(res => {
      i18n.addResourceBundle('eng', 'Report', res, true, true);
    });

    StatementDescriptions('nob').then(res => {
      i18n.addResourceBundle('nob', 'Report', res, true, true);
    });
  }
  /**
   * This fuction will assign category id to the id of radarchart, and category description(name)
   * To the subject key in the radarchart.
   * It will also add the self evaluation values for every categories, which is a key in radarchart.
   * The point is the calculated point values that is depend on the weight, rank and a fucntion.
   * The point and selfEval value has not exceeds the fullMark which is 10.
   */
  getInfoCategory() {
    let categoriesToCompare = [];
    this.state.categories.map(category => {
      let cat = JSON.parse(sessionStorage.getItem(category.idCategory));

      if (cat !== null && category.isoCode === userlanguage()) {
        if (cat.length > 0) {
          categoriesToCompare.push({
            id: category.idCategory,
            subject: category.category,
            selfEval: 0,
            point: 0,
            fullMark: 5
          });
        }
      }
    });
    return categoriesToCompare;
  }

  /**
   * This function will fetch the weight, id of every statments from
   * table named  "Questionaires" and assing rank that is stored in session to it.
   * This will help the radarcahrt to works with the chosen statements.
   * @param {*} idOfCategory the category id to retrive the statements that is stored in sessionStorage.
   */
  usersStatement(idOfCategory) {
    let userStatements = []; 

    let storedInSession = JSON.parse(sessionStorage.getItem(idOfCategory));
    let registredInfo = JSON.parse(sessionStorage.getItem('registredInfo'));

    // let idRole = parseInt(registredInfo.idCompanyRole);
    // let idType = parseInt(registredInfo.idCompanyType);
    // let idSize = parseInt(registredInfo.idCompanySize);
    
    // to access all statements these magic values are used
    let idRole =  1;
    let idType = 2;
    let idSize = 29;

      this.state.allStatements.map((item, idx) => {
        if (item.idCompanyRole === idRole && item.idCompanyType === idType && item.idCompanySize === idSize) {
          for (var i = 0; i < storedInSession.length; i++) {
            if (storedInSession[i].id === item.idStatement) {
              //fetch only the answered statments from questionare and then push it...
              userStatements.push({
                idStatement: storedInSession[i].id,
                weight: item.statementWeight,
                rank: storedInSession[i].rank
              });
            }
          }
        }
      });
    return userStatements.sort((x, y) => x.rank - y.rank);
  }

  /**
   * A function to find the maximum point of every chosen statement.
   * @param {*} nrOfchosen Number of chosen statemnts
   * @param {*} rank  chosen rank of every item
   * @param {*} weight registred weight for every item
   */
  point(nrOfchosen, rank, weight) {
    let point = 0;
    point = Math.round((nrOfchosen + 1 - rank) * weight);
    return point;
  }

  /**
   * This function finds the percentage of points that the user has earned
   * toward maximum points he could get.
   */
  findScore(userPoints, maxPoints) {
    let point = 0;
    point = Math.round((userPoints / maxPoints) * 500) / 100;
    return point;
  }

  /**
   * This function will calculate the point earned by the user,
   * There will be used a math function to assing the point. This value
   * is temporary.
   * The radarchart works only if there are more then 3 categories that are answered.
   * For this reason there is added some manual data if to use.
   */
  resultCategories = () => {
    let all = this.getInfoCategory();
    let idUser = userId();
    let data = [];

    all.map(item => {
      let userEval = JSON.parse(sessionStorage.getItem(idUser + item.id));
      item.selfEval = userEval.Evaluation;  
      let answers = this.usersStatement(item.id);
      let point = 0;
      let maxPoint = 0;
      let resultPoint = 0;
      // for the radarchart only the first answers will be used
      for (var i = 0; i < answers.length; i++) {
        let userRank = answers[i].rank + 1;
          point += this.point(answers.length, userRank, answers[i].weight);
          maxPoint += this.point(answers.length, userRank, 5);
        if (!item.highestpoint || item.highestpoint < answers[i].weight) {
          item.highestpoint = answers[i].weight;
        }
      }
      resultPoint = this.findScore(point, maxPoint);
      if (isNaN(resultPoint)){
        item.point = 2.1;
      } else {
        item.point = resultPoint;
      }
      data.push(item);
    });
    //create a function for this.. if user answered only to one of categories..
    if (data.length === 1) {
      data.push({
        id: 'your result',
        subject: 'Analyse',
        selfEval: 8,
        point: 2,
        highestpoint: 6,
        fullMark: 10
      });
      data.push({
        id: 'your result',
        subject: 'Analyse ',
        selfEval: 8,
        point: 1,
        highestpoint: 6,
        fullMark: 10
      });
    }
    if (data.length === 2) {
      data.push({
        id: 'your result',
        subject: 'Analyse',
        selfEval: 8,
        point: 1,
        highestpoint: 6,
        fullMark: 10
      });
    }
    return data;
  };

  /**
   *   Generates the Summarytext for the Report page
   * <p>{t('Oppsummering')}</p> 
     <p>{t('ResultatIntro')}</p>
   */
  getSummaryTxt() {
    const { t } = this.props;
    return (

        <Container className="textContainer">
          <TextFix style={{ marginBottom: 10 }}>
          <p>{t(' ')}</p>            
          </TextFix>
        </Container>

    );
  }

  getFeedbackGrid(statements) {
    const { t } = this.props;
    let regInfo = JSON.parse(sessionStorage.getItem('registredInfo'));
    var idStat = 0;
    if (statements !== null) {
      return (
        <Container
          className="textContainer"
          style={{
            display: 'grid',
            gridTemplateColumns: 'minmax(6%,4fr) minmax(47%, 4fr) minmax(47%, 4fr)',
            padding: 0
          }}
        >

          {statements.map((statement, idx) => {
            idStat = statement.id ? statement.id : statement.idStatement;

            return (
              <React.Fragment key={idx}>
                <div style={{background:bouvetorange, color:'white', textAlign:'center',paddingTop:'25px',fontSize:'16px', borderBottom:'1px solid white'}}>{idx+1}.</div>
                <div style={{ background:'#ffc8a5',borderBottom:'1px solid white', alignItems:'center', padding:'20px'}}>
                  <span style={{ padding: 10 }}>
                    <TableCellTitle>{t('Påstand')}</TableCellTitle>
                    <SmallText>{t('StatId' + idStat)}</SmallText>
                  </span>
                </div>
                <div style={{ background:'#fff0e6',borderBottom:'1px solid white', alignItems:'center', padding:'20px'}}>
                  <span style={{ padding: 10 }}>
                    <TableCellTitle>{t('Forklaring')}</TableCellTitle>
                    <SmallText>{t('StatDesc' + idStat + regInfo.idCompanyRole + regInfo.idCompanyType + regInfo.idCompanySize)}</SmallText>
                  </span>
                </div>
              </React.Fragment>
            );
          })}
        </Container>
      );
    }
    return null;
  }
    getFeedbackIntro(category) {
      const { t } = this.props;
      var TitleStrongSide = <H2>{t('Deres sterkeste side')}: {category.subject}</H2>;
      var TitleWeakSide = <H2>{t('Svakeste side')}: {category.subject}</H2>;
      this.state.strongestScore = category.point;
    return (
      <div style={{ marginTop: 10}}>
        {TitleStrongSide}
        <BlueTableHeader>
          <b>{t('Poengsum')}: </b><br/>
          {category.point} / 5.0 points
        </BlueTableHeader>
      </div>
    );
  }
  getFeedbackIntroWeak(category) {
    const { t } = this.props;
    var TitleStrongSide = <H2>{t('Deres sterkeste side')}: {category.subject}</H2>;
    var TitleWeakSide = <H2>{t('Svakeste side')}: {category.subject}</H2>;
    this.state.weakestScore = category.point;
  return (
    <div style={{ marginTop: 10 }}>
      {TitleWeakSide}
      <BlueTableHeader>
        <b>{t('Poengsum')}: </b><br/>
        {category.point} / 5.0 points
      </BlueTableHeader>
    </div>
  );
}

  /**
   * A function that filters all the statements in the application and returns the ones
   * matching the input category and the selected role, type and size by user.
   * @param {*} categoryId id of category
   */
  compareWithCatgory(categoryId) {
    const { idCompanyRole, idCompanyType, idCompanySize } = JSON.parse(sessionStorage.getItem('registredInfo'));
    const { allStatements, statements } = this.state;
    let statementsIdList = [];
    if (statements && statements.length > 0 && allStatements && allStatements.length > 0) {
      var filteredStatements = statements.filter(x => x.idCategory === categoryId).map(x => x.idStatement);
      allStatements.map(function(statement) {
        if (
          statement.idCompanyRole === idCompanyRole &&
          statement.idCompanyType === idCompanyType &&
          statement.idCompanySize === idCompanySize &&
          filteredStatements.includes(statement.idStatement)
        ) {
          statementsIdList.push({
            id: statement.idStatement,
            statementWeight: statement.statementWeight
          });
        }
      });
      return statementsIdList;
    } else {
      return [];
    }
  }

  /**
   *
   * @param {*} score
   * @param {*} selectedStatements
   */
  findImprovementsForCat(score, catId, selectedStatements) {
    let filteredStat = this.compareWithCatgory(catId);
    var catStatements = [];
    filteredStat.map(statement => {
      if (statement.statementWeight >= score && !selectedStatements.find(selStat => selStat.id === statement.id)) {
        catStatements.push(statement);
      }
    });

    return catStatements.sort((x, y) => x.statementWeight - y.statementWeight).slice(0, 3);
  }

  getStatementDescriptionText() {
    const { t } = this.props;
    var results = this.resultCategories();
    if (results.length === 0) return;
    var strongestCat, weakestCat;
    results.map(result => {
      if (!strongestCat || strongestCat.point < result.point) {
        strongestCat = result;
      }
      if (!weakestCat || weakestCat.point > result.point) {
        weakestCat = result;
      }
    });
    //Get the category where the user scored highest and the one where they scored the lowest from store
    const strongestStatments = JSON.parse(sessionStorage.getItem(strongestCat.id));
    const weakestStatments = JSON.parse(sessionStorage.getItem(weakestCat.id));
    const answers = [];
    answers.push(...JSON.parse(sessionStorage.getItem('1')));
    answers.push(...JSON.parse(sessionStorage.getItem('2')));
    answers.push(...JSON.parse(sessionStorage.getItem('3')));
    answers.push(...JSON.parse(sessionStorage.getItem('4')));
    answers.push(...JSON.parse(sessionStorage.getItem('5')));
    answers.push(...JSON.parse(sessionStorage.getItem('7')));
    answers.push(...JSON.parse(sessionStorage.getItem('8')));
    answers.push(...JSON.parse(sessionStorage.getItem('9')));
    answers.push(...JSON.parse(sessionStorage.getItem('11'))); // brukeropplevelse påstander skal her nå det er klart

    //Based on best and worst category find three statements in same category with higher score(if possible)
    //Will be used to suggest how they can improve
    let regInfo = JSON.parse(sessionStorage.getItem('registredInfo'));
    const improvmentList = [];
    results.map((result, index) => {
      const tempItem = JSON.parse(sessionStorage.getItem(result.id));
      const tempArray = this.findImprovementsForCat(result.highestpoint, result.id, tempItem);
      improvmentList[index] = [];
      tempArray.map(item => {
        idStat = item.id ? item.id : item.idStatement;
        improvmentList[index].push(t('StatId' + idStat));
        improvmentList[index].push(
          t('StatDesc' + idStat + regInfo.idCompanyRole + regInfo.idCompanyType + regInfo.idCompanySize)
        );
      });
    });
    if (
      improvmentList.length > 0 &&
      this.state.improvments.length === 0 &&
      improvmentList[0][0] !== undefined &&
      improvmentList[0][1].indexOf('StatDesc') === -1 &&
      improvmentList[0][0].indexOf('StatId') === -1
    ) {
      console.log(improvmentList);
      this.setState({ improvments: improvmentList });
    }
    var strongImpStatements = this.findImprovementsForCat(
      strongestCat.highestpoint,
      strongestCat.id,
      strongestStatments
    );

    var idStat;
    var strongStatements = [];
    var weakStatements = [];
    var strongestStatements = [];
    var weakImprovmentsStatements = [];
    var allStatementsUsed = [];
    var weakImpStatements = this.findImprovementsForCat(weakestCat.highestpoint, weakestCat.id, weakestStatments);
    strongestStatements.push(strongestCat.subject);
    strongestStatments.map(statement => {
      idStat = statement.id ? statement.id : statement.idStatement;
      strongestStatements.push(t('StatId' + idStat));
      strongestStatements.push(
        t('StatDesc' + idStat + regInfo.idCompanyRole + regInfo.idCompanyType + regInfo.idCompanySize)
      );
    });
    weakStatements.push(weakestCat.subject);
    answers.map(statement => {
      idStat = statement.id ? statement.id : statement.idStatement;
      allStatementsUsed.push(t('StatId' + idStat));
      allStatementsUsed.push(
        t('StatDesc' + idStat + regInfo.idCompanyRole + regInfo.idCompanyType + regInfo.idCompanySize)
      );
    });
    weakestStatments.map(statement => {
      idStat = statement.id ? statement.id : statement.idStatement;
      weakStatements.push(t('StatId' + idStat));
      weakStatements.push(
        t('StatDesc' + idStat + regInfo.idCompanyRole + regInfo.idCompanyType + regInfo.idCompanySize)
      );
    });
    weakImpStatements.map(statement => {
      idStat = statement.id ? statement.id : statement.idStatement;
      weakImprovmentsStatements.push(t('StatId' + idStat));
      weakImprovmentsStatements.push(
        t('StatDesc' + idStat + regInfo.idCompanyRole + regInfo.idCompanyType + regInfo.idCompanySize)
      );
    });
    strongImpStatements.map(statement => {
      idStat = statement.id ? statement.id : statement.idStatement;
      strongStatements.push(t('StatId' + idStat));
      strongStatements.push(
        t('StatDesc' + idStat + regInfo.idCompanyRole + regInfo.idCompanyType + regInfo.idCompanySize)
      );
    });
    if (
      this.state.allStatementsUsed.length === 0 &&
      allStatementsUsed &&
      allStatementsUsed.length > 0 &&
      allStatementsUsed[1].indexOf('StatDesc') === -1 &&
      allStatementsUsed[0].indexOf('StatId') === -1
    ) {
      this.setState({ allStatementsUsed: allStatementsUsed });
    }
    if (
      this.state.strongestImpStatements.length === 0 &&
      strongestStatements &&
      strongestStatements.length > 0 &&
      strongestStatements[2].indexOf('StatDesc') === -1 &&
      strongestStatements[1].indexOf('StatId') === -1
    ) {
      this.setState({ strongestImpStatements: strongestStatements });
    }
    if (
      this.state.strongestImpStatements.length === 0 &&
      strongestStatements &&
      strongestStatements.length > 0 &&
      strongestStatements[2].indexOf('StatDesc') === -1 &&
      strongestStatements[1].indexOf('StatId') === -1
    ) {
      this.setState({ strongestImpStatements: strongestStatements });
    }
    if (
      this.state.weakImpStatements.length === 0 &&
      weakImprovmentsStatements &&
      weakImprovmentsStatements.length !== 0 &&
      weakImprovmentsStatements[1] &&
      weakImprovmentsStatements[0] &&
      weakImprovmentsStatements[1].indexOf('StatDesc') === -1 &&
      weakImprovmentsStatements[0].indexOf('StatId') === -1
    ) {
      // this.setState({ weakImprovmentsStatements: weakImprovmentsStatements });
    }
    if (
      this.state.weakImpStatements.length === 0 &&
      weakStatements &&
      weakStatements.length !== 0 &&
      weakStatements[2] &&
      weakStatements[1] &&
      weakStatements[2].indexOf('StatDesc') === -1 &&
      weakStatements[1].indexOf('StatId') === -1
    ) {
      this.setState({ weakImpStatements: weakStatements });
    }
    if (
      this.state.evenStronger.length === 0 &&
      strongStatements &&
      strongStatements.length !== 0 &&
      strongStatements[1].indexOf('StatDesc') === -1 &&
      strongStatements[0].indexOf('StatId') === -1
    ) {
      this.setState({ evenStronger: strongStatements });
    }
    // this.setState({
    //   weakImpStatements: weakImpStatements,
    //   evenStronger: strongImpStatements,
    //   strongestImpStatements: strongestStatments
    // });

    return (
      <React.Fragment>
        <div style={{ marginBottom: 10, padding: 10 }}>
          {/* {this.getFeedbackIntro(strongestCat, TitleStrongSide)} */}
          {this.getFeedbackIntro(strongestCat)}
          {this.getFeedbackGrid(strongestStatments)}
        </div>
        <div style={{ marginBottom: 25,  padding: 10, }}>
          {this.getFeedbackIntroWeak(weakestCat)}
          {this.getFeedbackGrid(weakestStatments)}
        </div>
      </React.Fragment>
    );
  }

  getAvergageScore() {
    var results = this.resultCategories();
    return (
      Math.round(
        (results
          .map(value => {
            return value.point;
          })
          .reduce((a, b) => a + b, 0) /
          results.length) *
          100
      ) / 100
    );
  }
  getScoresAndSelfEvalScores() {
    var results = this.resultCategories();
    let scores = [];
    results.map(value => { scores.push(value.selfEval); scores.push(value.point) });
    return scores;
  }

  getResultsGraphics() {
    const { t } = this.props;
    var resultCategories = this.resultCategories();
    return (
      <div ref="test23">
        <TextFix>
          <H2>{t('Total poengsum') + ': ' + this.getAvergageScore()} av maksimalt 5.0</H2>
        </TextFix>
        <div id="analysispage">
          <Grid item xs={12}>
            {/* <img src={bouvetLogo} alt="bouvetLogo" style={{ height: "100px", marginBottom: "-200px", marginLeft: "80%", opacity: 0.5 }} /> */}
            {/* <img src={bouvetLogo} alt="bouvetLogo" style={{ height: "100px", marginBottom: "-100px", marginLeft: "1%", opacity: 0.5 }} /> */}
            <img src={bouvetLogo} alt="bouvetLogo" style={{ width: "100px", marginBottom: "-120px", marginLeft: "1%", opacity: 0.5}} />
            <ResponsiveContainer margin="1em" width="100%" height={500}>
              <RadarChart style={{ fontSize: 14 }} data={resultCategories}>
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" />
                <PolarRadiusAxis angle={90} domain={[0, 5]} marginBottom="50px" />
                <Radar
                  name={t('Din vurdering')}
                  dataKey="selfEval"
                  stroke={bouvetorange}
                  fill={bouvetorange}
                  fillOpacity={0.6}
                />
                <Radar
                  name={t('Vår måling')}
                  dataKey="point"
                  stroke={bouvetneongreen}
                  fill={bouvetneongreen}
                  fillOpacity={0.6}
                />

                <Legend />
              </RadarChart>
            </ResponsiveContainer>
          </Grid>
        </div>
        <TextFix>
          <SmallText>
            <strong>{t('Vekting Forklaring')}</strong>
            {t('Vekting Forklaring1')}<br/>
            <strong>{t('Vekting Forklaring2')}</strong>
            {t('Vekting Forklaring3')}
          </SmallText>
        </TextFix>
      </div>
    );
  }
  /**
   * Used to compare your results to others with similiar role, company type and size
   */
  getComparison() {
    const { t } = this.props;
    return (
      <Container className="textContainer">
        <Container style={{ width: '80%' }}>
          <Card
            style={{
              borderColor: 'white',
              position: 'relative',
              marginTop: 70
            }}
          >
            <img
              src={lightning}
              alt="lightning"
              style={{
                width: 80,
                padding: 15,
                position: 'absolute',
                margin: 'auto',
                backgroundColor: '#a3b123',
                top: -40,
                left: '40%'
              }}
            />
            <div
              style={{
                backgroundColor: bouvetneongreen,
                padding: '30px',
                paddingTop: 50,
                fontWeight: 300
              }}
            >
              <p style={{ fontWeight: 400, marginBottom: 1 }}>{t('Sammenligning')}</p>
              {t('SammenligningText')}
            </div>
          </Card>
        </Container>
        <p
          style={{
            paddingTop: 20,
            fontWeight: 300,
            paddingBottom: 20
          }}
        >
          {t('Send Rapport')}
        </p>
      </Container>
    );
  }

  getInformationAboutAnalysis() {
    const { t } = this.props;
    return (
      <TextFix>
        <H2 style={{marginBottom:'0px'}}>{t('Viktig om analysen')}</H2>
        <SmallText>{t('Informasjon om analysen')}</SmallText>
      </TextFix>
    );
  }
  getWhatIsDigitalMaturity() {
    const { t } = this.props;
    return (
      <TextFix>
        <H2 style={{marginTop:'40px'}}>{t('DigitalModenhet?')}</H2>
        <SmallText>{t('HvaErDigitalModenhet')}</SmallText>
        <br></br><br></br>
        <SmallText> {t('HvaErDigitalModenhet1')}</SmallText><br/>
          <Link
            to={{
              pathname: "/report",
              hash: "#contactForm"
            }}
            onClick={this.executeScroll}
            style={{ color: '#0000ff', fontSize: '16px'}}>
            Gå til kontaktskjemaet( nederst på denne siden).
          </Link>
      </TextFix>
    );
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox' && target.checked === true) {
      sessionStorage.setItem('checkbox', 'true');
    } else if (target.type === 'checkbox' && target.checked === false) {
      sessionStorage.setItem('checkbox', 'false');
    }
    const name = target.name;
    this.setState({
      [name]: value
    });
    this.BlobGenerator();
  }
  /*
  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      console.log(reader.result);
      return reader.result;
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
  }
  */
  //BlobGenerator takes a photo from the content in the div by id. The photo(in base64) is sent to the backend and presented in the pdf(from email).
  BlobGenerator = () => {
    window.requestAnimationFrame(() => {
      htmlToImage
        .toBlob(document.getElementById('analysispage')) // Id to the div containing the radarchart
        .then(function(blob) {
          if (blob) {
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = function() {
              console.log(
                'pngBase64',
                JSON.stringify({
                  pngString: reader.result
                })
              );
              sessionStorage.setItem(
                'pngBase64',
                JSON.stringify({
                  pngString: reader.result
                })
              );
            };
          }
        });
    });
  };

  getRegistrationBox(
    renderEmailValidationError,
    renderNameValidationError,
    renderCompanyValidationError,
    renderphoneValidationError
  ) {
    const { t } = this.props;
    /* const isValidForm=true; */
    return (
      <div
        style={{
          width: '100%',
          backgroundColor: bouvetbackgroundgrey,
          paddingBottom: 70
        }}
      >
        <Container className="textContainer" style={{ font: 'source-sans-pro', width: '80%' }}>
          <TextFix>
            <H2 style={{ fontFamily: "source-sans-pro", fontSize: '1em' }}>{t('AvsluttText')}</H2>
            <div style={{ textAlign: 'center' }}>
              <p><a href="https://www.bouvet.no/" style={{ color: '#0000ff', fontSize: '1em' }} > Avslutt og gå til bouvet.no</a></p>
              <div style={{ background: '#999999', height: '1px' }} />
            </div>
          </TextFix>
          <TextFix>
            <H2>{t('Tilbud om mer informasjon')}</H2>
            <SmallText>{t('Mer informasjon tekst')}</SmallText>
          </TextFix>

          <form id="contactForm" ref={this.myFormRef} onSubmit={this.onSubmit} noValidate>
            <Form.Control type="text" name="name" placeholder={t('Fornavn + Etternavn')} required />
            <div className="formInputError">{renderNameValidationError}</div>

            <Form.Control
              type="Email"
              name="email"
              placeholder={t('Email')}
              required
              onChange={() => {
                this.BlobGenerator();
              }}
            />
            <div className="formInputError">{renderEmailValidationError}</div>

            <Form.Control className="formInput" type="text" placeholder={t('Telefon')} name="phonenumber" required />
            <div className="formInputError">{renderphoneValidationError}</div>

            <Form.Control type="text" placeholder={t('Bedrifts navn')} name="companyname" required />
            <div className="formInputError">{renderCompanyValidationError}</div>

            <Form.Control as="textarea" rows="4" placeholder={t('Kommentar')} name="comment" />
            
            <div style={{ display: 'inline-block' }}>
              <Form.Check
                disabled
                hidden
                type="checkbox"
                value={this.state.acceptTerms}
                name="Please Contact me"
                onChange={event => {
                  this.handleChange(event);
                  this.setState({ termErrMsg: '' });
                }}
                // label={<p>{t('Fri time')}</p>}
              />
            </div>
            <p style={{color:'#999999',fontSize:'18px'}}>*Må fylles ut</p>
            <Button /* disabled={isValidForm} */>
              {t('Send')}
            </Button>             
          </form>
          
        </Container>
        
      </div>
    );
  }
  
  createAvbryt() {
    const { t } = this.props;
    let currentCategory = this.state.currentCategory;
    let buttons = [];
    let colx = "background-color:Tomato;";
        
    buttons.push(
      <div className="progressnav" key={currentCategory} style={{ paddingTop: "20px", height: "100%", display: "flex", justifyContent: "center", width: "100%" }} >
        <div>       
        <p >   <a href="https://www.bouvet.no/"> Avbryt</a>  modenhetsanalysen og gå tilbake til startsiden</p>
        </div>
        </div>     
    
        )       
  ;
    return buttons;
    }

  render() {
    const { t } = this.props;
    const { redirect, email, name, companyname, phonenumber } = this.state;

    //if all fields are correct filled out, we redirect to "/feedback"
    if (redirect) {
      return <Redirect to="/feedback" />;
    }

    const renderEmailValidationError = email.valid ? (
      ''
    ) : (
      <ErrorValidationLabel
        txtLbl={email.typeMismatch ? <p>{t('EmailFormatError')}</p> : <p>{t('EmailRequiredTxt')}</p>}
      />
    );

    const renderNameValidationError = name.valid ? '' : <ErrorValidationLabel txtLbl={<p>{t('NameRequired')}</p>} />;

    const renderCompanyValidationError = companyname.valid ? (
      ''
    ) : (
      <ErrorValidationLabel txtLbl={<p>{t('CompanyRequired')}</p>} />
    );

    const renderphoneValidationError = phonenumber.valid ? (
      ''
    ) : (
      <ErrorValidationLabel txtLbl={<p>{t('phoneRequired')}</p>} />
    );

    return (
      <div>
        {/* <Title style={{ backgroundColor: bouvetbackgroundgrey }}>{t('Din Digitale Modenhet')}</Title>
        {this.getSummaryTxt()} */}
        <Container className="textContainer">
        <Title>{t('Din Digitale Modenhet')}</Title>
        {this.getWhatIsDigitalMaturity()}
{/*         {this.getSummaryTxt()} */}
          {this.getResultsGraphics()}

          {this.getInformationAboutAnalysis()}
          {this.getStatementDescriptionText()}
        </Container>
        {this.getRegistrationBox(
          renderEmailValidationError,
          renderNameValidationError,
          renderCompanyValidationError,
          renderphoneValidationError
        )}
      </div>
    );
  }
}

export default withNamespaces('Report')(Report);
