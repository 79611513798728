import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import bouvet_logo from "../images/bouvet_logo.png";
import "../App.css";
import { adalApiFetch } from ".././adalConfig";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Fetch, Option } from "../components/frontEndHelper/FetchHelper";
import { serviceUrl } from "../components/frontEndHelper/ServiceURL";
import i18n from "../i18n";

class Navigation extends Component {
  constructor() {
    super();
    this.state = {
      availableLanguages: [], //A json objects list for the available languages.
      userlanguage: "nob" // A default value for language to avoid conflicts.
    };
    this.handleChange = this.handleChange.bind(this);
  }

  /**
   * A function to fetch languages that are available for now.
   * These are stored in database as "availableLanguages".
   */
  fetchavailableLanguages() {
    Fetch(serviceUrl + "/api/AvailableLanguages", Option("GET"))
      .then(res => {
        this.setState({
          availableLanguages: res
        });
        this.findDefaultLangugae();
      })
      .catch(error => console.error(error));
  }

  /**
   * This function will initialize the userlanguage to the defalut language.
   */
  findDefaultLangugae() {
    this.state.availableLanguages.map(lan => {
      if (lan.languageDefault === true) {
        this.setState({
          userlanguage: lan.isoCode
        });
        this.props.callback(lan.isoCode);
      }
    });
  }

  async storeLanguageChangeInSession(language) {
    let body = JSON.parse(sessionStorage.getItem("registredInfo"));
    if (!body) {
      return;
    }
    body.isoCode = language;
    return await adalApiFetch(
      fetch,
      serviceUrl + "/api/Users/" + body.idUser,
      Option("PUT", body)
    ).then(response => {
      if (response.ok) {
        sessionStorage.setItem("registredInfo", JSON.stringify(body));
      }
    });
  }

  componentWillMount() {
    this.fetchavailableLanguages();
  }

  handleChange = async event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    await this.setState({
      userlanguage: value
    });
    await this.storeLanguageChangeInSession(value);
    i18n.changeLanguage(value);
    this.props.callback(value);
  };

  /**
   * This Function creates an option for every language that is stored in "availableLanguages".
   * The options will be then assigned to a dropdown menu in "languageMenu" function.
   */
  createLanguageOptions() {
    let countryOptions = [];
    this.state.availableLanguages.map((lan, idx) => {
      countryOptions.push(
        <option value={lan.isoCode} key={idx}>
          {lan.nameEnglish}
        </option>
      );
    });
    return countryOptions;
  }

  /**
   * Generates a language menu
   */
  languageMenu() {
    const { userlanguage } = this.state;
    return (
      <Form.Control
        as="select"
        name="userlanguage"
        value={userlanguage}
        onChange={this.handleChange}
      >
        {this.createLanguageOptions()}
      </Form.Control>
    );
  }

  /**
   * Function for creating Nav.Links
   * @param {*} url for the nav link
   * @param {*} text to be shown for the nav link
   */
  createLink(url, text) {
    return (
      <Nav.Link
        className="dropdownItemStyle"
        style={{ paddingLeft: 15, paddingRight: 15 }}
        href={url}
      >
        {text}
      </Nav.Link>
    );
  }

  render() {
    return (
      <Navbar
        collapseOnSelect
        expand="xl"
        sticky="top"
        style={{
          height: 75,
          backgroundColor: "#FFF",
          borderBottom: "2px solid #f2f2f2",
          padding: 0
        }}
      >
        <Navbar.Brand href="https://www.bouvet.no/">
          <img src={bouvet_logo} alt="logo" style={{ width: 120 }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <div className="background-parallellogram-blue-mobileNav"></div>
          <Nav className="mr-auto" style={{ width: "100%" }}>
            {this.createLink("https://www.bouvet.no/prosjekter", "Prosjekter")}
            {this.createLink(
              "https://www.bouvet.no/ting-vi-kan",
              "Ting vi kan"
            )}
            {this.createLink(
              "https://www.bouvet.no/bouvet-deler",
              "Bouvet deler"
            )}
            {this.createLink(
              "https://www.bouvet.no/bli-en-av-oss",
              "Bli en av oss"
            )}
            {this.createLink("https://www.bouvet.no/kurs", "Kurs")}
            {this.createLink("https://www.bouvet.no/investor", "Investor")}
            {this.createLink("https://www.bouvet.no/om-bouvet", "Om oss")}
            {this.createLink("https://minside.bouvet.no", "Min side")}
          </Nav>
          <Nav>
            <Form.Group
              className="dropdownItemStyle"
              style={{ paddingLeft: 10, width: 180 }}
              controlId="exampleForm.ControlSelect1"
            >
              {this.languageMenu()}
            </Form.Group>
          </Nav>
          <div className="background-parallellogram-yellow-mobileNav"></div>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

//Using Navigation.protoTypes to find the current path.
Navigation.protoTypes = {
  callback: PropTypes.func
};
export default withRouter(Navigation);
