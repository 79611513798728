// import Analyse from "./Analyse";
import Analyse from "./AnalyseTest";
import { Component } from "react";
import React from "react";
import { userId } from '../components/frontEndHelper/SessionHelper';
import { Fetch, Option } from '../components/frontEndHelper/FetchHelper';
import "../App.css";
import { serviceUrl } from '../components/frontEndHelper/ServiceURL';

class AnalyseKnapp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      render: false,
      onNextPrevClick: false, //when next or previous button is clicked state will changes.
      dataLoaded:false, //when questionaires are loaded in the parent component state will change.
      idStatments: [],
      prev: false
    };
    this.onPrevious = this.onPrevious.bind(this);
    this.onPrev = this.onPrev.bind(this);
  }

  /**
   * If previous button, show the statements for current category. 
   * Hide the "see statemnts/ se påstander" button.
   * This is used for next button as well.
   */
  onPrevious() {
    this.setState({
      onNextPrevClick: true
    });
  }
  onPrev() {
    this.setState({
      prev: true
    });
    this.props.onPrev();
  }


/**
 * Store the selfevaluation star rating value in session.
 * The key is the id of category + id of user.
 */
  storeRatingToSession() {
    sessionStorage.setItem(
      userId() + this.props.currentIdForKnapp,
      JSON.stringify({
        Evaluation: this.props.evaluationFromParent
      })
    );
  }

  /**
   * Handle the "see statements" button
   */
  seeStatements = () => {
    if(!this.state.render){
      this.setState({ render: true });
    }
    this.props.hideStarRating();
    this.storeRatingToSession();

    //Post every evaluation to the database table named : "SELF_EVALUATION".
    if (this.props.isThisRated === false) {
      const body = {
          IdCategory: this.props.currentIdForKnapp, 
          IdUser: userId(),
          Evaluation: this.props.evaluationFromParent
      }
      Fetch(serviceUrl+"/api/SelfEvaluations",Option("POST", body));
    }
  };

  /**
   * A function that fetchs id of statements that are stored in the db table named: "STATEMENTS".
   */
  fetchIdStatements = async () => {
    await Fetch(serviceUrl+"/api/Statements", Option("GET"))
      .then(res => {
        this.setState({
          idStatments: res
        });
      })
      .catch(error => console.error(error));
  };

  /**
   * A function to check that either questionaires are fully retrived or not.
   * Until react works to fetch these data, a loading bar will be showed.
   */
  allQuestionairesLoaded(){
    if(this.props.allQuestionaires !== undefined){
      this.setState({
        dataLoaded: !this.state.dataLoaded
      });
    }
  }

  //running this async to make sure that every needed data presents.
  componentWillMount = async () => {
    await this.fetchIdStatements();
    this.allQuestionairesLoaded();
  };

  /**
   * In this function every statement will be compared with chosen company type, company role
   * and company size selected by the user.
   */
  compareWithQuestionaires() {
    let statementsIdList = [];
    // idCompany Type == 2 is correct
    // idCompany Type == 1 is incorrect
    // This function does not work correctly

    let registredInfo = JSON.parse(sessionStorage.getItem("registredInfo"));
    let idRole = parseInt(registredInfo.idCompanyRole);
    let idType = parseInt(registredInfo.idCompanyType);
    let idSize = parseInt(registredInfo.idCompanySize);
    this.props.allQuestionaires.map(function(questionaire) {
      if (
        questionaire.idCompanyRole === idRole
        && questionaire.idCompanyType === 2
        && questionaire.idCompanySize === 29
      ) {
        statementsIdList.push(questionaire.idStatement);
      }
    });
    return statementsIdList;
  }

  /**
   * A function that compares every statements that already ahve been filtered 
   * with the function "compareWithQuestionaires()"  with category currently running.
   * This function returns the final statement id list. 
   * @param {*} categoryId id of category
   */
  compareWithCatgory(categoryId) {
    let statementsIdList = [];
    let idsFromQuestionaire = this.compareWithQuestionaires();
    this.state.idStatments.map(function(statement) {
      if (idsFromQuestionaire.includes(statement.idStatement)) {
        if (statement.idCategory === categoryId) {
          statementsIdList.push({id: statement.idStatement});
        }
      }
    });
    return statementsIdList;
  }
  
  dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

  /**
   * This function will filter items that have not been selected from the first draggable list.
   * That is when any selected item are stored in session.
   */
  cleanSelectedItem(input) {
    let statementsForCategory = this.compareWithCatgory(this.props.currentIdForKnapp);

    let storedItems = JSON.parse(sessionStorage.getItem(this.props.currentIdForKnapp));
    const restItems = [];
    
    const tempStat = storedItems ? statementsForCategory.filter(item => {
      return input === !!(storedItems.find(store => store.id === item));
    }) : input ? null : statementsForCategory;
    if(input){
      for(var idx in tempStat) 
      {
        let stat = tempStat[idx];
        restItems.push(
          {
            id: stat.id,
            rank: storedItems.find(x => x.id === stat.id).rank
          }
        )
      }
      return restItems.sort(this.dynamicSort("rank"));
    }else{
      return tempStat;
    }
  }

  getAnalyse(){
    return (
      <Analyse
        t={this.props.t}
        allStatementsForCategory={this.cleanSelectedItem(false)}
        selectedStatementsForCategory={this.cleanSelectedItem(true)}
        onNextPrevClick={this.state.onNextPrevClick}
        onPrevious={this.onPrevious}
        nameForPorgress={this.props.nameForPorgress}
        idOfCat={this.props.idOfCategories}
        indexHandling={this.props.indexHandler}
        indexForAnalyse={this.props.idxForKnapp}
        handleRating={this.props.handleRating}
        didUserRated={this.props.userRated}
        nextButtonDisappear={this.props.nextButtonDisappear}
        enableOrDisableRating={this.props.enableOrDisableRating}
        seeTheNextButton={this.props.seeTheNextButton}
        seeThePrevButtton={this.props.seeThePrevButtton}
        showStarNextCategoy={this.props.showStarRating}
        isFinished={this.props.isFinished}
        onNext={this.props.onNext}
        onPrev={this.onPrev}
      />
    )
  }

  render() {
    var Loader = require('react-loader');
    if (this.state.onNextPrevClick === true) {
      return (
        <div>
          {this.state.onNextPrevClick && (
            this.getAnalyse()
          )}
        </div>
      );
    } else {
      return (
        <div className="AnalyseKnapp">
          {this.state.dataLoaded && this.props.rated ? (
            this.state.dataLoaded && this.seeStatements()
          ) : (
            <div>
              <span>
                <Loader
                  loaded={false}
                  lines={13}
                  length={9}
                  width={6}
                  radius={12}
                  corners={1}
                  rotate={0}
                  direction={1}
                  color="#000"
                  speed={1}
                  trail={60}
                  shadow={false}
                  hwaccel={false}
                  className="spinner"
                  zIndex={2e9}
                  scale={1.0}
                  loadedClassName="loadedContent"
                />
              </span>
            </div>
          )}
          {this.state.render && (
            this.getAnalyse()
          )}
        </div>
      );
    }
  }
}
export default AnalyseKnapp;
