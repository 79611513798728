import React, { PureComponent } from "react";
import StarRatingComponent from "react-star-rating-component";
import { Container } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import AnalyseKnapp from "./AnalyseKnapp";
import styled from "styled-components";
import pc_1 from "../images/pc_1.jpg";
import bouvetLogo from "../images/BouvetTagg_Oransje_RGB.png"
import { bouvetorange, bouvetdarkblue, bouvetblue, bouvetgrey70 } from "./../styles/colors";
import {
  userId,
  userlanguage
} from "../components/frontEndHelper/SessionHelper";
import { Fetch, Option } from "../components/frontEndHelper/FetchHelper";
import "../App.css";
import { serviceUrl } from '../components/frontEndHelper/ServiceURL';
import { withNamespaces } from 'react-i18next';
import i18n from "i18next";
import { StatementTranslations } from '../locales/FetchStatement';
import { createBar } from "../components/frontEndHelper/prgressbarHelper";
import { Navigatebutton } from "../components/frontEndHelper/Navigatebutton";

const Title = styled.div`
  text-align: center;
  display: inline-block;
  padding-bottom: 10px;
  padding-top: 10px;
 /*  margin-left: 17%; */
  /* width: 90%; */
`;
const H1 = styled.h1`
  font-family: sanchez;
  font-size: 40px;
  width: 100%;
  ::before {width: 81%;left:10.2% }
`;
const H2 = styled.h2`
font-family: sanchez;
`;

const GradeScale = styled.div`
  background: #efefef;
  padding: 10px;
`;

const Textfix = styled.div`
  font-size: 18px;
  font-family: sanchez;
`;

const SpacingDiv = styled.div`
  padding: 6px;
`;

const Dot = styled.span`
  height: 40px;
  width: 40px;
  margin-right: .5rem;
`;

const Dot1 = styled.span`
  height: 40px;
  width: 40px;
  margin-right: .5rem;
`;
const DotMobile = styled.span`
  height: 40px;
  width: 40px;
  margin-right: .1rem;
`;
const Container1 = styled(Container)`
width: 100%;
`;

class AnalyseRating extends PureComponent {
  isItMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      rating: 0, //Star rating value
      rated: false, //If rated then the star rating will desapear
      edit: true, // To enable/disable star rating after user rated.
      categories: [], //List of categories that will be fetched
      indexNow: 0, //Currently index
      wantToShowPrev: true, // when click on prevoius button // New design flow gives true
      wantToShowNext: true, //when click on next button
      showStars: true, // stars will disapear if false.
      isFinished: false, //True if the current index the last index
      redirect: false, //On click back return to "/" (Home page)
      allQuestionaires: [], // All questionaires will be fetched in this component and send as props to "Analyseknap.js".
      prev: false
    };
    this.indexHandler = this.indexHandler.bind(this);
    this.onPrev = this.onPrev.bind(this);

    this.ratingHandler = this.ratingHandler.bind(this);
    this.ratingUpdate = this.ratingUpdate.bind(this);

    this.nextButtonDisappear = this.nextButtonDisappear.bind(this);
    this.enableOrDisableRating = this.enableOrDisableRating.bind(this);

    this.hideStarRating = this.hideStarRating.bind(this);
    this.showStarRating = this.showStarRating.bind(this);
  }
  

  /**
   * This Function fetchs all categories from the table "CATEGORY_TRANSLATION" in the database.
   */
  fetchCategories() {
    Fetch(serviceUrl+"/api/CategoryTranslations", Option("GET"))
      .then(res => {
        this.setState({
          categories: res
        });
      })
      .catch(error => console.error(error));
  }

  /**
   * This Function fetchs all data from table "QUESTIONAIRE" in the database.
   */
  fetchQuestionaires = async () => {
    await Fetch(serviceUrl+"/api/Questionaires",Option("GET"))
      .then(res => {
        this.setState({
          allQuestionaires: res
        });
      })
      .catch(error => console.error(error));
  };

  addStatementTranslations(){
    StatementTranslations('eng').then(res => {
      i18n.addResourceBundle('eng', 'Analyse', res, true, true);
    })  
  
    StatementTranslations('nob').then(res => {
      i18n.addResourceBundle('nob', 'Analyse', res, true, true);
    })
  }

  componentWillMount() {
    this.fetchQuestionaires();
    this.addStatementTranslations();
    this.isItMounted = false;
  }

  componentDidMount() {
    this.isItMounted = true;
    if (this.isItMounted) {
      this.fetchCategories();
    }
  }

  componentDidUpdate() {
    //After clcik on previous or next, the next or previous category has to appear.
    this.setStateOfShowHIde(this.getIdOfCategories());
  }

  /**
   * Hide the stars when clicked on "see statements/ se paastander".
   */
  hideStarRating() {
    if(this.state.showStars){
      this.setState({
        showStars: false
      });
    }
  }

  /**
   * Show the stars when clicked on "next", if user have not visited that category;
   */
  showStarRating() {
    if(!this.state.showStars){
      this.setState({
        showStars: true
      });
    }
  }

  /**
   * By click on next or previus button, update the current index in this component as well.
   * @param {*} currentIdx index of the category in a list of categories.
   */
  indexHandler(currentIdx) {
    this.setState({
      indexNow: currentIdx
    });
  }
  onPrev() {
    this.setState({
      rated: false
      });
    
  }

  /**
   * Update statements to the current category after rating.
   */
  ratingHandler() {
    this.setState({
      rated: false
    });
  }

  /**
   * Update rating value to 0 after clicked on next.
   */
  ratingUpdate() {
    this.setState({
      rating: 0
    });
  }

  /**
   * Disappear the next button.
   */
  nextButtonDisappear() {
    this.setState({
      wantToShowNext: false
    });
  }

  /**
   * A function to enable the star rating for the current category.
   * If the current category have been rated then it will be disabled, the star values
   * is stored in session and will be showed. Otherwise the user will be able to rate.
   */
  enableOrDisableRating() {
    let ratingValueInsession = JSON.parse(
      sessionStorage.getItem(userId() + this.findCurrentId(this.state.indexNow))
    );
    //if the start rating value for current category is stored in session.
    if (ratingValueInsession !== null ) {
      this.setState({
        edit: false,
        rated: true,
        rating: ratingValueInsession.Evaluation
      });
    } else {
      this.setState({
        edit: true
      });
    }
  }

  /**
   * Register the user rating in the database.
   * @param {*} nextValue values between 1 - 5 from user
   */
  onStarClick(nextValue) {
    this.setState({
      rating: nextValue,
      rated: false, //set the rated value to true such that user be able to rate next category
      // edit: !this.state.edit
    });
  }

  /**
   * On mouse over the star rating turn on stars for the current value.
   * @param {*} nextValue values between 1 - 5 from user
   */
  onStarHover(nextValue) {
    this.setState({
      rating: nextValue
    });
  }

  /**
   * Check if the current category has been rated, if yes then dont post for rating agin.
   * The key for sessionstage of this are: the user id + the id of current category.
   */
  isThisRated() {
    if (
      JSON.parse(
        sessionStorage.getItem(
          userId() + this.findCurrentId(this.state.indexNow)
        ) !== null
      )
    ) {
      return false;
    }
    return false;
  }

  /**
   * This function will handle when user is in the path  "../report".
   * If user click on back from "./report" page then redirect him to the path "/" (home page).
   * The key 'categoriesCompleted' will only be assigned a value when user has finished all
   * categories and is in the path "../report"
   */
  onBackClick() {
    if (JSON.parse(sessionStorage.getItem("categoriesCompleted")) !== null) {
      this.setState({
        redirect: true
      });
    }
  }

  /**
   * GET the id of all categories that exist in the database
   * and fetched by fetchCategories() method. Then put it in a list to handle it.
   */
  getIdOfCategories() {
    let idOfCat = []; //list of category ids
    if (this.isItMounted) {
      this.state.categories.map(function(category) {
        if (category.isoCode === userlanguage()) {
          idOfCat.push(category.idCategory);
        }
      });
    }
    return idOfCat;
  }

  /**
   * To show the category descriptions of the current category on top of the questionaire page.
   * For the first index it will contain some information about the rating and category.
   * @param {*} id of category that currently running
   */
  getCategory(id) {
    const { t } = this.props;
    console.log("hei på deg");
    const ratingExplanation = [
      'Description2Rate1',
      'Description2Rate2',
      'Description2Rate3',
      'Description2Rate4',
      'Description2Rate5'
    ];
    let cat = [];let indx = ratingExplanation.length;
    if (this.state.categories.length === 0) return null;
    if (this.state.categories.length === 0) {console.log("Hei på deg")};
    this.state.categories.map((category, idx) => {
      if (category.isoCode === userlanguage()) {
        if (category.idCategory === id) {
          console.log("hei på deg 2");
          cat.push(
            <GradeScale key={category.evaluationDescription}>
              {/* {this.state.showStars ?( */}
              <div class="container" style={{ width: '80vw' }}>
                {ratingExplanation.map((col) => {
                  indx--;
                  return (
                    <SpacingDiv key={indx} className="row" style={{ padding: '2px 1px' }}>
                      <div className="col" style={{ minWidth: 120, maxWidth: 200, textAlign: 'end' }}>
                        <StarRatingComponent
                          name={(4 - indx + 1).toString()}
                          starCount={4 - indx + 1}
                          value={4 - indx + 1}
                          renderStarIcon={(index, value) => (
                            <Dot> {index <= value ? '●' : '◯'}</Dot>
                          )}
                          // renderStarIcon={() => <span>O◯</span>} this line is a simple example for using cutom symbol
                          editing={false}
                          starColor={bouvetorange}
                        />
                      </div>
                      <div className="col"><p style={{ lineHeight: '26px' }}>{t(col)}</p></div>
                    </SpacingDiv>
                  );
                }
                )}
              </div>
            </GradeScale>
          );
        }
      }
    });
    return cat;
  }
  getCategoryMobile(id) {
    const { t } = this.props;
    const ratingExplanation = [
      'Description2Rate5',
      'Description2Rate4',
      'Description2Rate3',
      'Description2Rate2',
      'Description2Rate1'
    ];
    let cat = [];let indx = ratingExplanation.length;
    if(this.state.categories.length === 0) return null;
    this.state.categories.map((category, idx) => {
      if (category.isoCode === userlanguage()) {
        if (category.idCategory === id) {
          cat.push(
            <GradeScale key={category.evaluationDescription}>
              {/* {this.state.showStars ? ( */}
                <div
                  style={{
                    fontWeight: 300,
                    padding: 12
                  }}>
                <div className="Container">
                  {ratingExplanation.map((col) => {
                    indx--;
                    return (
                      <SpacingDiv key={indx} className="row">
                        <div className="col" style={{minWidth: 110, maxWidth: 120}}>
                          <StarRatingComponent
                            name={""-indx+1}
                            starCount={indx+1}
                            value={indx + 1}
                            renderStarIcon={(index, value) => (
                              <Dot> {index <= value ? '●' :  '◯' }</Dot>
                          )}
                            // renderStarIcon={() => <span>O◯</span>} this line is a simple example for using cutom symbol
                            editing={false}
                            starColor={bouvetorange}
                          />
                        </div>
                        <div className="col flex"><p className="flexcenter">{t(col)}</p></div>
                      </SpacingDiv>
                    );
                  }
                  )}
                </div>
              </div>
              {/* ) : null} */}
            </GradeScale>
          );
        }
      }
    });
    return cat;
  }

  /**
   * This Function will return name of category (field "category" in the CATEGORY_TRANSLATION table in db).
   * @param {*} id of category that currently running
   */
  getNameCategory(id) {
    let name = "";
    this.state.categories.map(function(category) {
      if (category.isoCode === userlanguage()) {
        if (category.idCategory === id) {
          name = category.category;
        }
      }
    });
    return name;
  }

  /**
   * This function will disapear or show buttons : next, previous and finish in questioanire page.
   * @param {*} listOfIdcategories A list that contains all categories
   */
  setStateOfShowHIde(listOfIdcategories) {
    //If its the first category that showing, then dont show the previous button
    // New design flow requires the previous button to always be visible
    if (this.state.indexNow === 0 && this.state.wantToShowPrev) {
      this.setState({
        wantToShowPrev: true
      });
    }
    if (this.state.indexNow >= 0 &&
        this.state.indexNow < listOfIdcategories.length - 1 && !this.state.wantToShowNext) {
      this.setState({
        wantToShowNext: true
      });
    }
    if (this.state.indexNow > 0) {
      this.setState({
        wantToShowPrev: true
      });
    }
    if (this.state.indexNow < listOfIdcategories.length - 1 && this.state.isFinished) {
      this.setState({
        isFinished: false
      });
    }
    if (this.state.indexNow === listOfIdcategories.length - 1) {
      this.setState({
        isFinished: true
      });
    }
    if (listOfIdcategories.length === 1) {
      this.setState({
        wantToShowNext: false
      });
    }
  }

  /**
   * A function to find current id of the running category.
   * @param {*} idx index in array of categories.
   */
  findCurrentId(idx) {
    let listOfIdcategories = this.getIdOfCategories();
    let anId = listOfIdcategories[idx];
    return anId;
  }

  prev = async  () => {
    if (this.state.indexNow === 0) {
      this.setState({ redirect: true })
      return null;
    } else {
      this.setState({ indexNow: this.state.indexNow - 1 })
      this.setState({ rated: true })
      const answers = [];
      answers.push(...JSON.parse(sessionStorage.getItem(this.state.indexNow)));
      console.log(this.state.indexNow);
      console.log("bra");
      console.log(answers);
      return answers;
    }
  }
  next(nextValue) {
    if (!this.state.rated) {
      if (this.rating !== 0) {
        this.setState({
          rating: nextValue,
          rated: true, //set the rated value to true such that user be able to rate next category
          // edit: !this.state.edit
        });
      } 
    }
  }

  onNext() {
    this.setState({
      termErrMsg5: ("")
      });
  }
  
  

  nextError() {
      this.setState({
        termErrMsg5: (
          <p className="Smaller errorMsg" >
            Gi din vurdering
          </p>
        )
      });
  }

  getCatDescription(currentCat){
    return (
      <div className="corners">
      <div
        style={{
          fontWeight: 300     
        }}
      >
        <p className="Lead" style={{fontSize: '20px'}}>{currentCat ? currentCat.evaluationDescription : ""}</p>
      </div>
    </div>
    );
  }
  getCatDescription1(currentCat){
    return (
        <div style={{display: "flex", justifyContent: "center"}}>
      <div
        style={{
          fontWeight: 300,
          marginBottom: "10px"
        }}
      >
        <p className="Lead" style={{fontSize: '20px'}}>{currentCat ? currentCat.evaluationDescription : ""}</p>
      </div>
    </div>
    );
  }
  /**
   * A fucntion to dispaly the progress bar.
   * There are handlers that will be called on.
   */
  // A function to style and creat the progress bar.
  // Value for logic on this progressbar are temporary and must changes.
  // This function will be called in the component "Analyse.js". 
  // @param {*} currentCat name of the current category
  // @param {*} position Position in the bar
  // @param {} lengthOfBar Length of bar. Its depends on number of categories

  createProgressBar(currentCat, position, lengthOfBar, isMobile){
    let currentCategory = currentCat;
    let positionId = position;
    let l = lengthOfBar;
    let progress = [];
    let w = isMobile;
    progress.push(      
      <div className="progressnav" key={currentCategory} style={{width: w ? "60%" : "50%"}} key={positionId} >
        {createBar(
          l,
          positionId,
          this.currentCategory,
          bouvetdarkblue,
          w
        )}     
      </div>      
    );
    
    return progress;
  }

  createAvbryt() {
    const { t } = this.props;
    let currentCategory = this.state.currentCategory;
    let buttons = [];
    let colx = "background-color:Tomato;";
        
    buttons.push(
      <div className="progressnav" key={currentCategory} style={{ paddingTop: "20px", height: "100%", display: "flex", justifyContent: "center", width: "100%" }} >
        <div>           
        <p style={{color:'#999999'}}>   <a href="https://www.bouvet.no/" style={{color:'#0000ff'}} > Avbryt</a>  modenhetsanalysen og gå tilbake til startsiden</p>
        </div>
        </div>     
        )       
  ;
    return buttons;
   
  }


  render() {
    
    const { t } = this.props;
    const { rating, redirect } = this.state;
    const myWindowWidth = () => {
      var w = window.innerWidth;
      var h = window.innerHeight;
      var wS = window.screen.width;
      var hS = window.screen.height;
      console.log("Height:  ", h, "Width:  ", w, "Screen W:  ", wS , hS);
      if (w < 600) {
        return true;  
      } else { return false;}
    }
    const w = myWindowWidth();

    if (redirect) {
        return <Redirect to="/" />;  
    }
    let currentIdCategory = this.findCurrentId(this.state.indexNow); //index of current category
    let currentCat = this.state.categories.find(x =>(x.idCategory===currentIdCategory && x.isoCode===userlanguage()));
    let currentCatName = currentCat ? currentCat.category : "";
    return (
      <div>
        { w ?
          <div>
        <Title style={{fontWeight: 350, display:"flex", justifyContent: "space-between" }}>
          <H1>{currentCatName}</H1>
          <img src={bouvetLogo} alt="bouvetLogo" style={{ height: "75px", display: "flex", justifySelf: "center" }} /> 
        </Title>
        <Container1 marginleft="0px" style={{ display: "grid",/*  width: "95%" */ height: "50%", gridTemplateColumns: "minmax(100%, 4fr)" , paddingLeft: "0px"}}>   
             { !this.state.rated ? 
            (
              <Container style={{ paddingleft: "5px", marginTop: "10px" }}>
                <div>
                  <Container1 style={{ display: "flex", width: "90%", justifyContent: "center"}}>
                    <div style={{ gridArea: "1 / 1 / 1 / 1" }}>
                      {/* {this.state.showStars ? ( */}
                        <div style={{ paddingTop: 30, fontSize: 30 }}>
                          {this.getCatDescription1(currentCat)} 
                          <p>
                            <b>{t('Gi din egen karakter')}</b>
                          </p>
                          <StarRatingComponent
                            name="rate1"
                            starCount={5}
                            value={rating}
                            starSpacing="70px"
                            editing={this.state.edit}
                            renderStarIcon={(index, value) => (
                              <Dot> {index <= value ? '●' :  '◯' }</Dot>
                          )}
                            // renderStarIcon={() => <span>O◯</span>} this line is a simple example for using cutom symbol
                            onStarClick={this.onStarClick.bind(this)}
                            onStarHover={this.onStarHover.bind(this)}
                            starColor={bouvetorange}
                            emptyStarColor={bouvetdarkblue}
                          />
                        </div>
                      {/* // ) : null} */}
                      <div style={{display: "flex", justifyContent: "center", background: "lightgrey"}}>
                        {this.getCategoryMobile(currentIdCategory)}
                      </div>
                    </div>
                  </Container1>
                  <div style={{display: "flex", justifyContent: "center", margin: "10px"}}>
                  <Navigatebutton
                    primary
                    value={currentIdCategory}
                    onClick={() => this.prev()}
                  >
                    <span style={{ color: bouvetorange, fontFamily: 'timesNewRoman'}}>&#9668;</span>
                  </Navigatebutton>                  
                  {this.Bar(currentCat, this.state.indexNow + 1, this.state.categories.length, w)}
                  { this.state.rating !== 0 ?
                  <Navigatebutton
                    primary
                    disabled={false}
                    value={currentIdCategory}
                    onClick={() => this.next(this.state.rating)}
                    >
                      <span style={{ color: bouvetorange, fontFamily: 'timesNewRoman'}}>&#9658;</span>
                    </Navigatebutton>
                    :
                    (<div><Navigatebutton
                    primary
                    disabled={false}
                    value={currentIdCategory}
                    onClick={() => this.nextError(this.state.rating)}
                    style={{background: bouvetblue}}
                    >
                      <span style={{ color: bouvetgrey70, fontFamily: 'timesNewRoman'}}>&#9658;</span>
                      </Navigatebutton>                     
                      <p  style={{color: bouvetorange}}>{this.state.termErrMsg5}</p></div>)
             }
                    </div>
                </div>
                {/* {this.createProgressBar(currentCat, this.state.indexNow + 1, this.state.categories.length)} */}
              </Container>
            ) : (
              <Container style={{ paddingLeft: "0px" }}>
                  <AnalyseKnapp
                      t={t}
                      nameForPorgress={this.getNameCategory(currentIdCategory)} //pass the category name for to show on progress bar
                      evaluationFromParent={rating} //the rating value                     
                      idOfCategories={this.getIdOfCategories()} //Id of all categories
                      idxForKnapp={this.state.indexNow} //updated index of the current category
                      currentIdForKnapp={currentIdCategory} //category_id of the current category
                      indexHandler={this.indexHandler} //updates the state of indexNow.
                      //to update the rating states
                      userRated={this.ratingHandler}
                      handleRating={this.ratingUpdate}
                      hideStarRating={this.hideStarRating}
                      showStarRating={this.showStarRating}
                      isThisRated={this.isThisRated()}
                      onNext={this.onNext()}
                      rated={this.state.rated}
                      //to update the buttons state
                      nextButtonDisappear={this.nextButtonDisappear}
                      enableOrDisableRating={this.enableOrDisableRating}
                      seeTheNextButton={this.state.wantToShowNext}
                      seeThePrevButtton={this.state.wantToShowPrev}
                      isFinished={this.state.isFinished}
                      allQuestionaires={this.state.allQuestionaires} //pass all questionaires that has been fetched.
                      onPrev={this.onPrev}
                    />
                  </Container>)}
                  <div style={{display: "flex", justifyContent: "center", margin: "10px"}}>
                  <Navigatebutton
                    primary
                    value={currentIdCategory}
                    onClick={() => this.prev()}
                  >
                    <span style={{ color: bouvetorange, fontFamily: 'timesNewRoman'}}>&#9668;</span>
                  </Navigatebutton>                  
                  {this.createProgressBar(currentCat, this.state.indexNow + 1, this.state.categories.length, w)}
                  { this.state.rating !== 0 ?
                  <Navigatebutton
                    primary
                    disabled={false}
                    value={currentIdCategory}
                    onClick={() => this.next(this.state.rating)}
                    >
                      {/* {t("Neste ")} */}
                      <span style={{ color: bouvetorange, fontFamily: 'timesNewRoman'}}>&#9658;</span>
                    </Navigatebutton>
                    :
                    (<div><Navigatebutton
                    primary
                    disabled={false}
                    value={currentIdCategory}
                    onClick={() => this.nextError(this.state.rating)}
                    style={{background: bouvetblue}}
                    >
                      {/* {t("Neste ")} */}
                      <span style={{ color: bouvetgrey70, fontFamily: 'timesNewRoman'}}>&#9658;</span>
                      </Navigatebutton>
                      <p style={{color: bouvetorange}}>{this.state.termErrMsg5}</p></div>)
             }
                    </div>
                  {/* {this.createProgressBar(currentCat, this.state.indexNow + 1, this.state.categories.length, w)} */}
          </Container1> 
        </div>
          :
          <div>
          <Title style={{fontWeight: 350, display:"flex", justifyContent: "space-between" }}>
            <H1>{currentCatName}</H1>
            <img src={bouvetLogo} alt="bouvetLogo" style={{ height: "75px", display: "flex", justifySelf: "center" }} /> 
          </Title>
          <Container1 marginleft="0px" style={{ display: "grid"/* , width: "95%" */, height: "50%", gridTemplateColumns: "minmax(100%, 4fr)" , paddingLeft: "0px"}}>   
            {/* {!(this.state.rated !== true ? null : true && this.state.rated) ? */}
               { !this.state.rated ? 
              (
                <Container style={{ paddingleft: "5px", marginTop: "10px" }}>
                  <div>
                    <Container1 style={{ display: "flex", width: "80%", justifyContent: "center"}}>
                      <div style={{ gridArea: "1 / 1 / 1 / 1" }}>
                      {/* {this.getCatDescription1(currentCat)}  */}
                        {/* {this.state.showStars ? ( */}
                          <div style={{ paddingTop: 5, fontSize: 30 }}>
                            {this.getCatDescription1(currentCat)} 
                            <p style={{marginTop:'20px'}}>
                              <b>{t('Gi din egen karakter')}</b>
                            </p>
                            <StarRatingComponent  
                              name="rate1"
                              starCount={5}
                              value={rating}
                              starSpacing="70px"
                              editing={this.state.edit}

                              renderStarIcon={(index, value) => (
                                  <Dot> {index <= value ? '●' :  '◯' }</Dot>
                              )}
                              // renderStarIcon={() => <Dot>◯</Dot>}
                              // renderStarIcon={() => <span>O◯</span>} this line is a simple example for using cutom symbol
                              onStarClick={this.onStarClick.bind(this)}
                              //onStarHover={this.onStarHover.bind(this)}
                              starColor={bouvetorange}
                              emptyStarColor={bouvetorange}
                            />
                          </div>
                        {/* // ) : null} */}
                        <div style={{display: "flex", justifyContent: "stretch", background: "#efefef", marginTop:'20px'}}>
                          {this.getCategory(currentIdCategory)}
                        </div>
                      </div>
                    </Container1>
                      <div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <div className="buttonContainer" style={{ display: "flex", justifyContent: "space-between", marginTop: "25px", marginBottom: '5px' }}>
                            <Navigatebutton
                              primary
                              value={currentIdCategory}
                              onClick={() => this.prev()}
                            >
                              {t("Forrige")}
                            </Navigatebutton>
                            {this.createProgressBar(currentCat, this.state.indexNow + 1, this.state.categories.length, w)}
                            {this.state.rating !== 0 ?
                              <Navigatebutton
                                primary
                                disabled={false}
                                value={currentIdCategory}
                                onClick={() => this.next(this.state.rating)}
                              >
                                {t("Neste ")}
                              </Navigatebutton>
                              :
                              (<Navigatebutton
                                primary
                                disabled={false}
                                value={currentIdCategory}
                                onClick={() => this.nextError(this.state.rating)}
                                style={{ background: "white", color: bouvetdarkblue }}
                              >
                                {t("Neste ")}
                              </Navigatebutton>
                              )
                            }
                          </div>
                        </div>
                        <div style={{ width: '90%', float: 'left' }}>
                          <p style={{ color: bouvetorange, textAlign: 'right', paddingRight: '10px' }}>{this.state.termErrMsg5}</p>
                        </div>
                      </div>
                    </div>
                  {this.createAvbryt()}
                  {/* {this.createProgressBar(currentCat, this.state.indexNow + 1, this.state.categories.length)} */}
                </Container>
              ) : (
                <Container style={{ paddingLeft: "0px" }}>
                    <AnalyseKnapp
                    t={t}
                    nameForPorgress={this.getNameCategory(currentIdCategory)} //pass the category name for to show on progress bar
                    evaluationFromParent={rating} //the rating value
                    //evaluationFromParent={4}
                    idOfCategories={this.getIdOfCategories()} //Id of all categories
                    idxForKnapp={this.state.indexNow} //updated index of the current category
                    currentIdForKnapp={currentIdCategory} //category_id of the current category
                    indexHandler={this.indexHandler} //updates the state of indexNow.
                    //to update the rating states
                    userRated={this.ratingHandler}
                    handleRating={this.ratingUpdate}
                    hideStarRating={this.hideStarRating}
                    showStarRating={this.showStarRating}
                    isThisRated={this.isThisRated()}
                    onNext={this.onNext()}
                    onPrev={this.onPrev}
                    rated={this.state.rated}
                    //to update the buttons state
                    nextButtonDisappear={this.nextButtonDisappear}
                    enableOrDisableRating={this.enableOrDisableRating}
                    seeTheNextButton={this.state.wantToShowNext}
                    seeThePrevButtton={this.state.wantToShowPrev}
                    isFinished={this.state.isFinished}
                    allQuestionaires={this.state.allQuestionaires} //pass all questionaires that has been fetched.
                  />
                  </Container>)}
            </Container1> 
          </div>
          }
      </div>
    );
  }
}

export default withNamespaces('Analyse')(AnalyseRating);
