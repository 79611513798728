  import { Option } from '../frontEndHelper/FetchHelper';
  import { adalApiFetch } from "../../adalConfig";
  import { serviceUrl } from './ServiceURL';

/**
 * This function will post the answers (selected statments) of the user to the 
 * table named "Answers" in the database.
 * @param {*} idStatement id of statements that is selected by the user. 
 * @param {*} idUser id of current user.
 * @param {*} rank ranking of selected statement. (1 to number of chosen items)
 */
async function postAnswers(idStatement, id, rank) {
    const body = {
      IdStatement: idStatement, 
      IdUser: id,
      StatementRank: rank
    }
     await adalApiFetch(fetch, serviceUrl+"/api/Answers", Option('POST', body))
     .then(resp => resp.json())
     .catch(error => console.error(error));
    }

  export {
      postAnswers
  }
