import { Option } from "./fetchHelpers";
import { serviceUrl } from "./ServiceURL";

/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */

/**
 * This function takes in information on the languages available for translation
 * and reformats that the data to fit the dropdown menu format.
 *
 * @param {object} allLanguages all existing languages
 * @param {object} availableLanguages all languages available for translation
 * @returns {object} menu options that can be placed directly into a dropdown menu
 */
function createLanguageDropdown(allLanguages, availableLanguages) {
  let dropdown = [];
  let languageIdx = [];
  /*
   * First mapping all iso codes with the corresponding name of the language in
   * English.
   */
  for (var i in allLanguages) {
    var language = allLanguages[i];
    languageIdx.push({ iso: language.isoCode, name: language.nameEnglish });
  }
  /*
   * Then applying a filter so that only the languages available for translation
   * is left on the menu.
   */

  availableLanguages.map(function(language) {
    for (var i in languageIdx) {
      if (language.isoCode === languageIdx[i].iso) {
        dropdown.push({ value: language.isoCode, label: languageIdx[i].name });
      }
    }
  });
  return dropdown;
}

/**
 * This function takes the company roles and reformats the data such that it
 * fits the dropdown menu format.
 *
 * @param {object} companyRoles all company roles
 * @returns {object} the menu that can be placed directly into the dropdown
 */
function createRoleDropdown(companyRoles) {
  let dropdown = [{ value: 0, label: "All roles" }];
  companyRoles.map(function(role) {
    // Adds only the norwegian translation of the roles to the dropdown menu
    if (role.isoCode === "nob") {
      dropdown.push({ value: role.idCompanyRole, label: role.companyRole });
    }
  });
  return dropdown;
}

/**
 * This function takes the company roles and reformats the data such that it
 * fits the dropdown menu format.
 *
 * @param {objecct} companyTypes all company types
 * @returns {object} the menu that can be placed directly into the dropdown
 */
function createTypeDropdown(companyTypes) {
  let types = [{ value: 0, label: "All types" }];
  companyTypes.map(function(type) {
    // Adds only the norwegian translation of the types to the dropdown menu
    if (type.isoCode === "nob") {
      types.push({ value: type.idCompanyType, label: type.companyType });
    }
  });
  return types;
}

/**
 * This function takes the company sizes and reformats the data such that it
 * fits the dropdown menu format.
 *
 * @param {object} companySizes all the company sizes
 * @returns {object} the menu that can be placed directly into the dropdown
 */
function createSizeDropdown(companySizes) {
  let sizes = [{ value: 0, label: "All sizes" }];
  companySizes.map(function(size) {
    sizes.push({ value: size.idCompanySize, label: size.companySize1 });
  });
  return sizes;
}

/**
 * This function creates the rows for the evaluation table on the admin evaluation
 * page. It takes in a selected language and returns all categories translated in
 * that language along with the evaluation description.
 *
 * @param {object} language the selected language
 * @param {object} categories all categories with a translation in the selected
 * language
 * @returns {object} the rows to be put into the table
 */
function createStatementTable(
  language,
  category,
  role,
  type,
  size,
  statements,
  statementTranslations,
  statementDescription,
  questionaires
) {
  let catStatements = [];
  let roleVal = role ? role.value : 0;
  let typeVal = type ? type.value : 0;
  let sizeVal = size ? size.value : 0;
  var filteredQuestionaire = questionaires.filter(
    x =>
      (x.idCompanyType === typeVal || typeVal === 0) &&
      (x.idCompanySize === sizeVal || sizeVal === 0) &&
      (x.idCompanyRole === roleVal || roleVal === 0)
  );
  for (var idx in filteredQuestionaire) {
    var statement = statements.find(
      item => item.idStatement == filteredQuestionaire[idx].idStatement
    );
    if (statement && statement.idCategory === category.idCategory) {
      let statementTranslation = statementTranslations.find(
        translation =>
          translation.idStatement === statement.idStatement &&
          translation.isoCode === language.value
      );

      var description = statementDescription.find(
        item =>
          item.idStatement === statement.idStatement &&
          item.isoCode === language.value &&
          item.role === roleVal &&
          item.type === typeVal &&
          item.size === sizeVal
      );
      if (
        !catStatements.find(
          catstatement => catstatement.id === statement.idStatement
        )
      ) {
        catStatements.push({
          id: statement.idStatement,
          statement: statementTranslation
            ? statementTranslation.statementText
            : "",
          description: description ? description.statementDescription : "",
          weight: filteredQuestionaire[idx].statementWeight,
          role: roleVal,
          type: typeVal,
          size: sizeVal
        });
      }
    }
  }
  return catStatements;
}

/**
 * This function takes the entry that was changed in the evaluation table on
 * the admin evaluation page and updates the database with the new evaluation
 * description.
 *
 * @param {object} changed the evaluation description that has been changed
 * @param {object} isoCode the iso code of the currently selected language
 */
async function editDescription(change, idx, role, type, size, isoCode) {
  const request = async () => {
    if (change.description) {
      const body = {
        IdStatement: idx,
        IsoCode: isoCode,
        StatementDescription: change.description,
        Role: role,
        Type: type,
        Size: size
      };
      return fetch(
        serviceUrl +
          "/api/StatementDescription/" +
          idx +
          "/" +
          isoCode +
          "/" +
          role +
          "/" +
          type +
          "/" +
          size,
        Option("PUT", body)
      );
    }
  };
  return await request();
}

async function deleteDescription(idx, role, type, size, isoCode) {
  return fetch(
    serviceUrl +
      "/api/StatementDescription/" +
      idx +
      "/" +
      isoCode +
      "/" +
      role +
      "/" +
      type +
      "/" +
      size,
    Option("DELETE")
  );
}

export {
  createLanguageDropdown,
  createRoleDropdown,
  createTypeDropdown,
  createSizeDropdown,
  createStatementTable,
  editDescription,
  deleteDescription
};
