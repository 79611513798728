/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from 'react-bootstrap/Container';
import Typography from '@material-ui/core/Typography';
import { EditingState } from '@devexpress/dx-react-grid';
import {
  Grid as Gridd,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import { Option, Fetch } from "../helpers/fetchHelpers"
import { 
  addNewCompanyRole,
  editCompanyRole,
  deleteCompanyRole,
  addNewCompanyType,
  editCompanyType,
  deleteCompanyType,
  addNewCompanySize,
  editCompanySize,
  deleteCompanySize,
  createSizesTable,
  createTypesTable,
  createRolesTable
} from '../helpers/companyFunctions';
import { createTranslationColumns } from '../helpers/commonFunctions';
import { navbar } from '../helpers/adminNavbar';
import { TextFix } from '../helpers/styling';
import CreateConfirmationDialog from './AdminDialog'
import { serviceUrl } from '../helpers/ServiceURL';

const getRowId = row => row.id;

class AdminCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRoleDialog: false,
      showTypeDialog: false,
      showSizeDialog: false,
      availableLanguages: [],
      languages: [],
      companyRoles: [],
      companySizes: [],
      companyTypes: [],
      // Static columns for the company sizes table
      columnsSize: [
        { name: 'id', title: 'ID' },
        { name: 'size', title: 'Size' },
      ],
      rowsRole: [],
      rowsType: [],
      rowsSize: [],
      /*
       * Disabling edititng in the columns ID as the ID is dynamically assigned
       * by the database, and after that it is never changed.
       */
      editingStateColumnExtensions: [
        { columnName: 'id', editingEnabled: false },
      ],
    };
    this.commitChangesRole = this.commitChangesRole.bind(this);
    this.commitChangesType = this.commitChangesType.bind(this);
    this.commitChangesSize = this.commitChangesSize.bind(this);
  }

  /**
   * This function makes sure that the functions called within are run when the
   * component was mounted. This is done so that the functions called are ready
   * when the user sees the page.
   */
  componentDidMount() {
    this.fetchCompanyRole();
    this.fetchCompanySizes();
    this.fetchCompanyType();
    this.fetchLanguages();
  }

  /**
   * This fucntion retrives all existing languages and all languages available
   * for translation and stores them in the state for later use.
   */
  fetchLanguages() {
    Fetch(serviceUrl+'/api/AvailableLanguages', Option('GET'))
      .then((availableLanguages) => {
        this.setState({ availableLanguages: availableLanguages });
      })
    Fetch(serviceUrl+'/api/Languages', Option('GET'))
      .then((languages) => {
        this.setState({ languages: languages });
      })
  }

  /**
   * This function retrieves all company roles from the database and stores it
   * in the state. It also calls the function createRolesTable() so that the
   * table is up to date with the latest fetch from the database.
   */
  fetchCompanyRole() {
    Fetch(serviceUrl+'/api/CompanyRoleTranslations', Option('GET'))
      .then((companydata) => {
        this.setState({ companyRoles: companydata });
        this.setState({ rowsRole: createRolesTable(companydata) });
      })
  }

  /**
   * This function retrieves all company types from the database and stores it
   * in the state. It also calls the function createTypesTable() so that the
   * table is up to date with the latest fetch from the database.
   */
  fetchCompanyType() {
    Fetch(serviceUrl+'/api/CompanyTypeTranslations', Option('GET'))
      .then((companydata) => {
        this.setState({ companyTypes: companydata });
        this.setState({ rowsType: createTypesTable(this.state.companyTypes) });
      })
  }

  /**
   * This function retrieves all company sizes from the database and stores it
   * in the state. It also calls the function createSizesTable() so that the
   * table is up to date with the latest fetch from the database.
   */
  fetchCompanySizes() {
    Fetch(serviceUrl+'/api/CompanySizes', Option('GET'))
      .then((companydata) => {
        this.setState({ companySizes: companydata });
        this.setState({ rowsSize: createSizesTable(this.state.companySizes) });
      })
  }

  /**
   * This function commits the changes done in the company role table to the
   * datase. And performs the different task depending on whether it was an
   * added, changed or deleted task called.
   * 
   * @param {object} param0 an object indicating whether the table function new,
   * edit or delete was used
   */
  commitChangesRole({ added, changed, deleted }) {
    let { rowsRole } = this.state;
    /*
     * If a new company role has been added, call the addNewCompanyRole() function
     * to add it to the database.
     */
    if (added) {
      addNewCompanyRole(added);
    }
    /*
     * If a company role has been changed then call the function editCompanyRole()
     * to commit the changes to the database, and then update the table.
     */
    if (changed) {
      editCompanyRole(changed);
      /*
       * Updates the table in place, this part will work even if something went
       * wrong with in the editCompanyRole() function.
       */
      rowsRole = rowsRole.map(row => 
        (changed[row.id] ? { ...row, ...changed[row.id] } : row)
      );
    }
    /*
     * If a companu role has been deleted then call the deleteCompanyRole()
     * function to delete the statement from the database. And then remove the 
     * company role from the table.
     */
    if (deleted) {
      this.setState({
        roleId: deleted[0],
        rolelabel: this.state.rowsRole.find(x => x.id == deleted[0]).nob,
        showRoleDialog: true
      });
    }
    this.setState({ rowsRole });
  }

  /**
   * This function commits the changes done in the company type table to the
   * datase. And performs the different task depending on whether it was an
   * added, changed or deleted task called.
   * 
   * @param {object} param0 an object indicating whether the table function new,
   * edit or delete was used
   */
  commitChangesType({ added, changed, deleted }) {
    let { rowsType } = this.state;
    /*
     * If a new company type has been added, call the addNewCompanyType() function
     * to add it to the database.
     */
    if (added) {
      addNewCompanyType(added);
    }
    /*
     * If a company type has been changed then call the function editCompanyType()
     * to commit the changes to the database, and then update the table.
     */
    if (changed) {
      editCompanyType(changed);
      /*
       * Updates the table in place, this part will work even if something went
       * wrong with in the editCompanyType() function.
       */
      rowsType = rowsType.map(row => 
        (changed[row.id] ? { ...row, ...changed[row.id] } : row)
      );
    }
    /*
     * If a companu type has been deleted then call the deleteCompanyType()
     * function to delete the statement from the database. And then remove the 
     * company role from the table.
     */
    if (deleted) {
      this.setState({
        typeId: deleted[0],
        typelabel: this.state.rowsType.find(x => x.id == deleted[0]).nob,
        showTypeDialog: true
      });
    }
    this.setState({ rowsType });
  }

  /**
   * This function commits the changes done in the company size table to the
   * datase. And performs the different task depending on whether it was an
   * added, changed or deleted task called.
   * 
   * @param {object} param0 an object indicating whether the table function new,
   * edit or delete was used
   */
  commitChangesSize({ added, changed, deleted }) {
    let { rowsSize } = this.state;
    /*
     * If a new company size has been added, call the addNewCompanySize() function
     * to add it to the database.
     */
    if (added) {
      addNewCompanySize(added);
    }
    /*
     * If a company size has been changed then call the function editCompanySize()
     * to commit the changes to the database, and then update the table.
     */
    if (changed) {
      editCompanySize(changed);
      /*
       * Updates the table in place, this part will work even if something went
       * wrong with in the editCompanySize() function.
       */
      rowsSize = rowsSize.map(row => 
        (changed[row.id] ? { ...row, ...changed[row.id] } : row)
      );
    }
    /*
     * If a companu size has been deleted then call the deleteCompanySize()
     * function to delete the statement from the database. And then remove the 
     * company role from the table.
     */
    if (deleted) {
      this.setState({
        sizeId: deleted[0],
        sizelabel: this.state.rowsSize.find(x => x.id == deleted[0]).size,
        showSizeDialog: true
      });
    }
    this.setState({ rowsSize });
  }


  deleteRole = value => {
    let { roleId, rowsRole } = this.state;
    if(value){
      deleteCompanyRole(roleId);
      /*
       * Updates the table in place, this part will work even if something went
       * wrong with in the deleteCompanySize() function.
       */
      const deletedSet = new Set([roleId]);
      rowsRole = rowsRole.filter(row => !deletedSet.has(row.id));
    }
    this.setState({
      showRoleDialog: false
    });
  };

  deleteType = value => {
    let { typeId, rowsType } = this.state;
    if(value){
      deleteCompanyType(typeId);
      /*
       * Updates the table in place, this part will work even if something went
       * wrong with in the deleteCompanySize() function.
       */
      const deletedSet = new Set([typeId]);
      rowsType = rowsType.filter(row => !deletedSet.has(row.id));
    }
    this.setState({
      showTypeDialog: false
    });
  };

  deleteSize = value => {
    let { sizeId, rowsSize } = this.state;
    if(value){
      deleteCompanySize(sizeId);
      /*
       * Updates the table in place, this part will work even if something went
       * wrong with in the deleteCompanySize() function.
       */
      const deletedSet = new Set([sizeId]);
      rowsSize = rowsSize.filter(row => !deletedSet.has(row.id));
    }
    this.setState({
      showSizeDialog: false
    });
  };

  delete = value => {
    const {
      showRoleDialog,
      showTypeDialog,
      showSizeDialog
    } = this.state;
    if(showRoleDialog){
      this.deleteRole(value);
    }else if(showTypeDialog){
      this.deleteType(value);
    }else if(showSizeDialog){
      this.deleteSize(value);
    }
  }

  render() {
    const {
      rowsRole,
      rowsType,
      rowsSize,
      columnsSize,
      editingStateColumnExtensions,
      showRoleDialog,
      showTypeDialog,
      showSizeDialog,
      roleId,
      rolelabel,
      typeId,
      typelabel,
      sizeId,
      sizelabel
    } = this.state;
    let dialogName = showRoleDialog?"role ":showTypeDialog?"type ":"size ";
    let deleteId = showRoleDialog?roleId:showTypeDialog?typeId:sizeId
    let deleteLabel = showRoleDialog?rolelabel:showTypeDialog?typelabel:sizelabel;
    return (
      <Container style={{ width: '80%' }}>
        <CreateConfirmationDialog 
          title={"Delete " + dialogName + deleteLabel + "(" + deleteId + ")?"}
          contentText={"All setup that has been done for this " + dialogName + "will be lost!"}
          warningText={"This action cannot be undone!"}
          showDialog={showRoleDialog||showTypeDialog||showSizeDialog}
          onClick={(event) => this.delete(event)}
        />
        {/* Navigation bar for the admin pages */}
        {navbar}
        <Grid item xs={8}>
          <TextFix>
            Use the "NEW"/"EDIT" and "DELETE" buttons on the tables to add, edit and delete company details.
          </TextFix>
          <TextFix>
            When adding a new company role or a company type, please be sure to have added a "Norwegian Bokmål" translation
            as this is the default language.
            After adding a new company role, company type or company size the page needs to be reloaded before it can be edited or deleted.
          </TextFix>
        </Grid>
        <Typography variant="h6">
          Company Roles
        </Typography>
        <Gridd
          rows={rowsRole}
          columns={createTranslationColumns(this.state.languages, this.state.availableLanguages)}
          getRowId={getRowId}
        >
          <EditingState
            onCommitChanges={this.commitChangesRole}
            defaultEditingRowIds={[0]}
            columnExtensions={editingStateColumnExtensions}
          />
          <Table />
          <TableHeaderRow />
          <TableEditRow />
          <TableEditColumn
            showAddCommand
            showEditCommand
            showDeleteCommand
          />
        </Gridd>
        <Typography variant="h6">
          Company Types
        </Typography>
        <Gridd
          rows={rowsType}
          columns={createTranslationColumns(this.state.languages, this.state.availableLanguages)}
          getRowId={getRowId}
        >
          <EditingState
            onCommitChanges={this.commitChangesType}
            defaultEditingRowIds={[0]}
            columnExtensions={editingStateColumnExtensions}
          />
          <Table />
          <TableHeaderRow />
          <TableEditRow />
          <TableEditColumn
            showAddCommand
            showEditCommand
            showDeleteCommand
          />
        </Gridd>
        <Typography variant="h6">
          Company Sizes
        </Typography>
        <Grid item xs={6}>
          <Gridd
            rows={rowsSize}
            columns={columnsSize}
            getRowId={getRowId}
          >
            <EditingState
              onCommitChanges={this.commitChangesSize}
              defaultEditingRowIds={[0]}
              columnExtensions={editingStateColumnExtensions}
            />
            <Table />
            <TableHeaderRow />
            <TableEditRow />
            <TableEditColumn
              showAddCommand
              showEditCommand
              showDeleteCommand
            />
          </Gridd>
        </Grid>
      </Container>
    );
  };

};

export default AdminCompany;