import React from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import ikon_pc from "../images/ikon_pc.png";
import personas from "../images/personas.jpg";
import { Component } from "react";
import { Image } from "semantic-ui-react";
import { addUser } from "../components/frontEndHelper/storeUser";
import { userId } from "../components/frontEndHelper/SessionHelper";
import { Fetch, Option } from "../components/frontEndHelper/FetchHelper";
import {
  bouvetgrey70,
  bouvetblue,
  bouvetdarkblue,
  bouvetorange,
  bouvetbackgroundgrey,
  bouvetlightblue
} from "./../styles/colors";
import { postAnswers } from "../components/frontEndHelper/RequestHandler";
import { withNamespaces } from "react-i18next";
import { serviceUrl } from "../components/frontEndHelper/ServiceURL";
import { contacts } from "../components/frontEndHelper/Contacts";
import { TextFix } from "./frontEndHelper/styling";

const Title = styled.div`
  font-family: sanchez;
  text-align: center;
  margin-top: 40px;
  font-size: 48px;
  font-weight: 400;
`;
const H1 = styled.h1`
  font-family: sanchez;
  font-size: 40px;
  ::before {width: 47%;left:26.5% }
`;
const H2 = styled.h2`
  font-family: sanchez;
`;

const Button = styled.button`
  background: ${props => (props.primary ? bouvetblue : "white")};
  color: ${props => (props.primary ? "white" : bouvetblue)};

  font-size: 1em;
  padding: 0.25em 1em;
  border: 2px solid ${bouvetblue};
  border-radius: 3px;
  float: left;
  margin-top: 10px;

  &:hover {
    background: ${bouvetdarkblue};
    border: 2px solid ${bouvetdarkblue};
  }

  &:disabled {
    background: ${bouvetgrey70};
    border: 2px solid ${bouvetgrey70};
  }
`;

class Registration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      acceptTerms: false,
      termErrMsg: "",
      termErrMsg1: "",
      termErrMsg2: "",
      termErrMsg3: "",
      termErrMsg5: "",
      languageUser: "nob", //State of language
      companyname: "", //Name of company
      roleInCompany: "0", //Users role in company
      nrOfEmployees: "0", //Users companySize
      typeOfCompany: "0", //Users companytype
      rolesInCompany: [], //roles in company
      nrsOfEmployes: [], //Number of employees in the company
      typesOfCompany: [], //Type of company
      allcategories: [], //All categories will be fetched and assigned to this
      redirect: false, //False if every dropdown lists are not selected.
      questionaires: []
      /*All questionaires will be fetched and assigned to "questionaires". Then it will be rendered as a props i "AnalyseKnap.js".
      Which is the child component.
      This is for better perfomance. There will be stored more inputs in the "Questionaires" table in the database. 
      */
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * This Function fetchs all categories that are stored in database.
   * Every category contains :
   * id of catgeory;
   * iso_code for the language;
   * category, which is the title of category;
   * an description for the evaluation part.
   */
  fetchCategories = async () => {
    await Fetch(serviceUrl + "/api/CategoryTranslations", Option("GET"))
      .then(res => {
        this.setState({
          allcategories: res
        });
      })
      .catch(error => console.error(error));
    //Run this function to store answers and clean the session storage if user cliced back any time.
    await this.onBackClick();
  };

  /**
   * GET the id of all categories that exist in the database and
   * fetched by fetchCategories().
   * These ids will be compared with the statements id.
   */
  getIdCategories() {
    let languageNow = this.state.languageUser;
    let idOfCategories = []; //list of category ids
    this.state.allcategories.map(function(category, idx) {
      if (category.isoCode === languageNow) {
        idOfCategories.push(category.idCategory);
      }
    });
    return idOfCategories;
  }

  /**
   * When user clicks on back from ../analyse or ../report, if answers are not stored,
   * thats when "categoriesCompleted" is null, then answers will be stored and cleared the sessionStorage.
   * Otherwise clean the sessionStorage to let the user to start again.
   */
  onBackClick = async () => {
    let listOfcategoriIds = this.getIdCategories();
    let answers = [];
    // let idU = userId();
    /*when some key "categoriesCompleted" values are entered,
     the answers are already stored in database.*/
    if (JSON.parse(sessionStorage.getItem("categoriesCompleted")) !== null) {
      sessionStorage.clear();
    } else {
      for (var i = 0; i < listOfcategoriIds.length; i++) {
        if (JSON.parse(sessionStorage.getItem(listOfcategoriIds[i])) !== null) {
          answers = JSON.parse(sessionStorage.getItem(listOfcategoriIds[i]));
          if (answers !== null) {
            await answers.map(answer => {
              postAnswers(answer.id, userId(), answer.rank + 1);
            });
          }
        }
      }
      //add the users information, answers etc. in the cosmos db aswell as sql db.
      if (userId() !== undefined) {
        await addUser(userId());
      }
      sessionStorage.clear();
    }
  };

  /**
   * Handle chenges from dropdowmn menus, and set the state to the
   * current selected option.
   * @param {event} event
   */
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  /**
   * This function posts the selected items from dropdown menus.
   * Then user will be routed forward to the path "./analyse".
   * @param {*} event
   */

  handleSubmit(event) {
    event.preventDefault();
    if (this.formHasErrors()) {
      return;
    }
    const { roleInCompany, nrOfEmployees, typeOfCompany } = this.state;
    let languageNow = this.state.languageUser;

    const body = {
      IdCompanyType: parseInt(typeOfCompany),
      IdCompanySize: parseInt(nrOfEmployees),
      IdCompanyRole: parseInt(roleInCompany),
      IsoCode: languageNow //this.state.isoCode
    };
    Fetch(serviceUrl + "/api/Users", Option("POST", body))
      .then(data => {
        this.setState({
          redirect: true
        });
        sessionStorage.setItem("registredInfo", JSON.stringify(data));
      })
      .catch(error => {
        console.error(error);
      });
    event.preventDefault();
  }

  componentWillMount() {
    this.fetchCategories();
    this.fetch_role();
    this.fetch_nrof_employes();
    this.fetch_CompanyType();
  }

  /**
   * Fetches all " Company roles" from the "CompanyRoleTranslations" table in database.
   * Every roles contains:
   * An id, the iso_code, and text thats describes the role.
   */
  fetch_role() {
    Fetch(serviceUrl + "/api/CompanyRoleTranslations", Option("GET"))
      .then(res => {
        this.setState({ rolesInCompany: res });
      })
      .catch(error => console.error(error));
  }

  /**
   * Fetches all company size from the "CompanySizes" table in database.
   * Every size item contains:
   * an id, and the size.
   */
  fetch_nrof_employes() {
    Fetch(serviceUrl + "/api/CompanySizes", Option("GET"))
      .then(companydata => {
        this.setState({ nrsOfEmployes: companydata });
      })
      .catch(error => console.error(error));
  }

  /**
   *  Fetches all company types from the "CompanyTypeTranslations" table in database.
   * Every type item contains:
   * an id, the iso_code and the a text that describes the type.
   */
  fetch_CompanyType() {
    Fetch(serviceUrl + "/api/CompanyTypeTranslations", Option("GET"))
      .then(responseJs => {
        this.setState({ typesOfCompany: responseJs });
      })
      .catch(error => console.error(error));
  }

  /**
   * This function assigns every rlle item to an option, which will be added to a dropdown menu
   * with the name: "roleInCom".
   */
  createSelectedRole() {
    let myRoles = [];
    let languageNow = this.state.languageUser;
    this.state.rolesInCompany.map(function(role, idx) {
      if (role.isoCode === languageNow) {
        myRoles.push(
          <option value={role.idCompanyRole} key={idx}>
            {" "}
            {role.companyRole}{" "}
          </option>
        );
      }
    });
    return myRoles;
  }

  /**
   * This function assigns every "size" item to an option, which will be added to a dropdown menu
   * with the name: "nrOfEmployees".
   */
  createSelectedNumberOfEmployees() {
    let myEmployees = [];
    this.state.nrsOfEmployes.map(function(employee, idx) {
      myEmployees.push(
        <option value={employee.idCompanySize} key={idx}>
          {" "}
          {employee.companySize1}{" "}
        </option>
      );
    });
    return myEmployees;
  }

  /**
   * This function assigns every "type" item to an option, which will be added to the dropdown menu
   * with the name: "typeOfCompany".
   */
  createSelectedTypeCompany() {
    let typesOfCompany = [];
    let languageNow = this.state.languageUser;
    this.state.typesOfCompany.map(function(type, idx) {
      if (type.isoCode === languageNow) {
        typesOfCompany.push(
          <option value={type.idCompanyType} key={idx}>
            {" "}
            {type.companyType}{" "}
          </option>
        );
      }
    });
    return typesOfCompany;
  }

  /**
   * The selected language that have been updated in "Navgiation.js" will
   * be updated in this component as well.
   */
  UNSAFE_componentWillReceiveProps = async nextProps => {
    if (
      nextProps.userLanguage !== undefined &&
      this.state.languageUser !== nextProps.userLanguage
    ) {
      await this.setState({
        languageUser: nextProps.userLanguage
      });
    }
  };

  formErrors = () => {
    this.formHasErrors();
    this.formHasErrors1();
    this.formHasErrors2();
    this.formHasErrors3();
  }
  formHasErrors = () => {
    const { t } = this.props;
    const { acceptTerms, roleInCompany, nrOfEmployees, typeOfCompany } = this.state;
    if (!acceptTerms || (roleInCompany === "0") || (nrOfEmployees === "0") || (typeOfCompany === "0")) {
      if (!acceptTerms) {
        this.setState({
          termErrMsg: (
            <p className="Smaller errorMsg">
              {t("Vilkårene må godkjennes før du kan starte analysen")}
            </p>
          )
        });
      }
      if (roleInCompany === "0") {
        this.setState({
          termErrMsg2: (
            <p className="Smaller errorMsg">
              {t("Velg din rolle i bedriften før du kan starte analysen")}
            </p>
          )
        });
      }
      if (nrOfEmployees === "0") {
        this.setState({
          termErrMsg3: (
            <p className="Smaller errorMsg">
              {t("Velg hvor mange ansatte bedriften har før du kan starte analysen")}
            </p>
          )
        });
      }
      if (typeOfCompany === "0") {
        this.setState({
          termErrMsg1: (
            <p className="Smaller errorMsg">
              {t("Velg type virksomhet før du kan starte analysen")}
            </p>
          )
        });
      }
      return true;
    }
  };

  

  getIntroText() {
    const { t } = this.props;
    return (
      <Container className="textContainer introText" style={{ marginTop: '40px' }}>
        <TextFix>
          <p>{t("Intro1")}</p>
          <br/>
          <p>
            {t("Intro2Goal1")} <br />
            {t("Intro2Goal2")} <br />
            {t("Intro2Goal3")}
          </p><br/>
          <p>{t("Intro3")}{t("Intro4")}</p>
          {/* <p>{t("Intro5")}</p> */}
        </TextFix>
      </Container>
    );
  }

  getRegistrationBox(isValidForm) {
    const { t } = this.props;
    const isValidFormBool = (this.state.roleInCompany === "0") || (this.state.nrOfEmployees === "0") || (this.state.typeOfCompany === "0") || !this.state.acceptTerms;
    return (
      <div
        className="userInputForm"
        style={{ backgroundColor: "white", width: "100%" }}
      >
        <Container className="formContainer">
          <h3 className="textCenter" style={{fontSize:'20px', marginTop:'40px'}}>{t("Opplysninger om bedriften")}</h3>
          <Form.Group controlId="exampleForm.ControlSelect1" style={{ marginTop: '1.5rem' }}>
            {/* <Form.Label>
              <p className="Small">{t("Type bedrift")}: </p>
            </Form.Label> */}
            <Form.Control
              as="select"
              name="typeOfCompany"
              value={this.state.typeOfCompany}
              onChange={event=> {
                this.handleChange(event);
                this.setState({ termErrMsg1: "" });
              }}
              className="customSelect"
            >
              <option defaultValue value="0" style={{ color: '#000000' }}>
                {t("Type bedrift")}...
              </option>
              {this.createSelectedTypeCompany()}
            </Form.Control>
            <span style={{ color: bouvetorange, paddingTop: "1px"  }}>{this.state.termErrMsg1}</span>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1" style={{  marginBottom: '1.5rem', marginTop: '1.5rem' }}>
            {/* <Form.Label>
              <p className="Small">{t("Din rolle i bedriften")}:</p>{" "}
            </Form.Label> */}

            <Form.Control
              as="select"
              name="roleInCompany"
              value={this.state.roleInCompany}
              onChange={event=> {
                this.handleChange(event);
                this.setState({ termErrMsg2: "" });
              }}
              className="customSelect"
            >
              <option defaultValue value="0">
                {t("Din rolle i bedriften")}...
              </option>
              {this.createSelectedRole()}
            </Form.Control>
            <span style={{ color: bouvetorange, paddingTop: "3px"  }}>{this.state.termErrMsg2}</span>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1" style={{  marginBottom: '2rem', marginTop: '1rem' }}>
            {/* <Form.Label>
              <p className="Small">{t("Antall ansatte")}:</p>
            </Form.Label> */}
            <Form.Control
              as="select"
              name="nrOfEmployees"
              value={this.state.nrOfEmployees}
              onChange={event=> {
                this.handleChange(event);
                this.setState({ termErrMsg3: "" });
              }}
              className="customSelect"
            >
              <option defaultValue value="0">
                {t("Antall ansatte")}...
              </option>
              {this.createSelectedNumberOfEmployees()}
            </Form.Control>
            <span style={{ color: bouvetorange, paddingTop: "1px"}}>{this.state.termErrMsg3}</span>
          </Form.Group>

          <Form.Group controlId="formBasicChecbox" style={{ margin: 15 }}>
            <div style={{ display: "inline-block" }}>
              <Form.Check
                type="checkbox"
                value={this.state.acceptTerms}
                name="acceptTerms"
                onChange={event => {
                  this.handleChange(event);
                  this.setState({ termErrMsg: "" });
                }}
                style={{display: 'flex', marginTop: '10px' }}
                label={
                  <p className="Small" style={{ color: '#999999' }}>
                    {t("Jeg har lest og aksepterer vilkårene")}
                  </p>
                }
              />
            </div>
            <span style={{ color: bouvetorange }}>
              <a
                href="https://www.bouvet.no/om-bouvet/informasjonskapsler-og-personvern" 
                target="_blank" 
                className="label"
                style={{ fontSize: "15px", color: '#0000ff' }}
              >
                {t("Vilkår")}
              </a>
              {this.state.termErrMsg}
            </span>
          </Form.Group>
          {(isValidFormBool ?
          <Button primary style={{ marginBottom: 30, backgroundColor: "white", color: bouvetdarkblue  }} disabled={!isValidFormBool}>
            <p>{t("      Start")}<span style={{color:  "white"}}></span></p> 
          </Button>
:
          <Button primary style={{ marginBottom: 30, backgroundColor: bouvetdarkblue }} disabled={isValidFormBool}>
            <p>{t("      Start")}<span style={{color:  bouvetdarkblue}}></span></p> 
          </Button>
          )}   
        </Container>
      </div>
    );
  }

  getContactUs() {
    const { t } = this.props;
    var renderContacts = [];
    contacts.map(function(contact, idx) {
      renderContacts.push(
        <div className="contact" key={contact.Fullname + contact.idx}>
          <div>
            <Image
              src={contact.Image}
              size="small"
              style={{ objectFit: "scale-down" }}
              circular
            />
          </div>
          <div style={{ display: "inline-block", paddingLeft: 20 }}>
            <p>
              <strong>{contact.Fullname}</strong>
            </p>
            <p>
              <a href={"mailto:" + contact.Email} className="emailLink">
                {contact.Email}
              </a>
            </p>
            <p>{contact.Phone}</p>
          </div>
        </div>
      );
    });
    return (
      <div>
        <Container className="textContainer" style={{ paddingBottom: 80 }}>
          <h2 className="sectionHeader">{t("Kontakt oss")}</h2>
          {renderContacts}
        </Container>
      </div>
    );
  }

  render() {
    const {
      redirect,
      roleInCompany,
      nrOfEmployees,
      typeOfCompany
    } = this.state;
    const { t } = this.props;
    //When all 3 dropdown menu are selected and checkbox is checked then redirect to "./analyse".
    if (redirect) {
      return <Redirect to="/analyse" />;
    }

    const isValidForm =
      parseInt(roleInCompany) &&
      parseInt(nrOfEmployees) &&
      parseInt(typeOfCompany);
    
  
    return (
      <Form onSubmit={this.handleSubmit} noValidate>
        <img src={personas} alt="personas" style={{ width: "100%", height: "200px", objectFit: "cover"  }} />
        <Title>
          {/* <div>
            <img src={ikon_pc} alt="ikon" className="titleIcon" />
          </div> */}
          <H1>{t("Digital Modenhetsanalyse")}</H1>
        </Title>
        {this.getIntroText()}
        {this.getRegistrationBox(isValidForm)}
        {/* {this.getContactUs()} */}
      </Form>
    );
  }
}

export default withNamespaces("Registration")(Registration);
