/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-loop-func */
import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "react-bootstrap/Container";
import { EditingState } from "@devexpress/dx-react-grid";
import {
  Grid as Gridd,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  TableColumnResizing
} from "@devexpress/dx-react-grid-material-ui";
import Select from "react-select";
import Button from "@atlaskit/button";
import { Option, Fetch } from "../helpers/fetchHelpers";
import {
  editStatement,
  deleteStatement,
  addNewStatement,
  createRoleDropdown,
  createTypeDropdown,
  createSizeDropdown,
  createStatementDropdown,
  createQuestionaireTable,
  removeQuestionaireEntriesbyId,
  createQuestionaireEntries
} from "../helpers/questionaireFunctions";
import { createCategoryDropdown } from "../helpers/commonFunctions";
import {
  Dropdown,
  DropdownIndicator,
  ChevronDown,
  selectStyles,
  TextFix
} from "../helpers/styling";
import { navbar } from "../helpers/adminNavbar";
import { serviceUrl } from "../helpers/ServiceURL";

const getRowId = row => row.id;

class AdminQuestionaires extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      statements: [],
      statementsTranslation: [],
      languages: [],
      availableLanguages: [],
      columns: [],
      rows: [],
      editingStateColumnExtensions: [
        { columnName: "weight", editingEnabled: true }
      ],
      tableColumnExtensions: [
        { columnName: "weight", wordWrapEnabled: true },
        { columnName: "nob", wordWrapEnabled: true }
      ],
      defaultColumnWidths: [
        { columnName: 'id', width: 40 },
        { columnName: 'eng', width: 60 },
        { columnName: 'weight', width: 100 },
        { columnName: 'nob', width: 400 },
      ],
      dropdownCategories: [],
      dropdownStatements: [],
      dropdownRoles: [],
      dropdownTypes: [],
      dropdownSizes: [],
      isOpenCategory: false,
      isOpenStatement: false,
      isOpenRole: false,
      isOpenType: false,
      isOpenSize: false,
      selCategory: undefined,
      selRole: undefined,
      selType: undefined,
      selSize: undefined
    };

    this.commitChanges = this.commitChanges.bind(this);
  }

  /**
   * This function makes sure that the functions called within are run when the
   * component was mounted. This is done so that the functions called are ready
   * when the user sees the page.
   */
  componentDidMount() {
    this.fetchQuestionaires();
    this.fetchCategories();
    this.fetchLanguages();
    this.fetchStatements();
    this.fetchCompanyRole();
    this.fetchCompanyType();
    this.fetchCompanySizes();
  }

  /**
   * This function retrieves all questionnairies from the database and stores
   * it in the state.
   */
  fetchQuestionaires() {
    Fetch(serviceUrl + "/api/Questionaires", Option("GET")).then(
      questionaires => {
        this.setState({ questionaires: questionaires });
      }
    );
  }

  /**
   * This function retrieves all categories from the database and stores it in
   * the state. It also calls the function createCategoryDropdown() so that the
   * dropdown is up to date with the latest fetch from the database.
   */
  fetchCategories() {
    Fetch(serviceUrl + "/api/CategoryTranslations", Option("GET")).then(
      categories => {
        this.setState({ categories: categories });
        this.setState({
          dropdownCategories: createCategoryDropdown(categories)
        });
      }
    );
  }

  /**
   * This function retrieves all statements and statements translations from the
   * database and stores it in the state.
   *
   * The reason both are needed is that the reference to the parent category is
   * stored in the database table Statements while the translations of the
   * statement text is stored in the table StatementsTranslations.
   */
  fetchStatements() {
    Fetch(serviceUrl + "/api/Statements", Option("GET")).then(statements => {
      this.setState({ statements: statements });
    });
    Fetch(serviceUrl + "/api/StatementsTranslations", Option("GET")).then(
      statementsTranslation => {
        this.setState({ statementsTranslation: statementsTranslation });
      }
    );
  }

  /**
   * This fucntion retrives all existing languages and all languages available
   * for translation and stores them in the state for later use.
   */
  fetchLanguages() {
    Fetch(serviceUrl + "/api/AvailableLanguages", Option("GET")).then(
      availableLanguages => {
        this.setState({ availableLanguages: availableLanguages });
      }
    );
    Fetch(serviceUrl + "/api/Languages", Option("GET")).then(languages => {
      this.setState({ languages: languages });
    });
  }

  /**
   * This function retrieves all company roles from the database and stores it
   * in the state. It also calls the function createRoleDropdown() so that the
   * dropdown is up to date with the latest fetch from the database.
   */
  fetchCompanyRole() {
    Fetch(serviceUrl + "/api/CompanyRoleTranslations", Option("GET")).then(
      companyRoles => {
        this.setState({ companyRoles: companyRoles });
        this.setState({ dropdownRoles: createRoleDropdown(companyRoles) });
      }
    );
  }

  /**
   * This function retrieves all company types from the database and stores it
   * in the state. It also calls the function createTypeDropdown() so that the
   * dropdown is up to date with the latest fetch from the database.
   */
  fetchCompanyType() {
    Fetch(serviceUrl + "/api/CompanyTypeTranslations", Option("GET")).then(
      companyTypes => {
        this.setState({ companyTypes: companyTypes });
        this.setState({ dropdownTypes: createTypeDropdown(companyTypes) });
      }
    );
  }

  /**
   * This function retrieves all company types from the database and stores it
   * in the state. It also calls the function createTypeDropdown() so that the
   * dropdown is up to date with the latest fetch from the database.
   *
   * NOTE:
   * The 'company sizes' dropdown has not yet been fully implemented.
   */
  fetchCompanySizes() {
    Fetch(serviceUrl + "/api/CompanySizes", Option("GET")).then(
      companySizes => {
        this.setState({ companySizes: companySizes });
        this.setState({ dropdownSizes: createSizeDropdown(companySizes) });
      }
    );
  }

  /**
   * This function creates the columns used for the questionnaire table on the
   * admin statements page. The first column is manually set to be the ID of the
   * statement, the second is manually set to 'Statement Weight', the third
   * column is manually set to 'Norwegian Bokmål' as this is the default language.
   * The remaining columns are the other available languages sorted alphabetically.
   *
   * This function also ensures that all columns containing statements have
   * wordwrap enabled.
   *
   * @returns {object} the columns to be used for the statements table
   */
  createStatementColumns() {
    let languages = [];
    /*
     * Norwegian Bokmål is manually defined as it is the default language and
     * should therefore be the first column among the translations.
     */
    let columns = [
      { name: "id", title: "ID" },
      { name: "weight", title: "Statement weight" },
      { name: "nob", title: "Norwegian Bokmål" }
    ];

    this.state.availableLanguages.map(function(language) {
      languages.push(language.isoCode);
    });

    /*
     * This next section of the function is the part that ensures that all
     * statement columns have wordwrap enabled.
     */
    let columnExtensions = [];
    let extensions = this.state.editingStateColumnExtensions;
    this.state.languages.map(function(language) {
      if (languages.includes(language.isoCode) && language.isoCode !== "nob") {
        columns.push({ name: language.isoCode, title: language.nameEnglish });
        var found = false;
        for (var i in extensions) {
          var extension = extensions[i];
          if (
            extension ===
            {
              columnName: language.isoCode,
              wordWrapEnabled: true
            }
          ) {
            found = true;
            break;
          }
        }
        if (!found) {
          columnExtensions.push({
            columnName: language.isoCode,
            wordWrapEnabled: true
          });
        }
      }
    });
    for (var i in columnExtensions) {
      var found = false;
      var newExtension = columnExtensions[i];
      for (var j in this.state.tableColumnExtensions) {
        var extension = this.state.tableColumnExtensions[j];
        if (extension === newExtension) {
          found = true;
          break;
        }
      }
      if (!found) {
        this.state.tableColumnExtensions.push(newExtension);
      }
    }
    return columns;
  }

  /**
   * This function toggles open the category dropdown menu. Meaning if it's open
   * it closes and if it's closed it opens.
   *
   * It also closes all other dropdowns so that only one Dropdown can be open at
   * a time.
   */
  toggleOpenCat = () => {
    this.setState({ isOpenCategory: !this.state.isOpenCategory });
    this.setState({ isOpenRole: false });
    this.setState({ isOpenType: false });
    this.setState({ isOpenSize: false });
    this.setState({ isOpenStatement: false });
  };

  /**
   * This function toggles open the company role dropdown menu. Meaning if it's
   * open it closes and if it's closed it opens.
   *
   * It also closes all other dropdowns so that only one Dropdown can be open at
   * a time.
   */
  toggleOpenRole = () => {
    this.setState({ isOpenCategory: false });
    this.setState({ isOpenRole: !this.state.isOpenRole });
    this.setState({ isOpenType: false });
    this.setState({ isOpenSize: false });
    this.setState({ isOpenStatement: false });
  };

  /**
   * This function toggles open the company type dropdown menu. Meaning if it's
   * open it closes and if it's closed it opens.
   *
   * It also closes all other dropdowns so that only one Dropdown can be open at
   * a time.
   */
  toggleOpenType = () => {
    this.setState({ isOpenCategory: false });
    this.setState({ isOpenRole: false });
    this.setState({ isOpenType: !this.state.isOpenType });
    this.setState({ isOpenSize: false });
    this.setState({ isOpenStatement: false });
  };

  /**
   * This function toggles open the company size dropdown menu. Meaning if it's
   * open it closes and if it's closed it opens.
   *
   * It also closes all other dropdowns so that only one Dropdown can be open at
   * a time.
   *
   * NOTE:
   * This dropdown is not yet shown for the end user as the logic behind how it
   * should work has yet to be decided.
   */
  toggleOpenSize = () => {
    this.setState({ isOpenCategory: false });
    this.setState({ isOpenRole: false });
    this.setState({ isOpenType: false });
    this.setState({ isOpenSize: !this.state.isOpenSize });
    this.setState({ isOpenStatement: false });
  };

  /**
   * This function toggles open the statements dropdown menu. Meaning if it's open
   * it closes and if it's closed it opens.
   *
   * It also closes all other dropdowns so that only one Dropdown can be open at
   * a time.
   */
  toggleOpenStat = () => {
    this.setState({ isOpenCategory: false });
    this.setState({ isOpenRole: false });
    this.setState({ isOpenType: false });
    this.setState({ isOpenSize: false });
    this.setState({ isOpenStatement: !this.state.isOpenStatement });
  };

  /**
   * This function deccides what happens when something has been selected from
   * the category dropdown menu.
   *
   * It toggles the dropdown, updates the state with the selected value and
   * creates the questionnaire table and the statement dropdown by calling
   * createCategoryTable() and createStatementDropdown() respectively, as long
   * as a company role has been selected.
   */
  onSelectChangeCat = value => {
    this.toggleOpenCat();
    this.setState({ selCategory: value });
    this.setState({
      dropdownStatements: createStatementDropdown(
        value,
        this.state.statements,
        this.state.statementsTranslation,
        this.state.questionaires,
        this.state.selType,
        this.state.selRole,
        this.state.selSize
      )
    });
    this.setState({
      rows: createQuestionaireTable(
        value,
        this.state.selRole,
        this.state.selType,
        this.state.selSize,
        this.state.companyTypes,
        this.state.companyRoles,
        this.state.companySizes,
        this.state.questionaires,
        this.state.statements,
        this.state.statementsTranslation
      )
    });
  };

  /**
   * This function deccides what happens when something has been selected from
   * the company role dropdown menu.
   *
   * It toggles the dropdown, updates the state with the selected value and
   * creates the questionnaire table and the statement dropdown by calling
   * createCategoryTable() and createStatementDropdown() respectively, as long
   * as a category has been selected.
   */
  onSelectChangeRole = value => {
    this.toggleOpenRole();
    this.setState({ selRole: value });
    if (this.state.selCategory !== undefined) {
      this.setState({
        rows: createQuestionaireTable(
          this.state.selCategory,
          value,
          this.state.selType,
          this.state.selSize,
          this.state.companyTypes,
          this.state.companyRoles,
          this.state.companySizes,
          this.state.questionaires,
          this.state.statements,
          this.state.statementsTranslation
        )
      });
    }
    if (this.state.selCategory) {
      this.setState({
        dropdownStatements: createStatementDropdown(
          this.state.selCategory,
          this.state.statements,
          this.state.statementsTranslation,
          this.state.questionaires,
          this.state.selType,
          value,
          this.state.selSize
        )
      });
    }
  };

  /**
   * This function deccides what happens when something has been selected from
   * the company type dropdown menu.
   *
   * It toggles the dropdown, updates the state with the selected value and
   * creates the questionnaire table and the statement dropdown by calling
   * createCategoryTable() and createStatementDropdown() respectively, as long
   * as both category and a company role has been selected.
   */
  onSelectChangeType = value => {
    this.toggleOpenType();
    this.setState({ selType: value });
    if (
      this.state.selCategory !== undefined &&
      this.state.selRole !== undefined
    ) {
      this.setState({
        rows: createQuestionaireTable(
          this.state.selCategory,
          this.state.selRole,
          value,
          this.state.selSize,
          this.state.companyTypes,
          this.state.companyRoles,
          this.state.companySizes,
          this.state.questionaires,
          this.state.statements,
          this.state.statementsTranslation
        )
      });
    }
    if (this.state.selCategory) {
      this.setState({
        dropdownStatements: createStatementDropdown(
          this.state.selCategory,
          this.state.statements,
          this.state.statementsTranslation,
          this.state.questionaires,
          value,
          this.state.selRole,
          this.state.selSize
        )
      });
    }
  };

  /**
   * This function deccides what happens when something has been selected from
   * the statement dropdown menu.
   *
   * It toggles the dropdown, updates the state with the selected value and
   * add the statement to the questionnaire. If a statement that already exist
   * in the questionnaire gets added again then the selection will be ignored.
   */
  onSelectChangeStat = value => {
    this.toggleOpenStat();
    this.setState({ selStatement: value });
    addNewStatement(
      value,
      this.state.selRole,
      this.state.selType,
      this.state.selSize,
      this.state.companyTypes,
      this.state.companyRoles,
      this.state.companySizes
    ).then(id => {
      var newRowsToBeAdded = createQuestionaireEntries(
        value.value,
        this.state.selRole,
        this.state.selType,
        this.state.selSize,
        1,
        this.state.companyRoles,
        this.state.companyTypes,
        this.state.companySizes
      );
      var questionaires = this.state.questionaires.slice();
      Array.prototype.push.apply(questionaires, newRowsToBeAdded);
      this.setState({
        questionaires: questionaires
      });

      this.setState({
        rows: createQuestionaireTable(
          this.state.selCategory,
          this.state.selRole,
          this.state.selType,
          this.state.selSize,
          this.state.companyTypes,
          this.state.companyRoles,
          this.state.companySizes,
          questionaires,
          this.state.statements,
          this.state.statementsTranslation
        )
      });

      this.setState({
        dropdownStatements: createStatementDropdown(
          this.state.selCategory,
          this.state.statements,
          this.state.statementsTranslation,
          questionaires,
          this.state.selType,
          this.state.selRole,
          this.state.selSize
        )
      });
    });
  };

  /**
   * This function commits the changes done in the table to the datase. And
   * performs the different task depending on whether it was an added, changed
   * or deleted task called.
   *
   * @param {object} param0 an object indicating whether the table function edit
   * or delete was used
   */
  commitChanges({ changed, deleted }) {
    let { rows } = this.state;
    /*
     * If a statement weight has been changed then call the function editStatement()
     * to commit the changes to the database, and then update the table.
     */
    if (changed) {
      // Check to see if the new value is valid or not
      for (var row in changed) {
        try {
          if (
            parseInt(changed[row].weight) > 10 &&
            parseInt(changed[row].weight) === 0
          ) {
            return;
          }
        } catch (error) {
          return;
        }
      }
      editStatement(
        changed,
        this.state.selRole,
        this.state.selType,
        this.state.selSize,
        this.state.companyTypes,
        this.state.companyRoles,
        this.state.companySizes
      );
      /*
       * Updates the table in place, this part will work even if something went
       * wrong with in the editStatement() function.
       */
      rows = rows.map(row =>
        changed[row.id] ? { ...row, ...changed[row.id] } : row
      );
    }
    /*
     * If a statement has been deleted then call the deleteStatement()
     * function to delete the statement from the database. And then remove the
     * statement from the table.
     */
    if (deleted) {
      var questionaires = this.state.questionaires.slice();
      var questionaires = removeQuestionaireEntriesbyId(
        deleted,
        questionaires,
        this.state.selRole,
        this.state.selType,
        this.state.selSize,
        this.state.companyRoles,
        this.state.companyTypes,
        this.state.companySizes
      );

      this.setState({
        questionaires: questionaires
      });

      this.setState({
        dropdownStatements: createStatementDropdown(
          this.state.selCategory,
          this.state.statements,
          this.state.statementsTranslation,
          questionaires,
          this.state.selType,
          this.state.selRole,
          this.state.selSize
        )
      });

      deleteStatement(
        deleted,
        this.state.selRole,
        this.state.selType,
        this.state.selSize,
        this.state.companyTypes,
        this.state.companyRoles,
        this.state.companySizes
      );
      /*
       * Updates the table in place, this part will work even if something went
       * wrong with in the deleteStatement() function.
       */
      const deletedSet = new Set(deleted);
      rows = rows.filter(row => !deletedSet.has(row.id));
    }
    this.setState({ rows });
  }

  render() {
    const {
      rows,
      editingStateColumnExtensions,
      tableColumnExtensions,
      dropdownCategories,
      dropdownStatements,
      dropdownRoles,
      dropdownTypes,
      // dropdownSizes is to used in future updates
      dropdownSizes,
      isOpenCategory,
      isOpenStatement,
      isOpenRole,
      isOpenType,
      // isOpenSizes is to used in future updates
      isOpenSize,
      selCategory,
      selRole,
      selType,
      // selSize is to used in future updates
      selSize
    } = this.state;
    return (
      <Container style={{ width: "80%" }}>
        {/* Navigation bar for the admin pages */}
        {navbar}
        <Typography variant="h4">Questionaires</Typography>
        <Grid item xs={12}>
          <TextFix>
            This questionaire page is the page used to tailor the questionaires
            to the different types of company, their sizes and the
            representatives position within the company. The questionaires are
            currently only dependant on the representative's role within the
            company and the type of that company.
          </TextFix>
          <TextFix>
            Select a role and a type from the dropdowns to tailor the
            questionaire to the different combinations of types and roles. Then
            select the category you want the questionaire to include. Then you
            will see all statements within that category that has been added to
            this questionaire, shown in the table. To edit the weight just press
            the edit button on the lefthand side to the corresponing statement.
            To remove a statement from the questionaire simply press the
            corresponing delete button.
          </TextFix>
          <TextFix>
            To add a new statement to the questionaire use "Select a statement"
            dropdown menu and select the category you want to add. The category
            will then be automatically added to the questionaire with a weight
            if 1.
          </TextFix>
          <TextFix>
            Adding new statements or updating weights may take some time, the
            operation is complete once you are able to edit the row again.
          </TextFix>
            <Fragment>
              <Dropdown
                isOpen={isOpenType}
                onClose={this.toggleOpenType}
                target={
                  <Button
                    iconAfter={<ChevronDown />}
                    onClick={this.toggleOpenType}
                    isSelected={isOpenType}
                  >
                    {selType
                      ? `Company Type: ${selType.label}`
                      : "All company types"}
                  </Button>
                }
              >
                <Select
                  autoFocus
                  backspaceRemovesValue={false}
                  components={{ DropdownIndicator, IndicatorSeparator: null }}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  menuIsOpen
                  onChange={this.onSelectChangeType}
                  options={dropdownTypes}
                  placeholder="Search..."
                  styles={selectStyles}
                  tabSelectsValue={false}
                  value={selType}
                />
              </Dropdown>
              <Dropdown
                isOpen={isOpenRole}
                onClose={this.toggleOpenRole}
                target={
                  <Button
                    iconAfter={<ChevronDown />}
                    onClick={this.toggleOpenRole}
                    isSelected={isOpenRole}
                  >
                    {selRole
                      ? `Company Role: ${selRole.label}`
                      : "All company roles"}
                  </Button>
                }
              >
                <Select
                  autoFocus
                  backspaceRemovesValue={false}
                  components={{ DropdownIndicator, IndicatorSeparator: null }}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  menuIsOpen
                  onChange={this.onSelectChangeRole}
                  options={dropdownRoles}
                  placeholder="Search..."
                  styles={selectStyles}
                  tabSelectsValue={false}
                  value={selRole}
                />
              </Dropdown>
              <Dropdown
                isOpen={isOpenCategory}
                onClose={this.toggleOpenCat}
                target={
                  <Button
                    iconAfter={<ChevronDown />}
                    onClick={this.toggleOpenCat}
                    isSelected={isOpenCategory}
                  >
                    {selCategory
                      ? `Category: ${selCategory.label}`
                      : "Select a category"}
                  </Button>
                }
              >
                <Select
                  autoFocus
                  backspaceRemovesValue={false}
                  components={{ DropdownIndicator, IndicatorSeparator: null }}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  menuIsOpen
                  onChange={this.onSelectChangeCat}
                  options={dropdownCategories}
                  placeholder="Search..."
                  styles={selectStyles}
                  tabSelectsValue={false}
                  value={selCategory}
                />
              </Dropdown>
              <Dropdown
                isOpen={isOpenStatement}
                onClose={this.toggleOpenStat}
                target={
                  <Button
                    iconAfter={<ChevronDown />}
                    onClick={this.toggleOpenStat}
                    isSelected={isOpenStatement}
                  >
                    Add a statement
                  </Button>
                }
              >
                <Select
                  autoFocus
                  backspaceRemovesValue={false}
                  components={{ DropdownIndicator, IndicatorSeparator: null }}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  menuIsOpen
                  onChange={this.onSelectChangeStat}
                  options={dropdownStatements}
                  placeholder="Search..."
                  styles={selectStyles}
                  tabSelectsValue={false}
                  value={selCategory}
                />
              </Dropdown>
            </Fragment>
          <Grid item xs={12}>
            <Gridd
              rows={rows}
              columns={this.createStatementColumns()}
              getRowId={getRowId}
            >
              <EditingState
                columnEditingEnabled={false}
                onCommitChanges={this.commitChanges}
                defaultEditingRowIds={[0]}
                columnExtensions={editingStateColumnExtensions}
              />
              <Table columnExtensions={tableColumnExtensions} />
              <TableColumnResizing
                defaultColumnWidths={this.state.defaultColumnWidths}
              />
              <TableHeaderRow />
              <TableEditRow rowHeight={100}/>
              <TableEditColumn showEditCommand showDeleteCommand />
            </Gridd>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default AdminQuestionaires;
