/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@atlaskit/button';
import Container from 'react-bootstrap/Container';
import { EditingState } from '@devexpress/dx-react-grid';
import {
  Grid as Gridd,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import Select from 'react-select';
import { Option, Fetch } from "../helpers/fetchHelpers"
import { 
  createLanguageDropdown, 
  createCategoryTable, 
  editEvaluation 
} from '../helpers/evaluationFunctions';
import {
  Dropdown,
  DropdownIndicator,
  ChevronDown,
  selectStyles,
  TextFix,
} from '../helpers/styling';
import { navbar } from '../helpers/adminNavbar';
import { serviceUrl } from '../helpers/ServiceURL';

const getRowId = row => row.id;

class AdminEvaluations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      languages: [],
      availableLanguages: [],
      // Static columns
      columns: [
        { name: 'id', title: 'ID' },
        { name: 'category', title: 'Category' },
        { name: 'evaluation', title: 'Categories Description' },
      ],
      /*
       * Disabling edititng in the columns ID and Category as the ID is 
       * dynamically assigned by the database, and after that it is never 
       * changed. For the category the reason is that the editing og the 
       * category name happens on the admin category page.
       */
      editingStateColumnExtensions: [
        { columnName: 'id', editingEnabled: false },
        { columnName: 'category', editingEnabled: false },
      ],
      defaultColumnWidths: [
        { columnName: 'id', width: 50 },
        { columnName: 'category', width: 200 },
        { columnName: 'evaluation', width: 750 },
      ],
      /*
       * This allows for wordwrap in the evaluation column, this is very useful
       * as these descriptions can become somewhat long.
       */
      tableColumnExtensions: [
        { columnName: 'evaluation', wordWrapEnabled: true },
      ],
      rows: [],
      dropdownLanguages: [],
    };
    this.commitChanges = this.commitChanges.bind(this);
  }

  /**
   * This function makes sure that the functions called within are run when the
   * component was mounted. This is done so that the functions called are ready
   * when the user sees the page.
   */
  componentDidMount() {
    this.fetchCategories();
    this.fetchLanguages();
  }

  /**
   * This function retrieves all categories from the database and stores it in the
   * state.
   */
  fetchCategories() {
    Fetch(serviceUrl+'/api/CategoryTranslations', Option('GET'))
      .then((categories) => {
        this.setState({ categories: categories });
      })
  }

  /**
   * This fucntion retrives all existing languages and all languages available
   * for translation and stores them in the state for later use.
   * 
   * It also calls the createLanguageDropdown() function to create the menu for
   * the available languages dropdown.
   */
  fetchLanguages() {
    const request = async () => {
      const languages = await Fetch(
        serviceUrl+'/api/Languages', Option('GET'));
      this.setState({ languages: languages });

      const availableLanguages = await Fetch(
        serviceUrl+'/api/AvailableLanguages', Option('GET'));
      this.setState({ availableLanguages: availableLanguages });
      this.setState({ 
        dropdownLanguages: createLanguageDropdown(languages, availableLanguages)
      });
    }
    request();
  }

  /**
   * This function toggles open the dropdown menu. Meaning if it's open it 
   * closes and if it's closed it opens.
   */
  toggleOpen = () => {
    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  /**
   * This function deccides what happens when something has been selected from
   * the dropdown menu.
   * 
   * It toggles the dropdown, updates the state with the selected value and 
   * creates the evaluation table by calling createCategoryTable().
   */
  onSelectChange = value => {
    this.toggleOpen();
    this.setState({ value: value });
    this.setState({ rows: createCategoryTable(value, this.state.categories) });
  };

  /**
   * This function commits the changes done in the table to the datase. 
   * 
   * @param {object} param0 an object indicating if the table function edit was
   * used
   */
  commitChanges({ changed }) {
    let { rows } = this.state;
    /*
     * If an evaluation description has been changed then call the function 
     * editEvaluation() to commit the changes to the database, and then update 
     * the table.
     */
    if (changed) {
      editEvaluation(changed, this.state.value.value);
      /*
       * Updates the table in place, this part will work even if something went
       * wrong with in the editEvaluation() function.
       */
      rows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    }
    this.setState({ rows });
  }

  render() {
    const {
      rows,
      columns,
      editingStateColumnExtensions,
      tableColumnExtensions,
      dropdownLanguages,
      isOpen,
      value
    } = this.state;
    return (
      <Container style={{ width: '110%' }}>
        {/* Navigation bar for the admin pages */}
        {navbar}
        <Fragment>
          <div style={{ width: '30%', height: '10%', marginBottom: '20px' }} >
            <Dropdown
                isOpen={isOpen}
                onClose={this.toggleOpen}
                target={
                  <Button
                    iconAfter={<ChevronDown />}
                    onClick={this.toggleOpen}
                    isSelected={isOpen}
                  >
                    {value ? `Language: ${value.label}` : 'Select a language'}
                  </Button>
                }
              >
            <Select
                  autoFocus
                  backspaceRemovesValue={false}
                  components={{ DropdownIndicator, IndicatorSeparator: null }}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  menuIsOpen
                  onChange={this.onSelectChange}
                  options={dropdownLanguages}
                  placeholder="Search..."
                  styles={selectStyles}
                  tabSelectsValue={false}
                  value={value}
                />
            </Dropdown>
            </div>
            </Fragment>
        <Grid container style={{ width: "110%", marginBottom: 10 }}>
          <Grid item style={{ width: "110%", marginBottom: 10 }}>
            {/* <Grid item xs={12}> */}
            <Typography variant="h4">
              Categories Descriptions
            </Typography>
            <Grid item xs={12}>
              <TextFix>
                To edit the category description of a category, first select the language
                and then press the corresponding edit button.
              </TextFix>
            </Grid>
            <Gridd
              rows={rows}
              columns={columns}
              getRowId={getRowId}
            >
              <EditingState
                onCommitChanges={this.commitChanges}
                defaultEditingRowIds={[0]}
                columnExtensions={editingStateColumnExtensions}
              />
              <Table columnExtensions={tableColumnExtensions} />
              <TableColumnResizing
                defaultColumnWidths={this.state.defaultColumnWidths}
              />
              <TableHeaderRow />
              <TableEditRow rowHeight={100}/>
              <TableEditColumn
                showEditCommand
              />
            </Gridd>
          </Grid>
        </Grid>
      </Container>
    );
  };

};

export default AdminEvaluations;