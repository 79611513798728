/* eslint-disable no-redeclare */
import { Option, Fetch } from './FetchHelper';
import { serviceUrl } from './ServiceURL';

var user;
var answers = [];
var categories = [];
var selfEvaluations = [];

/**
 * This function all necessary information from the current user
 * Using an async function as the execution order is important to the consistancy
 * of the program.
 *
 * @param {int} userId id of current use
 */
async function fetchUser(userId) {
  try {
    // Retrive the user from the USERS table in the DB
    user = await Fetch(serviceUrl + '/api/Users/' + userId, Option('GET'));
    // Retrieve the name of the company type in the specified language
    const companyType = await Fetch(
      serviceUrl + '/api/CompanyTypeTranslations/' + user.idCompanyType + '/' + user.isoCode,
      Option('GET')
    );
    user['companyType'] = companyType.companyType;
    // Retrieve the name of the company size
    const companySize = await Fetch(serviceUrl + '/api/CompanySizes/' + user.idCompanySize, Option('GET'));
    user['companySize'] = companySize.companySize1;
    // Retrieve the name of the company role in the specified language
    const companyRole = await Fetch(
      serviceUrl + '/api/CompanyRoleTranslations/' + user.idCompanyRole + '/' + user.isoCode,
      Option('GET')
    );
    user['companyRole'] = companyRole.companyRole;
    // Retrieve the name of the language used by the user
    const language = await Fetch(serviceUrl + '/api/Languages/' + user.isoCode, Option('GET'));
    user['language'] = language.nameEnglish;
  } catch (error) {
    console.log(error);
  }
}

/**
 * This function retrieves all the answers a user has submitted with all
 * dependant information.
 * Using an async function as the execution order is important to the consistancy
 * og the program.
 *
 * @param {int} userId id of current user
 */
async function fetchAnswers(userId) {
  try {
    // Retrieve all answers from the DB
    const allAnswers = await Fetch(serviceUrl + '/api/Answers', Option('GET'));
    for (let i in allAnswers) {
      // Filter out all answers not submitted by the given user
      if (allAnswers[i].idUser === userId) {
        // Retrieve the statement weight
        var questionaire = await Fetch(
          serviceUrl +
            '/api/Questionaires/role/' +
            user.idCompanyRole +
            '/type/' +
            user.idCompanyType +
            '/size/' +
            user.idCompanySize +
            '/statement/' +
            allAnswers[i].idStatement,
          Option('GET')
        );
        // Retrive the statement text in the specified language
        var statement = await Fetch(
          serviceUrl + '/api/StatementsTranslations/' + allAnswers[i].idStatement + '/' + user.isoCode,
          Option('GET')
        );
        // Retrive the id of the category the statement belongs to
        var categoryId = await Fetch(serviceUrl + '/api/Statements/' + allAnswers[i].idStatement, Option('GET'));
        // Retrive the name of the category in the specified language
        var category = await Fetch(
          serviceUrl + '/api/CategoryTranslations/' + categoryId.idCategory + '/' + user.isoCode,
          Option('GET')
        );
        /*
         * Create a list of all answered categories with the corresponding
         * evaluation for the category given by the user. And make sure not to
         * include the same category more than once.
         */
        if (!categories.includes(category.category)) {
          categories.push(category.category);
          var selfEvaluation = await Fetch(
            serviceUrl + '/api/SelfEvaluations/category/' + categoryId.idCategory + '/user/' + userId,
            Option('GET')
          );
          selfEvaluations.push({
            category: category.category,
            evaluation: selfEvaluation ? selfEvaluation.evaluation : null
          });
        }
        // Store all the relevant retrieved information in the desired format
        answers.push({
          idStatement: allAnswers[i].idStatement,
          statementText: statement ? statement.statementText : 'undefined',
          statementRank: allAnswers[i].statementRank,
          statementWeight: questionaire ? questionaire.statementWeight : 1,
          category: category.category
        });
      }
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * This function takes all the information from the current user and stores it
 * statically in the mongodb server.
 *
 * @param {int} userId id of the current user
 */
async function addUser(userId) {
  try {
    // Get all info regarding user
    await fetchUser(userId);
    // Get all answers submitted by user
    await fetchAnswers(userId);

    /*
     * Using a temp variable to temporarily store the reformatted
     * category data.
     */
    var temp = [];
    // Reformat the category data to fit the mongodb schema
    for (let i in categories) {
      var categoryStatements = [];
      for (let j in answers) {
        if (categories[i] === answers[j].category) {
          categoryStatements.push({
            statementText: answers[j].statementText,
            statementWeight: answers[j].statementWeight,
            statementRank: answers[j].statementRank,
            statementID: answers[j].idStatement
          });
        }
      }
      var categoryEvaluation;
      for (let j in selfEvaluations) {
        if (categories[i] === selfEvaluations[j].category) {
          categoryEvaluation = selfEvaluations[j].evaluation;
        }
      }
      temp.push({
        categoryName: categories[i],
        selfEvaluation: categoryEvaluation,
        statements: categoryStatements
      });
    }
  } catch (exception) {
    console.log("error: " + exception);
    console.error(exception);
  }
  // Update the categories with the reformatted data
  categories = temp;
  let res;
  let forMonitoring = [];
  let forMonitoring1 = JSON.parse(sessionStorage.getItem('forMonitoring')) ? JSON.parse(sessionStorage.getItem('forMonitoring')) : null ;
  let registredInfo = JSON.parse(sessionStorage.getItem('registredInfo'));
  if (forMonitoring1 === null) {
    forMonitoring = user;
  } else {
    forMonitoring = forMonitoring1;
  }
  try {
    // The user data to be sent to the mongodb server for permanent storage
    const body = {
      userId: parseInt(userId),
      companyType: user.companyType,
      companySize: user.companySize,
      companyRole: user.companyRole,
      companyName: forMonitoring.companyName,
      name: forMonitoring.nameUser,
      email: forMonitoring.emailUser,
      phone: forMonitoring.phoneNumber,
      ipAddress: user.ipAddress,
      language: user.language,
      finished: user.finished,
      categoriesCompleted: user.categoriesCompleted,
      feedback: forMonitoring.comment,
      categories: categories
    };
    // Store the user data in the mongodb server
    res = await Fetch(serviceUrl + '/api/UsersDatabase', Option('POST', body));
  } catch (exception) {
    console.error(exception);
  }
  return res.id;
}
/**
 * This function updates the specified user.
 *
 * @param {string} id the document id for the mongoDB collection
 */
async function updateUser(id) {
  const res = await Fetch(serviceUrl + '/api/UsersDatabase/' + id, Option('GET'));
  // Get all info regarding user
  await fetchUser(res.userId);
  let forMonitoring = [];
  let forMonitoring1 = JSON.parse(sessionStorage.getItem('forMonitoring')) ? JSON.parse(sessionStorage.getItem('forMonitoring')) : null ;
  let registredInfo = JSON.parse(sessionStorage.getItem('registredInfo'));
  if (forMonitoring1 === null) {
    forMonitoring = user;
  } else {
    forMonitoring = forMonitoring1;
  }
  console.log(res.id);
  const body = {
    id: res.id,
    userId: res.userId,
    companyType: res.companyType,
    companySize: res.companySize,
    companyRole: res.companyRole,
    companyName: forMonitoring.companyName,
    name: forMonitoring.nameUser,
    email: forMonitoring.emailUser,
    phone: forMonitoring.phoneNumber,
    ipAddress: user.ipAddress,
    language: user.language,
    finished: user.finished,
    categoriesCompleted: user.categoriesCompleted,
    feedback: forMonitoring.comment,
    categories: res.categories
  };
  const ret = await Fetch(serviceUrl + '/api/UsersDatabase/' + res.id, Option('PUT', body));

  return ret;
}

export { addUser, updateUser };
