import React, { PureComponent } from "react";
import Routes from "../routes";
import Navigation from "../components/Navigation";

class App extends PureComponent {
  render() {
    return <Navigation />, <Routes />;
  }
}

export default App;
