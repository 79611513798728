import styled from "styled-components";
import {
  bouvetdarkblue,
  bouvetorange,
  bouvetlightblue,
  bouvetblue,
  bouvetdarkorange,
  bouvetgrey70,
  bouvetgrey
} from "../../styles/colors";

const itemBorderRadius='6px';

/* Styling on the next and previous buttons */
export const Navigatebutton = styled.button`
  background: ${props => (props.primary ? bouvetdarkblue : "white")};
  color: ${props => (props.primary ? "white" : bouvetdarkblue)};
  margin: 1px;
  padding: 0.25px 3px;
  border: 2px solid ${bouvetdarkblue};
  border-radius: 5px;
  width: "10%";
  height: 40px;
  font-size: 20px;
  &:hover {
    background: ${bouvetblue};
    border: 2px solid ${bouvetdarkblue};
  }
  &:disabled{
    background: ${bouvetgrey70};
  }
`;
const customStyles = {
    content : {
      top                   : '60%',
      left                  : '24%',
      right                 : '10%',
      bottom                : 'auto',
      // marginRight           : '-45%',
      transform             : 'translate(-25%, -50%)'
    }
  };
  
  export const Item = styled.button`
    overflow-wrap: break-word;
    justify-content: left;
    padding: 8px;
    margin: 8px;
    border-radius: ${itemBorderRadius};
    width: 22%;
    border:  2px solid #999999; // "2px solid #0D3C55";// : "2px solid #A4D5EB",
  
    background: linear-gradient(
      to right,
      ${bouvetlightblue} 0%,
      ${bouvetlightblue} 1px,
      transparent 0
    );
    background-repeat: repeat-x;
    background-size: 100%;    
    color: #000;
  --background-color: #4CAF50;
    --
    background-color: "white";
    text-decoration: none;
    border-color:  #adadad;
    --
  
    --&:hover {
     -- background: linear-gradient(
     --   to right,
     --   ${"white"} 0%,
     --   ${"white"} 1px,
     --   transparent
     -- );
    --}

  --  &:clicked {
  --    background: linear-gradient(
  --      to right,
  --      ${bouvetlightblue} 0%,
  --      ${bouvetlightblue} 1px,
  --      transparent
  --    );
  --  }
  `;
  
  export const ItemSmallWidth = styled.button`
    overflow-wrap: break-word;
    justify-content: left;
    padding: 8px;
    margin: 8px;
    border-radius: 20px;
    width: 100%;
    border:  2px solid #A4D5EB; // "2px solid #0D3C55";// : "2px solid #A4D5EB",
  
    background: linear-gradient(
      to right,
      ${bouvetlightblue} 0%,
      ${bouvetlightblue} 1px,
      transparent 0
    );
    background-repeat: repeat-x;
    background-size: 100%;
    color: #000;
    text-decoration: none;
  
    &:hover {
      background: linear-gradient(
        to right,
        ${bouvetlightblue} 0%,
        ${bouvetlightblue} 1px,
        transparent
      );
    }
  `;

  export const ItemChosen = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    overflow-wrap: break-word;
    padding: 8px;
    /* margin: 1px; */
    border-radius: ${itemBorderRadius};
    width: 100%;
    height : 100%;
   /* border:  2px solid #A4D5EB; // "2px solid #0D3C55";// : "2px solid #A4D5EB", */
   border:  2px solid white;
    /* background-color: ${bouvetlightblue}; */
    background-color: ${bouvetorange};
    /* background: linear-gradient(
      to right,
      ${bouvetlightblue} 0%,
      ${bouvetlightblue} 1px,
      transparent 0
    ); */
    background-repeat: repeat-x;
    background-size: 100%;
    color: #fff;
    text-decoration: none;
  
    /*&:hover {
      background: linear-gradient(
        to right,
        ${bouvetlightblue} 0%,
        ${bouvetlightblue} 1px,
        transparent
      );
    }*/
  `;
  
  /* Title style*/
  export const TitleContainer = styled.div`
    margin: 10px 0 0 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
     `;
  
  /* Styling of the done button*/
  export const MyButton = styled.button`
    background: ${props => (props.primary ? bouvetdarkblue : "white")};
    color: ${props => (props.primary ? "white" : "white")};
  
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 1em;
    border: 2px solid ${bouvetblue};
    border-radius: 3px;
    float: right;
  
    &:hover {
      background: ${bouvetdarkblue};
      border: 2px solid ${bouvetdarkblue};
      color: white;
    }
  `;
 export  const grid = 8;
  
 export const getItemStyle = (isDragging, draggableStyle, isChosen) => ({
    // some basic styles to the items
    userSelect: "none",
  
    borderRadius: "23px",
  
    margin: isChosen ?  `${grid}px ${grid}px ${grid}px ${grid}px` : `0 0 ${grid}px ${grid}px`,
  
    width: isChosen ? "33%" : "100%" ,
  
    display: "left",
  
    justifyContent: "left",
  
    fontSize: "16px",
  
    //border: isDragging ? "2px solid #0D3C55" : "2px solid #A4D5EB",
    //border: isDragging ? "2px solid #0D3C55" : "2px solid #A4D5EB",
    // change background colour if dragging
    //background: isDragging ? { bouvetgrey70  } : bouvetgrey70,
  
    // styles we need to apply on draggables
    ...draggableStyle
  });
  
  export const getItemStyleMobile = (isDragging, draggableStyle, isChosen) => ({
    // some basic styles to the items
    userSelect: "none",
  
    borderRadius: "23px",
  
    margin: isChosen ?  `${grid}px ${grid}px ${grid}px ${grid}px` : `0 0 ${grid}px ${grid}px`,
  
    width: isChosen ? "90%" : "100%" ,
  
    display: "flex",
  
    justifyContent: "center",
  
    fontSize: "16px",
  
    border: isDragging ? "2px solid #0D3C55" : "2px solid #A4D5EB",
    // change background colour if dragging
    background: isDragging ? { bouvetlightblue } : "white",
  
    // styles we need to apply on draggables
    ...draggableStyle
  });
  
  export const getListStyleLeft = (isDraggingOver , widthMobile) => ({
    background: "white",
    padding: grid,
    width: "105%",
    height: "100%",
    display: "flex",
    flexFlow: "wrap",
    flexDirection: widthMobile ?  "column" : "row",
    justifyContent: "space-evenly",
    // border: isDraggingOver ? "2px solid #A4D5EB" : "2px solid #195e73"
  });
  export const getListStyleRight = isDraggingOver => ({
    background: isDraggingOver ? bouvetorange : bouvetorange,
    padding: grid,
    width: "100%",
    minHeight: "100px",
    // height: "100%",
    display: "flex",
    // flexFlow: "wrap",
    //justifyContent: "center",
    justifyContent: "left",
    // alignItems: "center",
    borderRadius: "5px",
    // border: isDraggingOver ? "2px solid #A4D5EB" : "2px solid #195e83"
    // border: isDraggingOver ? "2px solid bouvetlightblue" : "2px solid bouvetdarkblue"
  });
  
  export const getListStyleRightMobile = isDraggingOver => ({
    background: isDraggingOver ? bouvetlightblue : bouvetdarkblue,
    padding: grid,
    width: "100%",
    height: "50%",
    // height: "100%",
    display: "flex",
    flexDirection: "column",
    // flexFlow: "wrap",
    justifyContent: "center",
    // alignItems: "center",
    borderRadius: "20px",
  });
  

  // const customStyles = {
//   content : {
//     top                   : '60%',
//     left                  : '24%',
//     right                 : '10%',
//     bottom                : 'auto',
//     // marginRight           : '-45%',
//     transform             : 'translate(-25%, -50%)'
//   }
// };

// const Item = styled.button`
//   overflow-wrap: break-word;
//   padding: 8px;
//   margin: 8px;
//   border-radius: 20px;
//   width: 22%;
//   border:  2px solid #A4D5EB; // "2px solid #0D3C55";// : "2px solid #A4D5EB",

//   background: linear-gradient(
//     to right,
//     ${bouvetlightblue} 0%,
//     ${bouvetlightblue} 1px,
//     transparent 0
//   );
//   background-repeat: repeat-x;
//   background-size: 100%;
//   color: #000;
//   text-decoration: none;

//   &:hover {
//     background: linear-gradient(
//       to right,
//       ${bouvetlightblue} 0%,
//       ${bouvetlightblue} 1px,
//       transparent
//     );
//   }
// `;

// const ItemSmallWidth = styled.button`
//   overflow-wrap: break-word;
//   padding: 8px;
//   margin: 8px;
//   border-radius: 20px;
//   width: 100%;
//   border:  2px solid #A4D5EB; // "2px solid #0D3C55";// : "2px solid #A4D5EB",

//   background: linear-gradient(
//     to right,
//     ${bouvetlightblue} 0%,
//     ${bouvetlightblue} 1px,
//     transparent 0
//   );
//   background-repeat: repeat-x;
//   background-size: 100%;
//   color: #000;
//   text-decoration: none;

//   &:hover {
//     background: linear-gradient(
//       to right,
//       ${bouvetlightblue} 0%,
//       ${bouvetlightblue} 1px,
//       transparent
//     );
//   }
// `;

// const ItemChosen = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   overflow-wrap: break-word;
//   padding: 8px;
//   /* margin: 1px; */
//   border-radius: 20px;
//   width: 100%;
//   border:  2px solid #A4D5EB; // "2px solid #0D3C55";// : "2px solid #A4D5EB",
//   /* background-color: ${bouvetlightblue}; */
//   background-color: ${bouvetorange};
//   /* background: linear-gradient(
//     to right,
//     ${bouvetlightblue} 0%,
//     ${bouvetlightblue} 1px,
//     transparent 0
//   ); */
//   background-repeat: repeat-x;
//   background-size: 100%;
//   color: #000;
//   text-decoration: none;

//   &:hover {
//     background: linear-gradient(
//       to right,
//       ${bouvetlightblue} 0%,
//       ${bouvetlightblue} 1px,
//       transparent
//     );
//   }
// `;

// /* Title style*/
// const TitleContainer = styled.div`
//   margin: 10px 0 0 0;
//   display: flex;
//   flex-flow: column;
//   align-items: center;
//   width: 100%;
// `;

// /* Styling of the done button*/
// const MyButton = styled.button`
//   background: ${props => (props.primary ? bouvetdarkblue : "white")};
//   color: ${props => (props.primary ? "white" : "white")};

//   font-size: 1em;
//   margin: 1em;
//   padding: 0.25em 1em;
//   border: 2px solid ${bouvetblue};
//   border-radius: 3px;
//   float: right;

//   &:hover {
//     background: ${bouvetdarkblue};
//     border: 2px solid ${bouvetdarkblue};
//     color: white;
//   }
// `;
// const grid = 8;

// const getItemStyle = (isDragging, draggableStyle, isChosen) => ({
//   // some basic styles to the items
//   userSelect: "none",

//   borderRadius: "23px",

//   margin: isChosen ?  `${grid}px ${grid}px ${grid}px ${grid}px` : `0 0 ${grid}px ${grid}px`,

//   width: isChosen ? "30%" : "100%" ,

//   display: "flex",

//   justifyContent: "center",

//   fontSize: "16px",

//   border: isDragging ? "2px solid #0D3C55" : "2px solid #A4D5EB",
//   // change background colour if dragging
//   background: isDragging ? { bouvetlightblue } : "white",

//   // styles we need to apply on draggables
//   ...draggableStyle
// });

// const getItemStyleMobile = (isDragging, draggableStyle, isChosen) => ({
//   // some basic styles to the items
//   userSelect: "none",

//   borderRadius: "23px",

//   margin: isChosen ?  `${grid}px ${grid}px ${grid}px ${grid}px` : `0 0 ${grid}px ${grid}px`,

//   width: isChosen ? "90%" : "100%" ,

//   display: "flex",

//   justifyContent: "center",

//   fontSize: "16px",

//   border: isDragging ? "2px solid #0D3C55" : "2px solid #A4D5EB",
//   // change background colour if dragging
//   background: isDragging ? { bouvetlightblue } : "white",

//   // styles we need to apply on draggables
//   ...draggableStyle
// });

// const getListStyleLeft = (isDraggingOver , widthMobile) => ({
//   background: "white",
//   padding: grid,
//   width: "100%",
//   height: "100%",
//   display: "flex",
//   flexFlow: "wrap",
//   flexDirection: widthMobile ?  "column" : "row",
//   justifyContent: "space-evenly",
//   // border: isDraggingOver ? "2px solid #A4D5EB" : "2px solid #195e73"
// });
// const getListStyleRight = isDraggingOver => ({
//   background: isDraggingOver ? bouvetlightblue : bouvetdarkblue,
//   padding: grid,
//   width: "90%",
//   height: "180px",
//   // height: "100%",
//   display: "flex",
//   // flexFlow: "wrap",
//   justifyContent: "center",
//   // alignItems: "center",
//   borderRadius: "20px",
//   // border: isDraggingOver ? "2px solid #A4D5EB" : "2px solid #195e83"
//   // border: isDraggingOver ? "2px solid bouvetlightblue" : "2px solid bouvetdarkblue"
// });

// const getListStyleRightMobile = isDraggingOver => ({
//   background: isDraggingOver ? bouvetlightblue : bouvetdarkblue,
//   padding: grid,
//   width: "100%",
//   height: "50%",
//   // height: "100%",
//   display: "flex",
//   flexDirection: "column",
//   // flexFlow: "wrap",
//   justifyContent: "center",
//   // alignItems: "center",
//   borderRadius: "20px",
// });