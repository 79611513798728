import React from "react";
import ReactDOM from "react-dom";
/* import { Provider } from "react-redux";
import Store from "./store"; */
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import { createGlobalStyle } from "styled-components";
/* import { PersistGate } from "redux-persist/integration/react"; */
import { runWithAdal } from "react-adal";
import { authContext } from "./adalConfig";
import './fonts/Sanchez-Italic.ttf'

const DO_NOT_LOGIN = false;
/* const { persistor, store } = Store(); */
/* store={store} */
const GlobalStyle = createGlobalStyle`
  html {
    background-color: white;
    box-sizing: border-box;
    transition: all 0.5s ease-in;
  }
`;


/* persistor={persistor} 
 */
runWithAdal(
  authContext,
  () => {
    ReactDOM.render(
      /*  <Provider>
        <PersistGate loading={null}> */
      <div>
        <GlobalStyle />
        <App />
        {/*          </PersistGate>
      </Provider>, 
       */}
      </div>,

      document.getElementById("root")
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister();
  },
  DO_NOT_LOGIN
);
