/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from 'react-bootstrap/Container';
import { Option, Fetch } from "../helpers/fetchHelpers"
import { navbar } from '../helpers/adminNavbar';
import { serviceUrl } from '../helpers/ServiceURL';

/**
 * React component that represents the dashboard for the admin page
 */
class AdminMP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      languages: [],
      availableLanguages: [],
    };
  }

  /**
   * This function makes sure that the functions called within are run when the
   * component was mounted. This is done so that the functions called are ready
   * when the user sees the page.
   */
  componentDidMount() {
    this.fetchCategories();
    this.getUsers();
  }

  /**
   * This function retrieves all categories from the database and stores it in the
   * state. It also calls the function createCategoryList() so that the list is up
   * to date with the last fetch from the database.
   */
  fetchCategories() {
    Fetch(serviceUrl+'/api/CategoryTranslations', Option('GET'))
      .then((categories) => {
        this.setState({ categories: categories });
      })
  }

  /**
   * This function retrieves all users from the mongoDB server and stores the
   * information the programs state.
   */
  getUsers() {
    Fetch(serviceUrl+'/api/UsersDatabase', Option('GET'))
      .then((users) => {
        this.setState({ users: users })
      })
  }

  /**
   * This function creates a list of all existing categories and lists all the
   * Norwegian names for the categories.
   */
  createCategoryList() {
    const categoryList = [];
    this.state.categories.map(function (category) {
      if (category.isoCode === 'nob') {
        categoryList.push(<li key={category.category}>{category.category}</li>)
      }
    })
    return categoryList;
  }

  /**
   * This function creates a list of all users from the mongoDB server and shows
   * their information in  a list.
   */
  createUserList() {
    const userList = [];
    this.state.users.map(function (user) {
      if (user.name) {
        userList.push(
          <ul key={"UserInfo"}>
            <li key={user.userId}>User ID: {user.userId}</li>
            <ul key={user.userId + "info"}>
              {/*
                * Since name, email and phone is optional this info is only added
                * to the list if it exists.
                */}
              <li>Name: {user.name}</li>
              {user.email ? <li>Email: {user.email}</li> : null}
              {user.phone ? <li>Phone: {user.phone}</li> : null}
              <li>Language: {user.language}</li>
              <li>Company Type: {user.companyType}</li>
              <li>Company Role: {user.companyRole}</li>
              <li>Company Size: {user.companySize}</li>
              <li>Categories Completed: {user.categoriesCompleted}</li>
              {/*
                * Finished is either true if the user finished the questionaire or
                * false if they did not. This translates true/false to yes/no for
                * readability
                */}
              {user.finished ? <li>Questionaire finished: Yes</li> : <li>Questionaire finished: No</li>}
              {/* Feedback is optional and is then only shown if it exists */}
              {user.feedback ? <li>User feedback: {user.feedback}</li> : null}
            </ul>
          </ul>
        )
      }
    })
    return userList;
  }

  /**
   * This function takes in a list of an attribute and return an object with
   * the type of the attribute as the key, and the count of that type as the
   * value.
   * 
   * For example:
   * input: ['a', 'b', 'c', 'a', 'c', 'a']
   * gives
   * output: {'a': 3, 'b': 1, 'c': 2}
   * @param {list} variable list of an attribute
   */
  getVariableStat(variable) {
    var count = {};
    var prev;
    variable.sort();
    for (var i in variable) {
      if (variable[i] !== prev) {
        count[variable[i]] = 1;
      } else {
        count[variable[i]]++;
      }
      prev = variable[i];
    }
    var stats = [];
    for (var type in count) {
      stats.push(
        <li key={type}>{type}: {count[type]}</li>
      );
    }
    return stats;
  }

  /**
   * This function creates a list of user statistics for display on the admin
   * main page.
   */
  createUserStats() {
    var numUsersFinished = 0, numUsersUnfinished = 0;
    var usersWantsFollowup = 0;
    var avgCategories = 0;
    var companyTypes = [], companyRoles = [], companySizes = [];
    var languages = [];

    this.state.users.map(function (user) {
      companyTypes.push(user.companyType);
      companyRoles.push(user.companyRole);
      companySizes.push(user.companySize);
      languages.push(user.language);
      if (!(user.finished > 0)) {
        numUsersUnfinished++;
        avgCategories += user.categoriesCompleted;
      } else {
        numUsersFinished++;
        if (user.name) {
          usersWantsFollowup++;
        }
      }
    })

    var companyTypeStats = this.getVariableStat(companyTypes);
    var companyRoleStats = this.getVariableStat(companyRoles);
    var companySizeStats = this.getVariableStat(companySizes);
    var languagesStats = this.getVariableStat(languages);

    avgCategories /= numUsersUnfinished;
    usersWantsFollowup = Math.round(((usersWantsFollowup / numUsersFinished) * 100) * 100) / 100;
    var numUsers = numUsersFinished + numUsersUnfinished;

    const userStats =
      <ul>
        <li>Total number of users: {numUsers}</li>
        <li>Number of users who finished: {numUsersFinished}</li>
        <li>Number of users who did not finish: {numUsersUnfinished}</li>
        <li>How many who finished wants to be contacted: {usersWantsFollowup}%</li>
        <li>Average number of completed categories for users that did not finish: {avgCategories}</li>
        <li>Company types count:</li>
        <ul>
          {companyTypeStats}
        </ul>
        <li>Company roles count:</li>
        <ul>
          {companyRoleStats}
        </ul>
        <li>Company sizes count:</li>
        <ul>
          {companySizeStats}
        </ul>
        <li>Languages count:</li>
        <ul>
          {languagesStats}
        </ul>
      </ul>
    return userStats;
  }

  render() {
    const {
      users
    } = this.state;
    return (
      <Container style={{ width: '90%', marginTop: '10px' }}>
        {/* Navigation bar for the admin pages */}
        {navbar}
        <Grid container>
        <Grid item xs={6}>
            <Typography variant="h6">
              All Categories
            </Typography>
            <div>
              <ul>{this.createCategoryList()}</ul>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">
              User Statistics
            </Typography>
            <div>
              {users ? this.createUserStats() : null}
            </div>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">
              Users Info
            </Typography>
            <div>
              {users ? this.createUserList() : null}
            </div>
          </Grid>
          {/* <Grid item xs={12}>
            <RadarChart cx={300} cy={250} outerRadius={150} width={550} height={500} data={data}>
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" />
              <PolarRadiusAxis angle={90} domain={[0, 10]} />
              <Radar name="Industri" dataKey="1" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
              <Radar name="Helse og velferd" dataKey="2" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
              <Legend />
            </RadarChart>
          </Grid> */}
        </Grid>
      </Container >
    );
  };

};

export default AdminMP;