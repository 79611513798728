/* eslint-disable array-callback-return */
import { Option, Fetch } from "./fetchHelpers";
import { serviceUrl } from "./ServiceURL";

/**
 * This function creates the rows for the available languages table on the admin
 * page. This function is automatically ran when the page is loaded.
 *
 * @param {object} allLanguages all existing languages
 * @param {object} availableLanguages all languages available for translation
 * @returns {object} a set of rows with the languages available for translation
 * that can be placed directly into a table
 */
function createLanguageTable(allLanguages, availableLanguages) {
  let languagesTable = [];
  let languages = [];
  /*
   * First mapping all iso codes with the corresponding name of the language in
   * English.
   */
  for (var i in allLanguages) {
    var language = allLanguages[i];
    languages.push({ iso: language.isoCode, name: language.nameEnglish });
  }
  /*
   * Then applying a filter so that only the languages available for translation
   * is left in the table.
   */
  availableLanguages.map(function(language) {
    for (var i in languages) {
      if (language.isoCode === languages[i].iso) {
        languagesTable.push({
          iso: language.isoCode,
          name: languages[i].name,
          default: language.languageDefault ? "True" : "False"
        });
      }
    }
  });
  return languagesTable;
}

/**
 * This function creates a menu containing all existing languages.
 *
 * @param {object} allLanguages all existing languages
 * @returns {object} menu options that can be placed directly into a dropdown
 * menu
 */
function createLanguageDropdown(allLanguages) {
  let languages = [];
  allLanguages.map(function(language) {
    languages.push({ value: language.isoCode, label: language.nameEnglish });
  });
  return languages;
}

/**
 * This function takes the selected language from the dropdown and adds it as a
 * language available for translation.
 *
 * @param {object} added the new language to be added as a language available
 * for translation
 */
function addNewLanguage(added) {
  const body = {
    IsoCode: added.value,
    NameEnglish: added.label
  };
  Fetch(serviceUrl + "/api/AvailableLanguages", Option("POST", body));
  addCategories(added.value);
}

function addCategories(isoCode) {
  Fetch(serviceUrl + "/api/CategoryTranslations", Option("GET")).then(
    categories => {
      for (var idx in categories) {
        const category = categories[idx];
        if (category.isoCode === "nob") {
          const body = {
            IdCategory: parseInt(category.idCategory),
            IsoCode: isoCode,
            Category: category.category,
            EvaluationDescription: "Please enter an evaluation description"
          };
          // This fetch add the translation of the category to the database
          Fetch(serviceUrl + "/api/CategoryTranslations", Option("POST", body));
        }
      }
    }
  );
}

/**
 * This function takes the selected language from the dropdown and adds it as a
 * language available for translation.
 *
 * @param {object} deleted the new language to be added as a language available
 * for translation
 */

function removeLanguage(deleted) {
  // Fetch(serviceUrl+'/api/AvailableLanguages/' +deleted, Option('DELETE'))
}

export {
  createLanguageTable,
  createLanguageDropdown,
  addNewLanguage,
  removeLanguage
};
