import { adalApiFetch, getToken } from '../../adalConfig';

/**
 * Header configurarion used for all fetch functions.
 */
const Headers = {
  'accept': 'application/json;odata=verbose',
  'Content-type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

/**
 * Sets up the options used for the fetch functions.
 * @param {string} method - the method of the fetch, either GET, PUT, POST or DELETE
 * @param {object} body - the body of the fetch operation, can be undefined if not needed
 * @return {object} the options to be used for the fetch function
 */
function Option(method, body) {
  if (body === undefined) {
    return {
      method: method,
      headers: Headers
    }
  }
  return {
    method: method,
    headers: Headers,
    body: JSON.stringify(body),
  }
}

/**
 * A function that performs the fetch operation and returns the json of the 
 * repsonse. If an error occurs it is logged to the console and the fetch is 
 * aborted.
 * @param {string} path - the URL path of the api
 * @param {object} option - options for fetch (output of the Option function)
 */
async function Fetch(path, option) {
  try {
    let response = "";
    if(getToken()){
      response = await adalApiFetch(fetch, path, option);
    }else{
      response = await fetch(path, {method: option.method, headers: {'Content-type': 'application/json'}, body: option.body});
    }
    return await response.json();;
  } catch (error) {
    console.error(error)
  }
}

export {
  Option,
  Fetch
};