
function userId() {
    if (JSON.parse(sessionStorage.getItem("registredInfo")) !== null) {
      let registredInfo = JSON.parse(sessionStorage.getItem("registredInfo"));
      let idUser = parseInt(registredInfo.idUser);
      return idUser;
    }
  };

  
function userlanguage() {
  let registredInfo = JSON.parse(sessionStorage.getItem("registredInfo"));
  let isoCode = registredInfo.isoCode;
  return isoCode;
};

export{
    userId,
    userlanguage
}

