/* eslint-disable array-callback-return */

/**
 * This function creates the columns used for tables with translation of text.
 * The first column is the id, the second column is manually set to Norwegian
 * Bokmål as this is the default language. The remaining columns are the other
 * available languages sorted alphabetically.
 *
 * @param {object} allLanguages all existing languages {isoCode: string, nameEnglish: string}
 * @param {object} availableLanguages all available languages {isoCode: string, languageDefault: true/false}
 * @return {object} columns in the desired format ready for use in tables
 */
function createTranslationColumns(allLanguages, availableLanguages) {
  let languages = [];
  let columns = [
    { name: "id", title: "ID" },
    { name: "nob", title: "Norwegian Bokmål" }
  ];
  availableLanguages.map(function(language) {
    languages.push(language.isoCode);
  });
  allLanguages.map(function(language) {
    if (languages.includes(language.isoCode) && language.isoCode !== "nob") {
      columns.push({ name: language.isoCode, title: language.nameEnglish });
    }
  });
  return columns;
}

/**
 * This function creates the content for a dropdown menu that will contain all
 * categories in the paramter categories with the necessary information.
 *
 * @param {object} categories all existing categories {idCategory: int, category: string}
 * @returns {object} a dropdown menu containg the categories from the input
 */
function createCategoryDropdown(categories) {
  let dropdown = [];
  categories.map(function(category) {
    if (category.isoCode === "nob") {
      dropdown.push({ value: category.idCategory, label: category.category });
    }
  });
  return dropdown;
}

export { createTranslationColumns, createCategoryDropdown };
