

import styled from "styled-components";
import {
    bouvetdarkblue,
    bouvetorange,
    bouvetlightblue,
    bouvetblue,
    bouvetdarkorange,
    bouvetgrey70,
    bouvetgrey
  } from "../../styles/colors";

/* Styling on the next and previous buttons */
export const Navigatebutton = styled.button`
  background: ${props => (props.primary ? bouvetdarkblue : "white")};
  color: ${props => (props.primary ? "white" : bouvetdarkblue)};
  margin: 1px;
  padding: 0.15px 20px;
  border: 1px solid ${bouvetdarkblue};
  border-radius: 5px;
  min-width: 120px;
  height: 40px;
  font-size: 18px;
  &:hover {
    background: ${bouvetblue};
    border: 1px solid ${bouvetdarkblue};
  }
  &:disabled{
    background: ${bouvetgrey70};
  }
`;

// export default Navigatebutton;