import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { Link } from "react-router-dom";
import "../App.css";
import { Icon } from "semantic-ui-react";
import {
  bouvetdarkblue,
  bouvetorange,
  bouvetlightblue,
  bouvetblue,
  bouvetdarkorange,
  bouvetgrey70,
  bouvetgrey
} from "./../styles/colors";
import { addUser } from "../components/frontEndHelper/storeUser";
import { userId } from "../components/frontEndHelper/SessionHelper";
import { postAnswers } from "../components/frontEndHelper/RequestHandler";
import { Fetch, Option } from "../components/frontEndHelper/FetchHelper";
import { createBar } from "../components/frontEndHelper/prgressbarHelper";
import { serviceUrl } from "../components/frontEndHelper/ServiceURL";
import {
  getItemStyle,
  getItem,
  getItemStyleMobile,
  getListStyleLeft,
  getListStyleRight,
  getListStyleRightMobile,
  TitleContainer,
  ItemChosen,
  Item,
  ItemSmallWidth
} from "../components/frontEndHelper/AnalyseStyling";
import { Navigatebutton } from "../components/frontEndHelper/Navigatebutton";

import ReactDOM from 'react-dom';
import Modal from 'react-modal';

const myWindowWidth = () => {
  var w = window.innerWidth;
  var h = window.innerHeight;
  var wS = window.screen.width;
  var hS = window.screen.height;
  console.log("Height:  ", h, "Width:  ", w, "Screen W:  ", wS , hS);
  if (w < 600) {
    return true;  
  } else { return false;}
}
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};


/*let haveBeenIncategory = []; /* A list to find out that the user have been in the category, if yes, the some changes will happen in how 
                             the statement will be showed. For more take a look at the "prev()" and "next()" functions.  ---Moving this to sessionStorage*/
class Analyse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCategory: this.props.idOfCat[this.props.indexForAnalyse], //current category id
      currentIdx: this.props.indexForAnalyse, //current index of category in category list
      items: this.shuffle(this.props.allStatementsForCategory), //all unselected items
      selected: this.props.selectedStatementsForCategory, 
      termErrMsg: "",
      mongoId: "",
      showModal: false,
      check: true
    };
    this.onClosingTab = this.onClosingTab.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  id2List = {
    droppable: "items",
    droppable2: "selected"
  };

  getList = id => this.state[this.id2List[id]];
  onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === "droppable2") {
        state = { selected: items };
      }
      this.setState(state);
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.setState({
        items: result.droppable,
        selected: result.droppable2
      });
    }
  };


  a = new Array(3);
  check() {
    let answers = [];
    if (sessionStorage.getItem(this.state.currentCategory.toString())) {
      //Data item exists
      answers.push(...JSON.parse(sessionStorage.getItem(this.state.currentCategory)));
      this.a[0] = answers[0];
      this.a[1] = answers[1];
      this.a[2] = answers[2];
      // this.setState({ selected: [answers[0], answers[1], answers[2]] });
      console.log(this.state.currentCategory.toString());
      console.log(this.state.selected);
      return false;
    } else{ 
        //Data item doesn't exist,
      return false;
    }
  }
  handleClick(itemId, e) {
    // e.preventDefault();
    const item = this.state.items[itemId];
   // this.state.items[itemId].background = "bouvetlightblue"; 
   //this.item.bodystyle={{background: bouvetblue}};
    const selected = this.state.selected;
    console.log(this.state.selected.length);
    console.log("Hei ZZ1 ");
    if (this.state.selected.length == 3) {
    //  this.state.items[this.a[2].id].style = 98;
    this.state.items.map((item, index) => [
    //  console.log("Hei Z ", index),
      this.resetSelected(item.id,this.a[2].id,index)
    //this.createClickablex(item.id, index, index + 1, true)]                         
    ])
    }

    
   // if (this.state.items[index].style == 99)
    this.setState({
      items: this.state.items,
      selected: []
    });

    //style={{background: bouvetblue}};
    console.log("Hei ZZ ");
    this.state.items[itemId].style=99;





    if (this.a[0] === undefined) {
      this.setState({
        items: this.state.items,
        selected: [item]
      });
      this.a[0] = item;
      console.log("Hei", this.a);
      console.log(this.state.selected);
    }
    else if (this.a[1] === undefined) {
      if (this.a[0] !== item) {
        this.setState({
          items: this.state.items,
          selected: [item, this.a[0]]
        });
        this.a[1] = this.a[0];
        this.a[0] = item;
      } else {
        this.setState({
          items: this.state.items,
          selected: [this.a[0]]
        });
        console.log("Hei B ", this.a);
        console.log(this.state.selected);
      }
    }
    else if (this.a[2] === undefined) {
      if (this.a[1] !== item && this.a[0] !== item) {
        this.setState({
          items: this.state.items,
          selected: [item, this.a[0], this.a[1]]
        });
        this.a[2] = this.a[1];
        this.a[1] = this.a[0];
        this.a[0] = item;
      } else {
        this.setState({
          items: this.state.items,
          selected: [this.a[0], this.a[1]]
        });
      }
    } else {
      if (this.a[2] !== item && this.a[1] !== item && this.a[0] !== item) {
        this.setState({
          items: this.state.items,
          selected: [item, this.a[0], this.a[1]]
        });
        this.a[2] = this.a[1];
        this.a[1] = this.a[0];
        this.a[0] = item;
      } else {
        this.setState({
          items: this.state.items,
          selected: [this.a[0], this.a[1], this.a[2]]
        });
      }
        
    }
  }

  resetSelected(itemId, selectedId, index) {
    if (itemId == selectedId) {      
  //  console.log("Hei Z ");
    this.state.items[index].style = 98;
  }
}


  handleOpenModal() {
    this.setState({ showModal: true });
  }
  
  handleCloseModal() {
    this.setState({ showModal: false });
  }

  /**
   * This function will create a new list, take the value from selected items.
   */
  selectedItemsInSession() {
    let answersWithRank = [];
    this.state.selected.map((item, index) =>
      answersWithRank.push({ rank: index, id: item.id, content: item.content })
    );
    return answersWithRank;
  }

  /**
   *This function will update the current user information in the table "USERS"
   * in database.
   */
  putUserData = async () => {
    let registredInfo = JSON.parse(sessionStorage.getItem("registredInfo"));
    let idUser = parseInt(registredInfo.idUser);
    // let idCompanyRole = parseInt(registredInfo.idCompanyRole);
    // let idCompanySize = parseInt(registredInfo.idCompanySize);
    // let idCompanyType = parseInt(registredInfo.idCompanyType);
    // to access correctly all statements these magic values are used
    let idCompanyRole = 1;
    let idCompanySize = 29;
    let idCompanyType = 2;
    let isoCode = registredInfo.isoCode;
    let completed = parseInt(
      JSON.parse(sessionStorage.getItem("categoriesCompleted"))
    );
    const body = {
      IdUser: idUser,
      IdCompanyType: idCompanyType,
      IdCompanySize: idCompanySize,
      IdCompanyRole: idCompanyRole,
      Finished: true,
      CategoriesCompleted: completed,
      IsoCode: isoCode
    };
    await Fetch(serviceUrl + "/api/Users/" + idUser, Option("PUT", body)).catch(
      error => {
        console.error(error);
      }
    );
  };

  /**
   * If user choose to close the tab, then react will try to put
   * any new information such as nr of completed categories into to the db for the
   * current user.
   */
  putUserUnfineshedData = async () => {
    let registredInfo = JSON.parse(sessionStorage.getItem("registredInfo"));
    let idUser = parseInt(registredInfo.idUser);
    // let idCompanyRole = parseInt(registredInfo.idCompanyRole);
    // let idCompanySize = parseInt(registredInfo.idCompanySize);
    // let idCompanyType = parseInt(registredInfo.idCompanyType);
    // to access correctly all statements these magic values are used
    let idCompanyRole = 1;
    let idCompanySize = 29;
    let idCompanyType = 2;
    let isoCode = registredInfo.isoCode;
    let completed = parseInt(
      JSON.parse(sessionStorage.getItem("categoriesCompleted"))
    );
    const body = {
      IdUser: idUser,
      IdCompanyType: idCompanyType,
      IdCompanySize: idCompanySize,
      IdCompanyRole: idCompanyRole,
      Finished: false,
      CategoriesCompleted: completed,
      IsoCode: isoCode
    };
    await Fetch(serviceUrl + "/api/Users/" + idUser, Option("PUT", body)).catch(
      error => {
        console.error(error);
      }
    );
  };

  /**
   * This function will store all selected statements id, rank with the current user in
   * the table named "ANSWERS" in db.
   */
  userDone = async () => {
    sessionStorage.setItem(
      this.state.currentCategory,
      JSON.stringify(this.selectedItemsInSession())
    );
    let nrOfCompletedCategories = this.props.indexForAnalyse;
    await sessionStorage.setItem(
      "categoriesCompleted",
      nrOfCompletedCategories
    );

    let listOfcategoriIds = this.props.idOfCat;
    let answers = [];
    let idU = userId();
    for (var i = 0; i < listOfcategoriIds.length; i++) {
      answers = JSON.parse(sessionStorage.getItem(listOfcategoriIds[i]));
      if (answers !== null) {
        await answers.map(answer => {
          postAnswers(answer.id, userId(), answer.rank + 1);
        });
      }
    }

    await this.putUserData();
    let mongoId = 0;
    mongoId = await addUser(idU);
    sessionStorage.setItem("mongoId", mongoId);
  };

  /**
   * This fucntion will be called when user close the tab. WITHOUT ALERT: NOT EVERY DATA WILL BE STORED.
   * It will store the answers and update user informations.
   */
  /*   onClosingTab = async e => {
      let answers = [];
      let listOfcategoriIds = this.props.idOfCat;
      let nrOfCompletedCategories = this.state.currentIdx;
      
      for (var i = 0; i < listOfcategoriIds.length; i++) {
        answers = JSON.parse(sessionStorage.getItem(listOfcategoriIds[i]));
        if (answers !== null) {
          await answers.map((answer) => {
                postAnswers(answer.id, userId(), answer.rank + 1);
          });
        }
      }
      sessionStorage.setItem("categoriesCompleted", nrOfCompletedCategories);
      await this.putUserUnfineshedData();
      //await addUser(userId());
    }; */

  /**
   * This fucntion will be called when user close the tab.
   * It will store the answers and update user informations.
   * Added alert let oss to store must of data we need.
   * WITH ALERT: MUST DATA WILL BE STORED.
   */
  onClosingTab(e) {
    let answers = [];
    let listOfcategoriIds = this.props.idOfCat;
    let nrOfCompletedCategories = this.state.currentIdx;
    let idU = userId();

    for (var i = 0; i < listOfcategoriIds.length; i++) {
      answers = JSON.parse(sessionStorage.getItem(listOfcategoriIds[i]));
      if (answers !== null) {
        answers.map(answer => {
          postAnswers(answer.id, userId(), answer.rank + 1);
        });
      }
    }

    sessionStorage.setItem("categoriesCompleted", nrOfCompletedCategories); //store number f
    this.putUserUnfineshedData();

    if (idU !== undefined) {
      addUser(idU);
    }
    return (e.returnValue = "Are you sure you want to leave ? ");
  }

  /**
   * A function to update the items and the selected list given
   *  current index of the category in the category list.
   * @param {*} idx index of current categori in categories list.
   */
  updateItems = async idx => {
    await this.setState({
      items: this.props.allStatementsForCategory,
      selected: this.props.selectedStatementsForCategory
    });
  };

  /**
   * This function handle the "next" button. The function contains a list that will register
   * users visited categories. If that was the case then it will disapear star rating,
   * "see statamnets" button, and retrive selected items that are stored in the sessionStorage.
   * For the last visited category it will be diffrent, the star rating will be
   * disabled for rating, but the star value given by user will appear.
   * For this case the "see statamnets" button will action normally.
   * Otherwise it will go to the next category normally.
   */
  next = async () => {
    let haveBeenIncategory = JSON.parse(
      sessionStorage.getItem(this.props.idOfCat[this.props.indexForAnalyse + 1])
    );
    let listOfCatIds = this.props.idOfCat; //list of category ids.
    let nextIndex = this.props.indexForAnalyse + 1; //next index of category id list
    if (haveBeenIncategory) {
      await sessionStorage.setItem(
        this.props.idOfCat[this.props.indexForAnalyse],
        JSON.stringify(this.selectedItemsInSession())
      );
      await this.updateItems(this.props.idOfCat[this.props.indexForAnalyse]);
      await this.props.onNext;

      await this.props.indexHandling(this.props.indexForAnalyse + 1);
      await this.props.termErrMsg5
      await this.props.onPrevious(); //Changes the state to true (hide/show button/star rating).
      await this.updateItems(this.props.idOfCat[this.props.indexForAnalyse]); //updates selected and unselected lists.
    } else {
      if (this.props.indexForAnalyse < listOfCatIds.length) {
        await this.props.didUserRated(); //update star rating
        await this.props.indexHandling(this.props.indexForAnalyse + 1); //After click increment the index of category list
        await this.props.handleRating(); //update the value of rating to 0 again

        await this.props.showStarNextCategoy(); //Enable to display the star rating for next category.
        await this.props.enableOrDisableRating(); //Checks that the star rating has been rated for the current category.
        //selected item will be stored in session to retrive them again when user clicks on preivous/next button.
        await sessionStorage.setItem(
          this.state.currentCategory,
          JSON.stringify(this.selectedItemsInSession())
        );
        await this.updateItems(this.props.idOfCat[this.props.indexForAnalyse]);
      }
    }
    if (nextIndex === this.props.idOfCat.length - 1) {
      await this.props.nextButtonDisappear(); //diseapers the next button on the last category.
    }
     this.a[0] = undefined;
     this.a[1] = undefined;
     this.a[2] = undefined;
  };

  // 
  prev = async () => {
    let haveBeenIncategory = JSON.parse(
      sessionStorage.getItem(this.props.idOfCat[this.props.indexForAnalyse - 1])
    );
    //Last category user have not been visited will not be included in the "haveBeenIncategory" list.
    if (haveBeenIncategory) {
      await sessionStorage.setItem(
        this.props.idOfCat[this.props.indexForAnalyse],
        JSON.stringify(this.selectedItemsInSession())
      );
    }

    await this.props.indexHandling(this.props.indexForAnalyse) // - 1); //After click decrement the index of category list
    await this.props.onPrev();
    await this.props.onPrevious(); //Changes the state to true (hide/show button/star rating).
    await this.updateItems(this.props.idOfCat[this.props.indexForAnalyse]); //updates selected and unselected lists.
  };

  nextError = () => {
    if (this.state.selected.length !== 3) {
      this.setState({
        termErrMsg1  : (          
          <p className="Smaller errorMsg">          
            Velg 3 påstander
          </p>
        )
      });
    } else {
      this.setState({
        termErrMsg1: ("")
      });
    }
  }

  /**
   * A fucntion to dispaly the progress bar.
   * There are handlers that will be called on.
   */
  createProgressBar() {
    let currentCategory = this.state.currentCategory;
    let progress = [];
    progress.push(
      <div className="progressnav" key={currentCategory} style={{width: "80%"}}>
        {createBar(
          this.props.idOfCat.length,
          this.props.indexForAnalyse + 1,
          this.props.nameForPorgress,
          bouvetdarkblue
        )}
      </div>
    );
    return progress;
  }
  createProgressBarMobile() {
    let currentCategory = this.state.currentCategory;
    let progress = [];
    progress.push(
      <div className="progressnav" key={currentCategory} >
        {createBar(
          this.props.idOfCat.length,
          this.props.indexForAnalyse + 1,
          this.props.nameForPorgress,
          bouvetorange, 
          true
        )}
      </div>
    );
    return progress;
  }
  /**
 * A fucntion to dispaly the next and previous buttons.
 * There are handlers that will be called on next and the previous buttons.
 */
  createNextAndPreviousButton() {
    const { t } = this.props;
    let currentCategory = this.state.currentCategory;
    let buttons = [];
        
    buttons.push(
      <div className="progressnav" key={currentCategory} style={{ paddingTop: "20px", height: "100%", display: "flex", justifyContent: "space-between", width: "100%" }} >
          <Navigatebutton
            primary
            value={currentCategory}
            onClick={() => this.prev()}
            style={{ background: bouvetdarkblue , color:  "white" }}
          >            
            {t("Forrige")}
          </Navigatebutton>
        {this.createProgressBar()}
        {this.props.seeTheNextButton ? (
          this.state.selected.length === 3 ?
            <Navigatebutton
              primary
              disabled={this.state.selected.length !== 3}
              value={currentCategory}
              onClick={() => this.next()}
            >
              {t("Neste ")}              
            </Navigatebutton>
            :
            <div>
              <Navigatebutton
                primary
                disabled={this.state.selected.length === 3}
                value={currentCategory}
                onClick={() => this.nextError()}
                style={{ background: "white", color:  bouvetdarkblue }}
              >
                {t("Neste ")} 
                       
              </Navigatebutton>
              {console.log(React.version)}
              <p style={{ color: bouvetorange, paddingTop: '10px', paddingRight: '10px' }}>{this.state.termErrMsg1}</p>
                            
            </div> 

        ) : null}       

        {this.props.isFinished ? (
          this.state.selected.length === 3 ?
            <Link to="report">
              <Navigatebutton disabled={this.state.selected.length !== 3} style={{ margin: '5px' }} primary onClick={() => this.userDone()}>
                {t("Ferdig")}
              </Navigatebutton>
            </Link>
            :
            <div>
              <Navigatebutton disabled={this.state.selected.length === 3} style={{ margin: '5px',  color: bouvetdarkblue, background: "white" }} primary onClick={() => this.nextError()}>
                {t("Ferdig")}
              </Navigatebutton>
              <p style={{ color: bouvetorange, paddingRight: '10px' }}>{this.state.termErrMsg1}</p></div>
        ) : null}
      </div>


    );
    return buttons;
  }
 
  createNextAndPreviousButtonMobile() {
    const { t } = this.props;
    let currentCategory = this.state.currentCategory;
    let buttons = [];
    buttons.push(
      <div className="progressnav" key={currentCategory} style={{ paddingTop: "20px", height: "100%", justifyContent: "space-between" }} >
        {/* {this.props.seeThePrevButtton ? ( */}
          {/* <Navigatebutton
            primary
            value={currentCategory}
            onClick={() => this.prev()}
          >
            <span style={{ color: bouvetorange, fontFamily: 'timesNewRoman' }}>&#9668;</span>
          
          </Navigatebutton> */}
        {/* ) : <div></div>} */}
        {this.createProgressBarMobile()}
        {this.props.seeTheNextButton ? (
          this.state.selected.length === 3 ?
            <Navigatebutton
              primary
              disabled={this.state.selected.length !== 3}
              value={currentCategory}
              onClick={() => this.next()}
            >
              {/* {t("Neste ")} */}
              <span style={{ color: bouvetorange, fontFamily: 'timesNewRoman' }}>&#9658;</span>
            </Navigatebutton>
            :
            <div>
              <Navigatebutton
                primary
                disabled={this.state.selected.length === 3}
                value={currentCategory}
                onClick={() => this.nextError()}
                style={{ background: bouvetblue }}
              >
                {/* {t("Neste ")} */}
                <span style={{ color: bouvetgrey70, fontFamily: 'timesNewRoman' }}>&#9658;</span>
              </Navigatebutton>
              {console.log(React.version)}              
              <p style={{ color: bouvetorange }}>{this.state.termErrMsg1}</p>
            </div>
        ) : null}
        
        {this.props.isFinished ? (
          this.state.selected.length === 3 ?
            <Link to="report">
              <Navigatebutton disabled={this.state.selected.length !== 3} style={{ margin: '5px' }} primary onClick={() => this.userDone()}>
                {t("Ferdig")}
              </Navigatebutton>
            </Link>
            :
            <div>
              <Navigatebutton disabled={this.state.selected.length === 3} style={{ margin: '5px', color: bouvetdarkblue, background: "white" }} primary onClick={() => this.nextError()}>
                {t("Ferdig")}
              </Navigatebutton>
              <p style={{ color: bouvetorange }}>{this.state.termErrMsg1}</p></div>
        ) : null}
      </div>
    );
    return buttons;
  }

  createAvbryt() {
    const { t } = this.props;
    let currentCategory = this.state.currentCategory;
    let buttons = [];
    let colx = "background-color:Tomato;";
        
    buttons.push(
      <div className="progressnav" key={currentCategory} style={{ paddingTop: "20px", height: "100%", display: "flex", justifyContent: "center", width: "100%" }} >
        <div>  
          <br></br>         
        <p style={{color:'#999999'}}>   <a href="https://www.bouvet.no/" style={{color:'#0000ff'}}> Avbryt</a>  modenhetsanalysen og gå tilbake til startsiden</p>
        </div>
        </div>      
      
        
            
           

            
           
            

        )       
  ;
    return buttons;
   
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onClosingTab);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onClosingTab);
  }

  createDraggable(itemId, index, position, isChosen) {
    const { t } = this.props;
  
    return (
      <Draggable key={index} draggableId={itemId + "item"} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
              snapshot.isDragging ? true : isChosen
            )}
          >
            <ItemChosen key={index}>
              <p className="items" style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <div style={{fontSize:'35px',paddingRight:'15px'}}>{position ? index + 1  : null}</div>
                <div style={{fontSize: '18px'}}>{t("StatId" + itemId)}</div>
              </p>
            </ItemChosen>
          </div>
        )}
      </Draggable>
    );
  }
  createDraggableMobile(itemId, index, position, isChosen) {
    const { t } = this.props;
  
    return (
      <Draggable key={index} draggableId={itemId + "item"} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyleMobile(
              snapshot.isDragging,
              provided.draggableProps.style,
              snapshot.isDragging ? true : isChosen
            )}
          >
            <ItemChosen key={index}>
              <p className="items">
                {position ? index + 1 + ". " : null}
                {t("StatId" + itemId)}
              </p>
            </ItemChosen>
          </div>
        )}
      </Draggable>
    );
  }
 
  createClickable(itemId, index, position, isChosen, selected) {
    const { t } = this.props;
    const styles = {
      color: "red",
      background: "#0f0",
      fontSize: "32px"
    };
    const styles2 = {
      background: '#ffc8a5',
      border: '#999999',
      fontSize: '18px'
    };
    const styles3 = {
      color: "red",
      background: "#0f0",
      fontSize: "32px"
    };


    console.log("Hei D ", index);
/*    if (this.state.items[index].style == "{background: '#377599'}") {
        console.log("Hei E ",  this.state.items[index].style)}; */
    if (this.state.items[index].style == 99) {
 /*   return (<Item key={itemId} style={styles2}  onClick={(e) => this.handleClick(index, e)}> */
      return (<Item key={itemId} style={styles2}  onClick={(e) => this.handleClick(index, e)}>
      {/* <Item key={itemId} onClick={(e) => this.handleClick(itemId, e)}> */}          
       <p className="items" style={{ fontSize: '18px' }}>           
          {t("StatId" + itemId)}          
        </p>
      </Item>
    )
  }
  else
  {
    return (<Item key={itemId}  onClick={(e) => this.handleClick(index, e)}>
      {/* <Item key={itemId} onClick={(e) => this.handleClick(itemId, e)}> */}          
       <p className="items" style={{ fontSize: '18px' }} >          
          {t("StatId" + itemId)}          
        </p>
      </Item>
    )
  }
  ;
    
  }
  
 
  
  createClickableMobile(itemId, index, position, isChosen) {
    const { t } = this.props;
    return (
      <ItemSmallWidth key={itemId} onClick={(e) => this.handleClick(index, e)}>
        {/* <Item key={itemId} onClick={(e) => this.handleClick(itemId, e)}> */}
        <p className="items">
          {position ? index + 1 + ". " : null}
          {t("StatId" + itemId)}
        </p>
      </ItemSmallWidth>
    )
  }

  createChosen(itemId, index, position, isChosen) {
    const { t } = this.props;
    return (
      <ItemChosen key={index}>
        {/* <Item key={itemId} onClick={(e) => this.handleClick(itemId, e)}> */}
        <p className="items">
          {position ? index + 1 + ". " : null}
          {t("StatId" + itemId)}
        </p>
      </ItemChosen>
    )
  }

  shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }
  createDragDrop() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd} >
        <Droppable droppableId="droppable2" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyleRight(snapshot.isDraggingOver)}
            >
              {this.state.selected.map((item, index) =>
                this.createDraggable(item.id, index, index + 1, true)
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  createDragDropMobile() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd} >
        <Droppable droppableId="droppable2" direction="vertical">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyleRightMobile(snapshot.isDraggingOver)}
            >
              {this.state.selected.map((item, index) =>
                this.createDraggableMobile(item.id, index, index + 1, true)
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  render() {
    //console.log(JSON.parse(sessionStorage));
    const { t } = this.props;
    const w = myWindowWidth();
    if (this.state.check) {
      console.log(this.state.check);
      // this.setState({ check: this.check() }); 
      console.log(this.state.check);
    }
    return (
      <div>
        {w ?
          (<div style={{ paddingBottom: 50 }
          } >
            <div style={{ height: "100%", marginBottom: "10px" }}>
              <p style={{ marginLeft: "5%" }}>{t("Påstander")} <br></br></p>
              <p style={{ marginLeft: "5%" }}>{t("Påstander2")} <br></br></p>
              <TitleContainer>
                {/* {this.createDragDropMobile()} */}
                {/* {this.createDragDrop()} */}
                  <div
                    style={getListStyleLeft(false, w)}
                  >
                    {
                      this.state.items.map((item, index) =>
                        this.createClickableMobile(item.id, index, index + 1, true)
                      )
                    }
                  </div>
                <div></div>
              </TitleContainer>
              {this.createDragDropMobile()}
              {this.createNextAndPreviousButtonMobile()}
              {/* If the current category is the last category, the finish button will be showed. */}
              <div style={{ paddingBottom: 10 }} />
            </div>
            {/* <div>
              <button onClick={this.handleOpenModal}>Trigger Modal</button>
              <Modal
                isOpen={this.state.showModal}
                contentLabel="Minimal Modal Example"
                style={customStyles}
              >
                <DragDropContext onDragEnd={this.onDragEnd} >
                  <Droppable droppableId="droppable2" >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyleRightMobile(snapshot.isDraggingOver)}
                      >
                        {this.state.selected.map((item, index) =>
                          this.createDraggableMobile(item.id, index, index + 1, true)
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <button onClick={this.handleCloseModal}>Close Modal</button>
              </Modal>
            </div> */}
          </div >
          ) : (
            <div style={{ paddingBottom: 50,  display:"flex", justifyContent:"center" }}>
              <div style={{ height: "100%", marginBottom: "10px", maxWidth:"80%" }}>
                <p style={{ marginTop: '30px', marginBottom: '20px' }}>{t("Påstander")} <br></br></p>  
                <TitleContainer>
                  {this.createDragDrop()}
                  {/* {this.createDragDrop()} */}                  
                  <div
                    style={getListStyleLeft(false, w)}
                  >
                      {
                        this.state.items.map((item, index) => [
                          console.log("Hei C ", index),
                        this.createClickable(item.id, index, index + 1, true)]                         
                        )
                      }
                    </div>
                  <div></div>
                  {this.createNextAndPreviousButton()}
                  {this.createAvbryt()}
                </TitleContainer>
                {/* If the current category is the last category, the finish button will be showed. */}
                <div style={{ paddingBottom: 10 }} />
              </div>
            </div>)
        }
      </div>);
}
}
export default Analyse;
