import React from "react";


/**
 * A function to style and creat the progress bar.
 * Value for logic on this progressbar are temporary and must changes.
 * This function will be called in the component "Analyse.js". 
 * @param {} lengthOfBar Length of bar. Its depends on number of categories
 * @param {*} position Position in the bar
 * @param {*} name name of the current category
 * @param {*} color color of the bar.
 * @param {*} isMobile boolean param for screen width
 */
 function createBar( lengthOfBar , position , name , color, isMobile ){

    let multiplyWidth = 0; //to extend the bar to be larger depends on amount of categories.
    if (lengthOfBar < 5) {
      multiplyWidth = 19;
    }
    if (lengthOfBar < 10 && lengthOfBar >= 5) {
      multiplyWidth = 12;
    }
    if (lengthOfBar >= 10) {
      multiplyWidth = 10;
    }

    const reading = {
      name: name,
      value: position * multiplyWidth,
      color: color
   };

   return (
     <div style={{ display: "flex", flexDirection: "row", marginTop: '15px' }}>
       {isMobile ? null :
         (<div style={{ color: reading.color, width: "90px", marginLeft: "20px", marginTop: "-6px" }}>
           <p style={{ fontWeight: "bold"}}>
             {position} <span> av </span>{" "}{lengthOfBar}
           </p>
         </div>) }
       <div  style={{display: "flex", height: "15px"}} key={position}>
         <div style={{ backgroundColor: "#ffffff" }}>
        </div>
        <div style={{ backgroundColor: "#efefef", width: ( isMobile ? "100px" : '300px' ), marginLeft: "10px", marginRight: "10px", marginTop: "00px", borderRadius: "0px"  }}>
           <div
            style={{
              backgroundColor: reading.color,
              width: reading.value + "%",
              height: "15px",
              alignSelf: "left",
              borderRadius: "0px"
            }}
          />
        </div>
        </div>
       </div>
    );
}

  export {createBar}