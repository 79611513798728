import { Option, Fetch } from "./fetchHelpers";
import { serviceUrl } from "./ServiceURL";

/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */

/**
 * This function takes in information on the languages available for translation
 * and reformats that the data to fit the dropdown menu format.
 *
 * @param {object} allLanguages all existing languages
 * @param {object} availableLanguages all languages available for translation
 * @returns {object} menu options that can be placed directly into a dropdown menu
 */
function createLanguageDropdown(allLanguages, availableLanguages) {
  let dropdown = [];
  let languageIdx = [];
  /*
   * First mapping all iso codes with the corresponding name of the language in
   * English.
   */
  for (var i in allLanguages) {
    var language = allLanguages[i];
    languageIdx.push({ iso: language.isoCode, name: language.nameEnglish });
  }
  /*
   * Then applying a filter so that only the languages available for translation
   * is left on the menu.
   */

  availableLanguages.map(function(language) {
    for (var i in languageIdx) {
      if (language.isoCode === languageIdx[i].iso) {
        dropdown.push({ value: language.isoCode, label: languageIdx[i].name });
      }
    }
  });
  return dropdown;
}

/**
 * This function creates the rows for the evaluation table on the admin evaluation
 * page. It takes in a selected language and returns all categories translated in
 * that language along with the evaluation description.
 *
 * @param {object} language the selected language
 * @param {object} categories all categories with a translation in the selected
 * language
 * @returns {object} the rows to be put into the table
 */
function createCategoryTable(language, categories) {
  let evaluations = [];
  const isoCode = language.value;
  categories.map(function(category) {
    // Finding all categories for the given language
    if (category.isoCode === isoCode) {
      evaluations.push({
        id: category.idCategory,
        category: category.category,
        evaluation: category.evaluationDescription
      });
    }
  });
  return evaluations;
}

/**
 * This function takes the entry that was changed in the evaluation table on
 * the admin evaluation page and updates the database with the new evaluation
 * description.
 *
 * @param {object} changed the evaluation description that has been changed
 * @param {object} isoCode the iso code of the currently selected language
 */
function editEvaluation(changed, isoCode) {
  for (var categoryId in changed) {
    /*
     * This async function ensures that the first fetch returns before the second
     * one starts. This is necessary as the second fetch is dependant on the
     * output of the former.
     */
    const request = async () => {
      var evaluationDescription = changed[categoryId].evaluation;
      /*
       * This fetch retrieves the translatad name of the edited category as the
       * name is not part of the input for this edit funtion.
       */
      const category = await Fetch(
        serviceUrl + "/api/CategoryTranslations/" + categoryId + "/" + isoCode,
        Option("GET")
      );
      const categoryTranslation = category.category;
      const body = {
        IdCategory: categoryId,
        IsoCode: isoCode,
        Category: categoryTranslation,
        EvaluationDescription: evaluationDescription
      };
      Fetch(
        serviceUrl + "/api/CategoryTranslations/" + categoryId + "/" + isoCode,
        Option("PUT", body)
      );
    };
    request();
  }
}

export { createLanguageDropdown, createCategoryTable, editEvaluation };
