import { Fetch, Option } from '../components/frontEndHelper/FetchHelper';
import { serviceUrl } from '../components/frontEndHelper/ServiceURL';

async function StatementTranslations(Langauge) {
  const res = await fetchStatementstranslations();
  var list = [];
  res
    .filter(statement => statement.isoCode === Langauge)
    .map(translation => {
      list['StatId' + translation.idStatement] = translation.statementText;
    });
  return list;
}

async function StatementDescriptions(Langauge) {
  const res = await fetchStatementDescriptions();
  let regInfo = JSON.parse(sessionStorage.getItem('registredInfo'));
  var list = [];
  res
    .filter(statement => statement.isoCode === Langauge)
    .map(description => {
      list[
        'StatDesc' + description.idStatement + regInfo.idCompanyRole + regInfo.idCompanyType + regInfo.idCompanySize
      ] = description.statementDescription;
    });
  return list;
}

/**
 * A function to fetch details for every statements that are stored in table
 * named: "STATEMENT_DESCRIPTIONS" in the db.
 */
async function fetchStatementDescriptions() {
  return await Fetch(serviceUrl + '/api/StatementDescription', Option('GET')).then(res => {
    return res;
  });
}

/**
 * A function to fetch details for every statements that are stored in table
 * named: "STATEMENTS_TRANSLATION" in the db.
 */
async function fetchStatementstranslations() {
  return await Fetch(serviceUrl + '/api/StatementsTranslations', Option('GET')).then(res => {
    return res;
  });
}

export { StatementTranslations, StatementDescriptions };
