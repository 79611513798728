import React from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import AdminMP from "../admin/components/AdminMP";
import AdminCategories from "../admin/components/AdminCategories";
import AdminStatements from "../admin/components/AdminStatements";
import AdminStatementDescriptions from "../admin/components/AdminStatementDescriptions";
import AdminCompany from "../admin/components/AdminCompany";
import AdminLanguage from "../admin/components/AdminLanguage";
import AdminEvaluations from "../admin/components/AdminEvaluations";
import AdminQuestionaires from "../admin/components/AdminQuestionaires";
import AnalyseRating from "../components/AnalyseRating";
import Feedback from "../components/Feedback";
import Report from "../components/Report";
import NavUpdater from "../components/NavUpdater";
import { withAdalLoginApi } from "../adalConfig";
//some chenges

const AdminRoutes = () => (
  <Route path="admin">
    <Route path="/admin" exact component={AdminMP} />
    <Route path="/admin/categories" exact component={AdminCategories} />
    <Route path="/admin/statements" exact component={AdminStatements} />
    <Route path="/admin/statementDescriptions" exact component={AdminStatementDescriptions} />
    <Route path="/admin/company" exact component={AdminCompany} />
    <Route path="/admin/language" exact component={AdminLanguage} />
    <Route path="/admin/categoriesDescriptions" exact component={AdminEvaluations} />
    <Route path="/admin/questionaires" exact component={AdminQuestionaires} />
  </Route>
);
const AdminPages = withAdalLoginApi(AdminRoutes, () => null, () => null);

const AppRouter = () => {
  
  return (
    <Router>
        <NavUpdater/>   
       <div>       
        <Route path="/admin"
          render={ () => <AdminPages />} 
        />
        <Route path="/analyse" exact component={AnalyseRating} />
        <Route path="/report" exact component={Report} />
        <Route path="/feedback" exact component={Feedback} />
      </div>
    </Router>
  );
};

export default AppRouter;
