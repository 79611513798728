import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Button from '@atlaskit/button';

const navbar = <Grid style={{width: "110%", marginBottom: 10}} container>
  <Grid item>
    <Link to="/admin">
      <Button variant="primary" type="submit">
        Dashboard
      </Button>
    </Link>
  </Grid>
  <Grid item>
    <Link to="/admin/categories">
      <Button variant="primary" type="submit">
        Categories
      </Button>
    </Link>
  </Grid>
  <Grid item>
    <Link to="/admin/categoriesDescriptions">
      <Button variant="primary" type="submit">
        Categories Description
      </Button>
    </Link>
  </Grid>
  <Grid item>
    <Link to="/admin/statements">
      <Button variant="primary" type="submit">
        Statements
      </Button>
    </Link>
  </Grid>
  <Grid item>
    <Link to="/admin/statementdescriptions">
      <Button variant="primary" type="submit">
        Statement Descriptions
      </Button>
    </Link>
  </Grid>
  <Grid item>
    <Link to="/admin/questionaires">
      <Button variant="primary" type="submit">
        Questionaires
      </Button>
    </Link>
  </Grid>
  <Grid item>
    <Link to="/admin/company">
      <Button variant="primary" type="submit">
        Company
      </Button>
    </Link>
  </Grid>
  <Grid item>
    <Link to="/admin/language">
      <Button variant="primary" type="submit">
        Languages
      </Button>
    </Link>
  </Grid>
</Grid>;

export {
    navbar
};