import React from "react";
import Registration from "./Registration";
import Navigation from "./Navigation";
import { Component } from "react";
import { HashRouter as Router, Route } from "react-router-dom";


class NavUpdater extends Component {
    constructor(){
        super()
        this.state = {
            userLanguage:''
        }
        this.optionSelected = this.optionSelected.bind(this);
    }

    /**
     * A function to set the state of language to the selected language.
     * Updated value will be send to child component "Registration.js".
     * @param {int} params the selected language.
     */
    optionSelected = async (params) => {
       await this.setState({
            userLanguage : params
        })
      }

    render() {
        return (
            <Router>
                <Route path="/" exact render={() => <Navigation callback={this.optionSelected} />}/>
                <Route path="/" exact render={() => <Registration userLanguage = {this.state.userLanguage} />}/>  
            </Router>
        )
    }
}
export default NavUpdater;