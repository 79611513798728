
import { Component } from "react";
import React from "react";
import { DialogContentText, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";

class CreateConfirmationDialog extends Component{
    constructor(props){
        super(props);
    }
    
    render() {
        const { props } = this;
        return (
            <Dialog open={props.showDialog} onClose={() => props.onClick(false)}> 
                <DialogTitle>
                {props.title}
                </DialogTitle>
                <DialogContent>
                <DialogContentText>
                    {props.contentText}
                </DialogContentText>
                {props.warningText ? <DialogContentText>
                    {props.warningText}
                </DialogContentText> : null}
                </DialogContent>
                <DialogActions>
                <Button  color="primary" onClick={() => props.onClick(true)}>
                        Confirm
                    </Button>
                    <Button color="primary" onClick={() => props.onClick(false)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

  export default CreateConfirmationDialog;